import React from "react"

import { Row, Col, CardBody, Card, Container } from "reactstrap"

import { Link } from "react-router-dom"

// import images
import profile from "assets/images/caretip.jpg"
import logo from "assets/images/caretip.jpg"

const Support = props => {
  //meta title
  document.title = "Privacy Choices | Care-Tip - Support"
  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary-subtle">
                  <Row>
                    <Col className="col-7">
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Care-Tip</h5>
                        <p> Support</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/" className="logo-light-element">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logo}
                            alt=""
                            className="rounded-circle"
                            height="80"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    <div className="page-content">
                      <div className="container mt-5">
                        <h1>Support</h1>
                        <p>Last updated: 18th January 2024</p>

                        <h2>Care-Tip Support Information</h2>
                        <p>
                          Welcome to the Care-Tip Support Center! We're here to
                          help you navigate through our healthcare recruitment
                          app, ensuring you have all the information you need to
                          successfully find or post healthcare jobs. Whether
                          you're a healthcare professional looking for your next
                          opportunity or an employer seeking top talent, our
                          support guide has got you covered.
                        </p>

                        <h3>Getting Started</h3>
                        <p>For Job Seekers</p>

                        <p>
                          Creating an Account: Learn how to set up your Care-Tip
                          profile for maximum visibility.
                        </p>
                        <p>
                          Profile Optimization: Tips on how to enhance your
                          profile to stand out to potential employers.
                        </p>
                        <p>
                          Search and Apply: Guide on using our search filters to
                          find the right job and how to apply directly through
                          the app.
                        </p>

                        <h3>For Employers</h3>
                        <p>
                          Registering as an Employer: Step-by-step instructions
                          on creating an employer account.
                        </p>
                        <p>
                          Posting a Job: How to create compelling job postings
                          that attract the right candidates.
                        </p>
                        <p>
                          Managing Applications: Tools for reviewing
                          applications, scheduling interviews, and managing
                          candidates.
                        </p>

                        <h3>Frequently Asked Questions (FAQs)</h3>

                        <h3>General</h3>
                        <p>
                          Is Care-Tip free to use? Care-Tip offers free access
                          to job seekers, with premium features available for
                          employers to enhance their job postings and candidate
                          search.
                        </p>
                        <p>
                          How do I reset my password? You can reset your
                          password via the 'Forgot Password' link on the login
                          page. Instructions will be sent to your registered
                          email address.
                        </p>

                        <h3>For Job Seekers</h3>
                        <p>
                          How do I set up job alerts? Dive into setting up
                          customized job alerts to ensure you never miss an
                          opportunity that matches your skills and preferences.
                        </p>
                        <p>
                          Can I apply for jobs in different locations? Yes,
                          Care-Tip allows you to search and apply for jobs in
                          various locations based on your mobility and
                          preferences.
                        </p>

                        <h3>For Employers</h3>
                        <p>
                          How can I feature my job posting? Explore our premium
                          features that allow you to feature your job posting,
                          increasing its visibility to top candidates.
                        </p>
                        <p>
                          What is the process for shortlisting candidates? Learn
                          about our intuitive shortlisting process that helps
                          you manage and communicate with candidates
                          efficiently.
                        </p>

                        <h3>Troubleshooting</h3>
                        <p>Technical Issues </p>
                        <p>
                          App Crashes: Steps to take if the Care-Tip app
                          unexpectedly closes or fails to load.
                        </p>

                        <p>
                          Login Problems: Common login issues and how to resolve
                          them.
                        </p>
                        <p>
                          Notification Issues: What to do if you're not
                          receiving push notifications as expected.
                        </p>
                        <p>
                          Account Management Updating Profile Information: How
                          to keep your profile up-to-date to reflect your
                          current skills and experiences.
                        </p>
                        <p>
                          Deactivating Account: Instructions for deactivating or
                          permanently deleting your Care-Tip account.
                        </p>

                        <h3>Contact Us</h3>

                        <p>
                          Direct Support Email Support: Reach out to our support
                          team at
                          <a href="mailto:support@care-tip.com">
                            support@care-tip.com.
                          </a>
                          for personalized assistance.
                        </p>
                        <p>
                          Live Chat: Available on our website and app, our live
                          chat feature connects you with a support agent for
                          immediate help.
                        </p>

                        <p>
                          Community and Social Media Community Forum: Join the
                          Care-Tip community forum to share experiences, tips,
                          and advice with fellow users.
                        </p>
                        <p>
                          Social Media: Follow us on
                          <a href="Facebook.com">Facebook</a>,
                          <a href="twitter.com">Twitter</a>, and
                          <a href="linkedin.com">LinkedIn</a> for the latest
                          news, updates, and job tips.
                        </p>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  © {new Date().getFullYear()} Care-Tip. Crafted with{" "}
                  <i className="mdi mdi-heart text-danger" /> by Precious Tech
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Support
