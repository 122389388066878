// Firebase imports
import { initializeApp } from 'firebase/app';
import {
  getDatabase,
  ref,
  push,
  onValue,
  update,
  remove,
  query,
  equalTo,
  orderByChild,
  orderByKey,
} from "firebase/database"

// Firebase configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_APIKEY,
  authDomain: process.env.REACT_APP_AUTHDOMAIN,
  databaseURL: process.env.REACT_APP_DATABASEURL,
  projectId: process.env.REACT_APP_PROJECTID,
  storageBucket: process.env.REACT_APP_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_APPID,
  measurementId: process.env.REACT_APP_MEASUREMENTID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getDatabase(app);

class OrganisationDataService {
  constructor() {
    this.dbRef = ref(getDatabase(), "/organisation")
  }

  getOrganisationCount(callback) {
    return onValue(this.dbRef, snapshot => {
      const data = snapshot.val()
      const count = data ? Object.keys(data).length : 0
      callback(count)
    })
  }

  async getOrganisationBusinessName(key) {
    const specificOrgRef = ref(getDatabase(), `/organisation/${key}`)

    try {
      const snapshot = await get(specificOrgRef)
      const data = snapshot.val()
      if (data && data.businessName) {
        return data.businessName // Return business name
      } else {
        return "Business not found" // Fallback if businessName is missing or key is invalid
      }
    } catch (error) {
      console.error("Error fetching organisation data:", error)
      return "Error retrieving business name" // Error handling
    }
  }
  // getAll(callback) {
  //   return onValue(this.dbRef, snapshot => {
  //     const data = snapshot.val()
  //     let organisations = []
  //     if (data) {
  //       organisations = Object.keys(data).map(key => ({ key, ...data[key] }))
  //     }
  //     callback(organisations)
  //   })
  // }

  getAll(callback) {
    // Query to order organisations by the 'createdAt' field
    const organisationsByLatestRef = query(
      this.dbRef,
      orderByChild("createdAt")
    )

    return onValue(organisationsByLatestRef, snapshot => {
      const data = snapshot.val()
      let organisations = []

      if (data) {
        // Convert the object into an array and include the 'key' property
        organisations = Object.keys(data).map(key => ({ key, ...data[key] }))

        // Sort organisations by 'createdAt' in descending order (latest first)
        organisations.sort((a, b) => (b.createdAt || 0) - (a.createdAt || 0)) // Descending order
      }

      if (typeof callback === "function") {
        callback(organisations) // Pass sorted organisations to the callback
      } else {
        console.error("Callback is not a function")
      }
    })
  }

  getOrganisationByKey(key, callback) {
    const specificOrgRef = ref(getDatabase(), `/organisation/${key}`)
    return onValue(specificOrgRef, snapshot => {
      const data = snapshot.val()
      callback({ key, ...data })
    })
  }

  createOrganisation(organisation) {
    return push(this.dbRef, organisation)
  }

  updateOrganisation(key, value) {
    const specificOrgRef = ref(getDatabase(), `/organisation/${key}`)
    return update(specificOrgRef, value)
  }

  deleteOrganisation(key) {
    const specificOrgRef = ref(getDatabase(), `/organisation/${key}`)
    return remove(specificOrgRef)
  }
}

export default new OrganisationDataService();