import React, { useState } from "react"
import "./UserPill.css" // Assume some basic CSS for styling

const UserPill = ({ employeeName, employeeEmail, employeeLocation }) => {
  const [showDetails, setShowDetails] = useState(false)

  const toggleDetails = () => {
    setShowDetails(!showDetails)
  }

  return (
    <div className="user-pill" onClick={toggleDetails}>
      <span className="username">{employeeName}</span>
      {showDetails && (
        <div className="user-details">
          <p>Email: {employeeEmail}</p>
          <p>Location: {employeeLocation}</p>
        </div>
      )}
    </div>
  )
}

export default UserPill
