import React, { useState, useEffect } from "react"
import Modal from "react-modal"
import JobDataService from "services/JobDataService"

// Custom styling for the modal
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    maxWidth: "500px",
    padding: "20px",
    backgroundColor: "#f7f7f7",
    borderRadius: "10px",
  },
  overlay: {
    backgroundColor: "rgba(0,0,0,0.5)",
  },
}

Modal.setAppElement("#root")

function NewJobModal({ isOpen, onClose, jobData }) {
  const [hours, setHours] = useState("")
  const [comments, setComments] = useState("")

  const convertToFriendlyDateUK = isoDateString => {
    const date = new Date(isoDateString)
    return date.toLocaleString("en-GB", {
      day: "numeric",
      month: "long",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    })
  }

  const handleSubmit = async e => {
    e.preventDefault()

    const updatedJobData = {
      ...jobData,
      worker: `${jobData?.data?.firstName || "Unknown"} ${
        jobData?.data?.lastName || ""
      }`,
      email: jobData?.data?.email || "Not Available",
      jobstatus: "suggested",
    }

    const jobID = updatedJobData.id

    if (jobID) {
      JobDataService.createJob(jobID)
        .then(() => {
          alert("New job created successfully!")
          onClose()
        })
        .catch(error => {
          console.error("Error creating job:", error)
          alert("Failed to create new job.")
        })
    } else {
      console.error("Job ID is undefined.")
      alert("Failed to create job. Job ID is missing.")
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      style={customStyles}
      contentLabel="New Job Profile"
    >
      <h2 style={{ color: "#333", textAlign: "center" }}>New Job Profile</h2>
      <div style={{ marginBottom: "20px" }}>
        <h3>Job Details</h3>
        <div
          style={{
            backgroundColor: "#fff",
            padding: "10px",
            borderRadius: "5px",
            boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
          }}
        >
          <p>
            Provider:{" "}
            <strong>{jobData?.data?.provider || "Not Available"}</strong>
          </p>
          <p>
            Care Home:{" "}
            <strong>{jobData?.data?.careHome || "Not Available"}</strong>
          </p>
          <p>
            Start Date & Time:{" "}
            <strong>
              {jobData?.start
                ? convertToFriendlyDateUK(jobData.start)
                : "Not Available"}
            </strong>
          </p>
          <p>
            End Date & Time:{" "}
            <strong>
              {jobData?.end
                ? convertToFriendlyDateUK(jobData.end)
                : "Not Available"}
            </strong>
          </p>
        </div>
      </div>

      <form onSubmit={handleSubmit}>
        <div style={{ marginBottom: "10px" }}>
          <label>Start:</label>
          <input
            type="number"
            value={hours}
            onChange={e => setHours(e.target.value)}
            required
            style={{ width: "100%", padding: "8px", marginTop: "5px" }}
          />
        </div>
        <div style={{ marginBottom: "10px" }}>
          <label>End:</label>
          <input
            type="number"
            value={hours}
            onChange={e => setHours(e.target.value)}
            required
            style={{ width: "100%", padding: "8px", marginTop: "5px" }}
          />
        </div>
        <div style={{ marginBottom: "20px" }}>
          <label>Comments:</label>
          <textarea
            value={comments}
            onChange={e => setComments(e.target.value)}
            style={{
              width: "100%",
              padding: "8px",
              marginTop: "5px",
              height: "100px",
            }}
          />
        </div>
        <div style={{ textAlign: "right" }}>
          <button
            type="button"
            onClick={onClose}
            style={{
              marginRight: "10px",
              padding: "10px 20px",
              backgroundColor: "gray",
              color: "white",
              border: "none",
              borderRadius: "5px",
            }}
          >
            Cancel
          </button>
          <button
            type="submit"
            style={{
              padding: "10px 20px",
              backgroundColor: "#007bff",
              color: "white",
              border: "none",
              borderRadius: "5px",
            }}
          >
            Submit
          </button>
        </div>
      </form>
    </Modal>
  )
}

export default NewJobModal
