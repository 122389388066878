// Firebase imports
import { initializeApp } from "firebase/app"
import {
  getDatabase,
  ref,
  push,
  onValue,
  update,
  query,
  orderByChild,
  remove,
} from "firebase/database"

// Firebase configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_APIKEY,
  authDomain: process.env.REACT_APP_AUTHDOMAIN,
  databaseURL: process.env.REACT_APP_DATABASEURL,
  projectId: process.env.REACT_APP_PROJECTID,
  storageBucket: process.env.REACT_APP_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_APPID,
  measurementId: process.env.REACT_APP_MEASUREMENTID,
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
const db = getDatabase(app)

class BusinessUnitDataService {
  constructor() {
    this.dbRef = ref(getDatabase(), "/businessunit")
  }

  getBusinessUnitCount(callback) {
    return onValue(this.dbRef, snapshot => {
      const data = snapshot.val()
      const count = data ? Object.keys(data).length : 0
      callback(count)
    })
  }

  // getAll(callback) {
  //   return onValue(this.dbRef, snapshot => {
  //     const data = snapshot.val()
  //     let businessunits = []
  //     if (data) {
  //       businessunits = Object.keys(data).map(key => ({ key, ...data[key] }))
  //     }
  //     callback(businessunits)
  //   })
  // }

  getAll(callback) {
    // Query to order business units by the 'createdAt' field
    const businessUnitsByLatestRef = query(
      this.dbRef,
      orderByChild("createdAt")
    )

    return onValue(businessUnitsByLatestRef, snapshot => {
      const data = snapshot.val()
      let businessunits = []

      if (data) {
        // Convert the object into an array of business units and include the 'key' property
        businessunits = Object.keys(data).map(key => ({ key, ...data[key] }))

        // Sort business units by 'createdAt' in descending order (latest first)
        businessunits.sort((a, b) => (b.createdAt || 0) - (a.createdAt || 0)) // Descending order
      }

      if (typeof callback === "function") {
        callback(businessunits) // Pass the sorted business units to the callback
      } else {
        console.error("Callback is not a function")
      }
    })
  }

  // Method to get business units by provider
  getBusinessUnitsByProvider(providerName, callback) {
    return onValue(this.dbRef, snapshot => {
      const data = snapshot.val()
      // console.log('Get Businesses for: ' + providerName + " " + JSON.stringify(data));
      let businessUnits = []
      if (data) {
        businessUnits = Object.keys(data)
          .map(key => ({ key, ...data[key] }))
          .filter(bu => bu.provider === providerName)
      }
      // console.log('the Businesses for: ' + (businessUnits));
      callback(businessUnits)
    })
  }

  getBusinessUnitByKey(key, callback) {
    const specificBURef = ref(getDatabase(), `/businessunit/${key}`)
    return onValue(specificBURef, snapshot => {
      const data = snapshot.val()
      callback({ key, ...data })
    })
  }

  createBusinessUnit(bu) {
    return push(this.dbRef, bu)
  }

  updateBusinessUnit(key, value) {
    const specificBURef = ref(getDatabase(), `/businessunit/${key}`)
    return update(specificBURef, value)
  }

  deleteBusinessUnit(key) {
    const specificBURef = ref(getDatabase(), `/businessunit/${key}`)
    return remove(specificBURef)
  }
}

export default new BusinessUnitDataService()
