// Firebase imports
import { initializeApp } from 'firebase/app';
import { getDatabase, ref, push, onValue, update, remove, query, orderByChild } from 'firebase/database';

// Firebase configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_APIKEY,
  authDomain: process.env.REACT_APP_AUTHDOMAIN,
  databaseURL: process.env.REACT_APP_DATABASEURL,
  projectId: process.env.REACT_APP_PROJECTID,
  storageBucket: process.env.REACT_APP_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_APPID,
  measurementId: process.env.REACT_APP_MEASUREMENTID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getDatabase(app);

class ApplicationDataService {
  constructor() {
    this.dbRef = ref(getDatabase(), "/jobdetail")
  }

  getApplicationCount(callback) {
    return onValue(this.dbRef, snapshot => {
      const data = snapshot.val()
      const count = data ? Object.keys(data).length : 0
      callback(count)
    })
  }

  // getAll(callback) {
  //   return onValue(this.dbRef, (snapshot) => {
  //     const data = snapshot.val();
  //     let application = [];
  //     if (data) {
  //       application = Object.keys(data).map(key => ({ key, ...data[key] }));
  //     }
  //     callback(application);
  //   });
  // }

  getAll(callback) {
    // Query to order applications by the 'createdAt' field
    const applicationsByLatestRef = query(this.dbRef, orderByChild("createdAt"))

    return onValue(applicationsByLatestRef, snapshot => {
      const data = snapshot.val()
      let applications = []

      if (data) {
        // Convert object to an array and include the 'key' property
        applications = Object.keys(data).map(key => ({ key, ...data[key] }))

        // Sort applications by 'createdAt' in descending order (latest first)
        applications.sort((a, b) => (b.createdAt || 0) - (a.createdAt || 0)) // Descending order
      }

      if (typeof callback === "function") {
        callback(applications) // Pass sorted applications to the callback
      } else {
        console.error("Callback is not a function")
      }
    })
  }

  createApplication(application) {
    return push(this.dbRef, application)
  }

  updateApplication(key, value) {
    const specificApplicationRef = ref(getDatabase(), `/jobdetail/${key}`)
    return update(specificApplicationRef, value)
  }

  deleteApplication(key) {
    const specificApplicationRef = ref(getDatabase(), `/jobdetail/${key}`)
    return remove(specificApplicationRef)
  }
}

export default new ApplicationDataService();