import React, { useState } from "react"
import Modal from "react-modal"
import TimesheetDataService from "services/TimesheetDataService"

// Custom styling for the modal
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    maxWidth: "500px",
    padding: "20px",
    backgroundColor: "#f7f7f7",
    borderRadius: "10px",
  },
  overlay: {
    backgroundColor: "rgba(0,0,0,0.5)",
  },
}

Modal.setAppElement("#root")

function TimesheetModal({ isOpen, onClose, jobData }) {
  const [hours, setHours] = useState("")
  const [comments, setComments] = useState("")
  const [file, setFile] = useState(null)

    const convertToFriendlyDateUK = isoDateString => {
      const date = new Date(isoDateString)
      return date.toLocaleString("en-GB", {
        day: "numeric",
        month: "long",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      })
    }


  const handleSubmit = async e => {
    e.preventDefault()
    const timesheetData = {
      jobId: jobData?.data?.key,
      hours,
      comments,
      file: file,
    }
    try {
      await TimesheetDataService.createTimesheet(timesheetData)
      alert("Timesheet submitted successfully!")
      onClose() // Still provide a way to close the modal
    } catch (error) {
      console.error("Error submitting timesheet:", error)
      alert("An error occurred while submitting the timesheet.")
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      style={customStyles}
      contentLabel="Timesheet Modal"
    >
      <h2 style={{ color: "#333", textAlign: "center" }}>Submit Timesheet</h2>
      <div style={{ marginBottom: "20px" }}>
        <h3>Job Details</h3>
        {jobData && (
          <div
            style={{
              backgroundColor: "#fff",
              padding: "10px",
              borderRadius: "5px",
              boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
            }}
          >
            <p>
              Provider:{" "}
              <strong>{jobData?.data?.provider || "Not Available"}</strong>
            </p>
            <p>
              Care Home:{" "}
              <strong>{jobData?.data?.careHome || "Not Available"}</strong>
            </p>
            <p>
              Start Date & Time:{" "}
              <strong>
                {jobData?.start
                  ? convertToFriendlyDateUK(jobData.start)
                  : "Not Available"}
              </strong>{" "}
            </p>
            <p>
              End Date & Time:{" "}
              <strong>
                {jobData?.end
                  ? convertToFriendlyDateUK(jobData.end)
                  : "Not Available"}
              </strong>
            </p>
          </div>
        )}
      </div>
      <form onSubmit={handleSubmit}>
        <div style={{ marginBottom: "10px" }}>
          <label>
            Upload Timesheet File:
            <input
              type="file"
              onChange={e => setFile(e.target.files[0])}
              style={{ width: "100%", padding: "8px", marginTop: "5px" }}
            />
          </label>
          <label>Hours Worked:</label>
          <input
            type="number"
            value={hours}
            onChange={e => setHours(e.target.value)}
            required
            style={{ width: "100%", padding: "8px", marginTop: "5px" }}
          />
        </div>
        <div style={{ marginBottom: "20px" }}>
          <label>Comments:</label>
          <textarea
            value={comments}
            onChange={e => setComments(e.target.value)}
            style={{
              width: "100%",
              padding: "8px",
              marginTop: "5px",
              height: "100px",
            }}
          />
        </div>
        <div style={{ textAlign: "right" }}>
          <button
            type="button"
            onClick={onClose}
            style={{
              marginRight: "10px",
              padding: "10px 20px",
              backgroundColor: "gray",
              color: "white",
              border: "none",
              borderRadius: "5px",
            }}
          >
            Cancel
          </button>
          <button
            type="submit"
            style={{
              padding: "10px 20px",
              backgroundColor: "#007bff",
              color: "white",
              border: "none",
              borderRadius: "5px",
            }}
          >
            Submit
          </button>
        </div>
      </form>
    </Modal>
  )
}

export default TimesheetModal
