import React, { useState, useEffect } from "react"
import { Popup } from "devextreme-react/popup"
import { Form, SimpleItem, RequiredRule } from "devextreme-react/form"
import { TabPanel, Item as TabItem } from "devextreme-react/tab-panel"
import "devextreme/dist/css/dx.light.css"
import WorkersAllocationService from "services/WorkersAllocationService"
import { ContextMenu } from "devextreme-react/context-menu"
import { confirm } from "devextreme/ui/dialog"
import notify from "devextreme/ui/notify"

const AllocationModal = ({ visible, onHiding, jobData }) => {
  const [companyName, setCompanyName] = useState("")
  const [eligibleWorkers, setEligibleWorkers] = useState([])
  const [suggestedWorkers, setSuggestedWorkers] = useState([])
  const [selectedWorkers, setSelectedWorkers] = useState([])
  const [rejectedWorkers, setRejectedWorkers] = useState([])

  useEffect(() => {
    const fetchAllocations = async jobId => {
      try {
        const [eligible, suggested, selected, rejected] = await Promise.all([
          WorkersAllocationService.getAllocationsByCategory(jobId, "eligible"),
          WorkersAllocationService.getAllocationsByCategory(jobId, "suggested"),
          WorkersAllocationService.getAllocationsByCategory(jobId, "selected"),
          WorkersAllocationService.getAllocationsByCategory(jobId, "rejected"),
        ])

        setEligibleWorkers(eligible)
        setSuggestedWorkers(suggested)
        setSelectedWorkers(selected)
        setRejectedWorkers(rejected)
      } catch (error) {
        console.error("Failed to fetch allocations:", error)
      }
    }

    if (!localStorage.getItem("authUserDetails")) {
      console.log("No user details found...")
    } else {
      let userDetails = JSON.parse(localStorage.getItem("authUserDetails"))
      const company = userDetails.userCompany
      setCompanyName(company)

      if (jobData && (jobData.key || jobData.id)) {
        const jobId = jobData.key || jobData.id
        console.log(`Fetching allocations for jobId: ${jobId}`) // Log jobId before fetching
        fetchAllocations(jobId)
      } else {
        console.error("Job data is missing key or id:", jobData)
      }
    }
  }, [jobData])

  const handleMoveWorker = async (
    worker,
    fromList,
    setFromList,
    toList,
    setToList,
    toCategory
  ) => {
    confirm("Are you sure you want to move this worker?").then(async result => {
      if (result) {
        const fromCategory =  fromList === eligibleWorkers ? "eligible"  
                            : fromList === suggestedWorkers ? "suggested"
                            : fromList === selectedWorkers ? "selected"
                            : "rejected"

        setFromList(fromList.filter(w => w.key !== worker.key))
        setToList([...toList, worker])

        // Update Firebase
        try {
          await WorkersAllocationService.removeWorkerFromCategory(
            jobId,
            fromCategory,
            worker.key
          )
          await WorkersAllocationService.addWorkerToCategory(
            jobId,
            toCategory,
            worker
          )
          notify(
            `${worker.firstName} ${worker.lastName} has been moved.`,
            "success",
            2000
          )
        } catch (error) {
          console.error(`Error moving worker ${worker.key}:`, error)
        }
      }
    })
  }

  const contextMenuItems = [
    {
      text: "Send to Eligible",
      action: (worker, list, setList) =>
        handleMoveWorker(
          worker,
          list,
          setList,
          eligibleWorkers,
          setEligibleWorkers,
          "eligible"
        ),
    },
    {
      text: "Send to Suggested",
      action: (worker, list, setList) =>
        handleMoveWorker(
          worker,
          list,
          setList,
          suggestedWorkers,
          setSuggestedWorkers,
          "suggested"
        ),
    },
    {
      text: "Send to Selected",
      action: (worker, list, setList) =>
        handleMoveWorker(
          worker,
          list,
          setList,
          selectedWorkers,
          setSelectedWorkers,
          "selected"
        ),
    },
    {
      text: "Send to Rejected",
      action: (worker, list, setList) =>
        handleMoveWorker(
          worker,
          list,
          setList,
          rejectedWorkers,
          setRejectedWorkers,
          "rejected"
        ),
    },
  ]

  const handleContextMenuClick = (e, list, setList) => {
    if (!e || !e.itemData) {
      console.error("Context menu event is missing itemData")
      return
    }

    const worker = list.find(w => w.key === e.itemData.key)
    if (!worker) {
      console.error("Worker not found in list:", e.itemData)
      return
    }

    const action = contextMenuItems.find(
      item => item.text === e.itemData.text
    ).action
    action(worker, list, setList)
  }

  const renderWorkerList = (workers, list, setList) => (
    <ul>
      {workers.length > 0 ? (
        workers.map(worker => (
          <li
            key={worker.key}
            style={{
              backgroundColor: "#f0f0f0",
              margin: "5px",
              padding: "5px",
              cursor: "pointer",
            }}
            onClick={() =>
              handleMoveWorker(
                worker,
                list,
                setList,
                suggestedWorkers,
                setSuggestedWorkers,
                "suggested"
              )
            }
            onContextMenu={e => {
              e.preventDefault()
              handleContextMenuClick(e, list, setList)
            }}
          >
            {worker.firstName} {worker.lastName} - {worker.workerType}
            <ContextMenu
              dataSource={contextMenuItems}
              target={`li#${worker.key}`}
              onItemClick={e => handleContextMenuClick(e, list, setList)}
            />
          </li>
        ))
      ) : (
        <div>No workers found.</div>
      )}
    </ul>
  )

  const safeJobData = jobData || {
    jobTitle: "",
    jobType: "",
    startDate: new Date(),
    endDate: new Date(),
    jobstatus: "open",
  }

  return (
    <Popup
      visible={visible}
      onHiding={onHiding}
      dragEnabled={true}
      closeOnOutsideClick={true}
      showTitle={true}
      title="Allocation Details"
      width={800}
      height={600}
      position={{ my: "center", at: "center", of: window }}
    >
      <div style={{ display: "flex", height: "100%" }}>
        <div
          style={{ flex: 1, padding: "10px", borderRight: "1px solid #ccc" }}
        >
          <Form formData={safeJobData}>
            <SimpleItem dataField="jobTitle" isRequired={true}>
              <RequiredRule />
            </SimpleItem>
            <SimpleItem dataField="jobType" isRequired={true}>
              <RequiredRule />
            </SimpleItem>
            <SimpleItem
              dataField="startDate"
              editorType="dxDateBox"
              editorOptions={{
                type: "datetime",
                displayFormat: "dd/MM/yyyy HH:mm",
              }}
              isRequired={true}
            >
              <RequiredRule />
            </SimpleItem>
            <SimpleItem
              dataField="endDate"
              editorType="dxDateBox"
              editorOptions={{
                type: "datetime",
                displayFormat: "dd/MM/yyyy HH:mm",
              }}
              isRequired={true}
            >
              <RequiredRule />
            </SimpleItem>
            <SimpleItem
              dataField="jobstatus"
              editorType="dxSelectBox"
              isRequired={true}
            >
              <RequiredRule />
            </SimpleItem>
          </Form>
        </div>
        <div style={{ flex: 3, padding: "10px" }}>
          <TabPanel>
            <TabItem title="Eligible Workers">
              {renderWorkerList(
                eligibleWorkers,
                eligibleWorkers,
                setEligibleWorkers
              )}
            </TabItem>
            <TabItem title="Suggested Workers">
              {renderWorkerList(
                suggestedWorkers,
                suggestedWorkers,
                setSuggestedWorkers
              )}
            </TabItem>
            <TabItem title="Selected Workers">
              {renderWorkerList(
                selectedWorkers,
                selectedWorkers,
                setSelectedWorkers
              )}
            </TabItem>
            <TabItem title="Rejected Workers">
              {renderWorkerList(
                rejectedWorkers,
                rejectedWorkers,
                setRejectedWorkers
              )}
            </TabItem>
          </TabPanel>
        </div>
      </div>
    </Popup>
  )
}

export default AllocationModal
