import React, { useState, useEffect, useMemo } from "react"
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete"
import debounce from "lodash/debounce"
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap"
import JobDataService from "services/JobDataService"
import emailjs from "@emailjs/browser"
import { getFirestore, collection, getDocs } from "firebase/firestore"
import OrganisationDataService from "services/OrganisationDataService"
import BusinessUnitDataService from "services/BusinessUnitDataService"
import WorkersDataService from "services/WorkersDataService"
import WorkersAllocationService from "services/WorkersAllocationService"
import avatar from "../../assets/images/users/user.png"
import UserPill from "./UserPill";
import "./booking.css";
import { useTable, useSortBy } from "react-table";
import jsPDF from "jspdf";


const Booking = () => {
  const [jobs, setJobs] = useState([])
  const [currentJob, setCurrentJob] = useState({
    jobTitle: "",
    jobDescription: "",
    jobExperience: "",
    jobType: "",
    provider: "",
    company: "",
    careHome: "",
    location: "",
    coordinates: "",
    workerType: "",
    noofVacancies: "",
    startDate: "",
    startTime: "",
    endTime: "",
    endDate: "",
    enableRecurrence: "",
    breaks: "",
    jobstatus: "open",
    jobID: "",
    category: "",
    featured: "",
    employee: "",
    businessUnits: "",
    timesheetStatus: "Not Created",
    createdAt: Date.now(),
  })
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [locationInput, setLocationInput] = useState("")
  const [coordinatesStatus, setCoordinatesStatus] = useState({
    resolved: false,
    message: "",
  })

  const [organisations, setOrganisations] = useState([])
  const [businessUnits, setBusinessUnits] = useState([])
  const [workers, setWorkers] = useState([])
  const [selectedWorker, setSelectedWorker] = useState(null)
  const [isWorkerModalOpen, setIsWorkerModalOpen] = useState(false)
  const [selectedOrg, setSelectedOrg] = useState("")
  const [selectedBU, setselectedBU] = useState("")
  const [selectionFeedback, setSelectionFeedback] = useState("")

  // Fetch jobs when the component mounts
  useEffect(() => {
    const fetchJobs = async () => {
      try {
        const jobList = await JobDataService.getAllJobs()
        setJobs(jobList)
      } catch (error) {
        console.error("Error fetching jobs: ", error)
      }
    }

    fetchJobs()
  }, [])

  const handleWorkerSelection = worker => {
    setCurrentJob(prevJob => ({
      ...prevJob,
      employee: `${worker.firstName} ${worker.lastName}`,
      email: worker.workerEmailAddress,
      location: worker.residentialAddress,
      company: worker.company,
    }))
    setSelectionFeedback(
      "Worker selected: " + worker.firstName + " " + worker.lastName
    )
    setIsWorkerModalOpen(false)
  }

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen)
    if (!isModalOpen) {
      setCurrentJob(prevJob => ({ ...prevJob, provider: selectedOrg }))
    } else {
      resetForm()
    }
  }

  const handleSelectChange = e => {
    const selectedValue = e.target.value
    setSelectedOrg(selectedValue)
    setCurrentJob(prevJob => ({
      ...prevJob,
      provider: selectedValue,
    }))
  }

  const handleSelectBUChange = e => {
    const selectedValue = e.target.value
    setselectedBU(selectedValue)

    setCurrentJob(currentJob => ({
      ...currentJob,
      businessUnits: selectedValue,
    }))
  }

  useEffect(() => {
    setLocationInput(currentJob.location)
  }, [currentJob.location])

  useEffect(() => {
    const unsubscribe = OrganisationDataService.getAll(data => {
      setOrganisations(data)
    })

    return () => unsubscribe && unsubscribe()
  }, [])

  useEffect(() => {
    let isActive = true

    if (selectedOrg) {
      const unsubscribeBU = BusinessUnitDataService.getBusinessUnitsByProvider(
        selectedOrg,
        data => {
          if (isActive) {
            setBusinessUnits(data)
          }
        }
      )

      return () => {
        isActive = false
        if (unsubscribeBU) {
          unsubscribeBU()
        }
      }
    }
  }, [selectedOrg])

  useEffect(() => {
    console.log(
      "useEffect" +
        "Selected org: " +
        selectedOrg +
        " JT: " +
        currentJob.jobType
    )
    if (selectedOrg && currentJob.jobType) {
      fetchWorkers(selectedOrg, currentJob.jobType)
    }
  }, [selectedOrg, currentJob.jobType])

  const fetchWorkers = (provider, jobType) => {
    console.log(
      "getWorkersByTypeAndProvider" +
        "provider: " +
        provider +
        " jobType: " +
        currentJob.jobType
    )

    WorkersDataService.getWorkersByTypeAndProvider(jobType, provider)
      .then(data => {
        setWorkers(data)
      })
      .catch(error => {
        console.error("Failed to fetch workers:", error)
      })
  }

  const handleWorkerClick = worker => {
    if (!worker) {
      console.error("No worker data available")
      return
    }
    setSelectedWorker(worker)
    setIsWorkerModalOpen(true)
  }


  const handleInputChange = async event => {
    const { name, value } = event.target

    if (name === "jobID") {
      const fixedPrefix = currentJob.jobID.substring(0, 14)
      if (value.startsWith(fixedPrefix)) {
        setCurrentJob({ ...currentJob, [name]: value })
      }
    } else if (name === "location") {
      setLocationInput(value)
      setCurrentJob({ ...currentJob, [name]: value })
    } else {
      setCurrentJob({ ...currentJob, [name]: value })
    }

    // Handle the case when the input field is 'company'
    if (name === "provider") {
      // Fetch company name asynchronously when provider changes
      const companyName = await getCompanyName(value)
      setCurrentJob(prevJob => ({
        ...prevJob,
        provider: value,
        company: companyName || "Company not found", // Fallback if company not found
      }))
    }

    if (name === "jobType") {
      setCurrentJob({ ...currentJob, jobType: value })
      if (value && currentJob.provider) {
        fetchWorkers(currentJob.provider, value)
      }
    } else {
      setCurrentJob({ ...currentJob, [name]: value })
    }
  }


  const generatejobID = () => {
    const currentDate = new Date()
    const day = currentDate.getDay()
    const sunday = new Date(currentDate)
    sunday.setDate(currentDate.getDate() - day)

    const formattedDate = `${String(sunday.getDate()).padStart(2, "0")}${String(
      sunday.getMonth() + 1
    ).padStart(2, "0")}${sunday.getFullYear()}`

    // Generate a random five-character alphanumeric suffix
    const randomSuffix = Math.random()
      .toString(36)
      .substring(2, 7)
      .toUpperCase()

    return `JOB-WK${formattedDate}-${randomSuffix}`
  }

  // Function to fetch the company name (Provider)
  async function getCompanyName(companyKey) {
    try {
      console.log('Getting company name: ' + companyKey)
      const companyData = await OrganisationDataService.getOrganisationByKey(
        companyKey
      )
      console.log("Got company data: " + companyData)
      if (companyData && companyData.businessName) {
        console.log("Got company name: " + companyData.businessName)
        return companyData.businessName
      } else {
        console.error("Company not found for key: ", companyKey)
        return null // Return null if no company found
      }
    } catch (error) {
      console.error("Error fetching company name: ", error)
      return null // Handle error gracefully
    }
  }

  const submitHandler = async event => {
    event.preventDefault()

    if (!currentJob.employee) {
      alert("Please select at least one worker before submitting the job.")
      return
    }

    try {
      const generatedJobID = generatejobID(jobs)
      const updatedJobData = {
        ...currentJob,
        provider: selectedOrg,
        company: selectedOrg,
        jobID: generatedJobID,
        key: generatedJobID,
      }

      console.log("Updated job data: ", JSON.stringify(updatedJobData))

      const createdJobID = await JobDataService.createJob(updatedJobData)

      setCurrentJob({
        ...updatedJobData,
        provider: selectedOrg,
        company: selectedOrg,
        jobstatus: "open",
        jobID: createdJobID,
        key: createdJobID,
      })

      await WorkersAllocationService.addEligibleWorkers(generatedJobID, workers)
      const updatedJobList = await JobDataService.getAllJobs()
      setJobs(updatedJobList)

      resetForm()
      toggleModal()
    } catch (error) {
      console.log("Error in form submission:", error)
    }
  }

  const resetForm = () => {
    setCurrentJob({
      jobTitle: "",
      jobDescription: "",
      jobExperience: "",
      jobType: "",
      careHome: "",
      workerType: "",
      noofVacancies: "",
      location: "",
      coordinates: "",
      startDate: "",
      startTime: "",
      endTime: "",
      enableRecurrence: "",
      breaks: "",
      jobstatus: "open",
      jobID: "",
      category: "",
    })
  }

  const editJob = job => {
    setCurrentJob({ ...job, jobID: job.key || "" })
    toggleModal()
  }

  const deleteJob = key => {
    JobDataService.deleteJob(key)
  }

  const WorkerDetailModal = ({ worker, isOpen, toggle }) => {
    const [activeTab, setActiveTab] = useState("details")

    const getTabStyle = tabName => ({
      cursor: "pointer",
      color: activeTab === tabName ? "white" : "black",
      backgroundColor: activeTab === tabName ? "blue" : "",
      padding: "10px",
    })

    return (
      <Modal isOpen={isOpen} toggle={toggle} size="xl" backdrop={true}>
        <ModalHeader toggle={toggle} style={{ color: "white !important" }}>
          Details for {worker.firstName} {worker.lastName} | {worker.key}
        </ModalHeader>
        <div className="nav nav-tabs">
          <a
            className={`nav-item nav-link ${
              activeTab === "details" ? "active" : ""
            }`}
            // style={getTabStyle("details")}
            onClick={() => setActiveTab("details")}
            style={{ color: "black" }}
          >
            Candidate Details
          </a>
          <a
            className={`nav-item nav-link ${
              activeTab === "cv" ? "active" : ""
            }`}
            // style={getTabStyle("cv")}
            onClick={() => setActiveTab("cv")}
            style={{ color: "black" }}
          >
            CV
          </a>
          <a
            className={`nav-item nav-link ${
              activeTab === "compliance" ? "active" : ""
            }`}
            // style={getTabStyle("compliance")}
            onClick={() => setActiveTab("compliance")}
            style={{ color: "black" }}
          >
            Compliance
          </a>
        </div>
        <ModalBody>
          {activeTab === "details" && (
            <div>
              <fieldset>
                <legend>Personal Details</legend>
                <p>
                  <strong>Name:</strong> {worker.firstName} {worker.lastName}
                </p>
                <p>
                  <strong>Gender:</strong> {worker.gender}
                </p>
              </fieldset>
              <fieldset>
                <legend>Address</legend>
                <p>
                  <strong>Street:</strong> {worker.residentialAddress}
                </p>
                <p>
                  <strong>Post code:</strong> {worker.postcode}
                </p>
                <p>
                  <strong>Country:</strong> {worker.country}
                </p>
              </fieldset>
              <fieldset>
                <legend>Contact Details</legend>
                <p>
                  <strong>Email:</strong>{" "}
                  <a
                    href={"mailto:" + worker.workerEmailAddress}
                    rel="noreferrer"
                  >
                    {worker.workerEmailAddress}
                  </a>
                </p>
                <p>
                  <strong>Phone Number:</strong> {worker.phoneNumber}
                </p>
              </fieldset>
            </div>
          )}
          {activeTab === "cv" && (
            <div>
              <p>
                <strong>CV:</strong>&nbsp;&nbsp;
                <a href={worker.cv} rel="noreferrer" target="_blank">
                  Download
                </a>
              </p>
            </div>
          )}
          {activeTab === "compliance" && (
            <div>
              <p>
                <strong>Visa Work Permit:</strong>&nbsp;&nbsp;
                <a
                  href={worker.visa_workpermit}
                  rel="noreferrer"
                  target="_blank"
                >
                  Download
                </a>
              </p>
              <p>
                <strong>Reference Proof:</strong>&nbsp;&nbsp;
                <a
                  href={worker.referenceProof}
                  rel="noreferrer"
                  target="_blank"
                >
                  Download
                </a>
              </p>
              <p>
                <strong>National Insurance:</strong>&nbsp;&nbsp;
                <a
                  href={worker.nationalInsurance}
                  rel="noreferrer"
                  target="_blank"
                >
                  Download
                </a>
              </p>
              <p>
                <strong>DBS:</strong>&nbsp;&nbsp;
                <a href={worker.dbs} rel="noreferrer" target="_blank">
                  Download
                </a>
              </p>
              <p>
                <strong>COVID:</strong>&nbsp;&nbsp;
                <a href={worker.covid} rel="noreferrer" target="_blank">
                  Download
                </a>
              </p>
            </div>
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => {
              setCurrentJob(prevJob => ({
                ...prevJob,
                employee: `${worker.firstName} ${worker.lastName}`,
                email: worker.workerEmailAddress,
                location: worker.residentialAddress,
              }))
              toggle()
            }}
          >
            Select This Worker
          </Button>

          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    )
  }

  const columns = useMemo(
    () => [
      {
        Header: "No.",
        id: "row",
        accessor: "",
        Cell: ({ row }) => row.index + 1,
      },
      {
        Header: "Job Type",
        accessor: "jobType",
      },
      {
        Header: "Job ID",
        accessor: "key",
      },
      {
        Header: "Provider",
        accessor: "provider",
      },
      {
        Header: "Location",
        accessor: "location",
      },
      {
        Header: "Start Date",
        accessor: "startDate",
      },
      {
        Header: "End Date",
        accessor: "endDate",
      },
      {
        Header: "Status",
        accessor: "jobstatus",
      },
      {
        Header: "Actions",
        accessor: "actions",
        Cell: ({ row }) => (
          <>
            <Button onClick={() => editJob(row.original)}>Edit</Button>
            &nbsp;&nbsp;&nbsp;
            <Button onClick={() => deleteJob(row.original.key)}>Delete</Button>
          </>
        ),
      },
    ],
    []
  )

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data: jobs || [] }, useSortBy)

  return (
    <div className="page-content">
      {selectedWorker && (
        <WorkerDetailModal
          worker={selectedWorker}
          isOpen={isWorkerModalOpen}
          toggle={() => setIsWorkerModalOpen(false)}
        />
      )}

      <Button
        color="primary"
        style={{ margin: "10px" }}
        onClick={() => toggleModal()}
      >
        Add New Job
      </Button>
      <span
        style={{ float: "right" }}
      >{`Displaying ${jobs.length} job(s)`}</span>
      <Modal
        isOpen={isModalOpen}
        toggle={toggleModal}
        backdrop={true}
        size="xl"
      >
        <ModalHeader toggle={toggleModal}>
          {currentJob.jobID ? "Create a new Job" : "Create Job"} |{" "}
          {currentJob.key}
        </ModalHeader>
        <Form onSubmit={submitHandler}>
          <ModalBody style={{ display: "flex", flexDirection: "row" }}>
            <div
              style={{
                flex: 1,
                paddingRight: "20px",
                borderRight: "1px solid #ccc",
                textAlign: "center",
              }}
            >
              <img
                src={avatar}
                alt="User list"
                className="avatar-md rounded-circle img-thumbnail"
                style={{
                  objectFit: "cover",
                  width: "auto",
                }}
              />
              <ul style={{ listStyle: "none", padding: 0 }}>
                <li>Select a client, business unit</li>
                <li>and job to view eligible</li>
                <li>candidates</li>
              </ul>

              <Table style={{ textAlign: "justify" }}>
                <thead>
                  <tr>
                    <th>Worker Firstname</th>
                    <th>Worker Lastname</th>
                  </tr>
                </thead>
                <tbody>
                  {workers.map(worker => (
                    <tr
                      key={worker.id}
                      className="table-row"
                      onClick={() => handleWorkerClick(worker)}
                    >
                      <td>{worker.firstName}</td>
                      <td>{worker.lastName}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>

            <div style={{ flex: 3, paddingLeft: "20px" }}>
              <div>Fields marked with an asterisk (*) are required.</div>

              <FormGroup>
                {selectionFeedback && (
                  <div className="feedback-message">{selectionFeedback}</div>
                )}

                <Label for="provider">
                  Provider<span style={{ color: "red" }}>*</span>
                </Label>
                <select
                  className="form-control"
                  value={selectedOrg}
                  onChange={handleSelectChange}
                >
                  <option value="">Select a Provider</option>
                  {organisations.map(org => (
                    <option key={org.key} value={org.key}>
                      {org.businessName}
                    </option>
                  ))}
                </select>
                <span style={{ visibility: "hidden" }}>
                  <Input
                    type="text"
                    name="provider"
                    id="provider"
                    value={currentJob.provider}
                    onChange={handleInputChange}
                    required
                  />
                </span>
              </FormGroup>

              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <FormGroup style={{ flex: 1, marginRight: "20px" }}>
                  <Label for="startDate">
                    Start Date<span style={{ color: "red" }}>*</span>
                  </Label>
                  <Input
                    type="date"
                    name="startDate"
                    id="startDate"
                    value={currentJob.startDate}
                    onChange={handleInputChange}
                    required
                  />
                </FormGroup>
                <FormGroup style={{ flex: 1 }}>
                  <Label for="startTime">
                    Start Time<span style={{ color: "red" }}>*</span>
                  </Label>
                  <Input
                    type="time"
                    name="startTime"
                    id="startTime"
                    value={currentJob.startTime}
                    onChange={handleInputChange}
                    required
                  />
                </FormGroup>
              </div>

              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <FormGroup style={{ flex: 1, marginRight: "20px" }}>
                  <Label for="endDate">
                    End Date<span style={{ color: "red" }}>*</span>
                  </Label>
                  <Input
                    type="date"
                    name="endDate"
                    id="endDate"
                    value={currentJob.endDate}
                    onChange={handleInputChange}
                    required
                  />
                </FormGroup>
                <FormGroup style={{ flex: 1 }}>
                  <Label for="endTime">
                    End Time<span style={{ color: "red" }}>*</span>
                  </Label>
                  <Input
                    type="time"
                    name="endTime"
                    id="endTime"
                    value={currentJob.endTime}
                    onChange={handleInputChange}
                    required
                  />
                </FormGroup>
              </div>

              <FormGroup>
                <Label for="careHome">
                  Business Unit {`(${businessUnits.length})`}
                </Label>
                <select
                  className="form-control"
                  value={selectedBU}
                  onChange={handleSelectBUChange}
                >
                  <option value="">Select a Business Unit</option>
                  {businessUnits.map(bu => (
                    <option key={bu.key} value={bu.key}>
                      {bu.businessName}
                    </option>
                  ))}
                </select>

                <span style={{ visibility: "hidden" }}>
                  <Input
                    type="text"
                    name="careHome"
                    id="careHome"
                    value={currentJob.businessUnits}
                    onChange={handleInputChange}
                  />
                </span>

                <span style={{ visibility: "hidden" }}>
                  <Input
                    type="text"
                    name="company"
                    id="company"
                    value={currentJob.company}
                    onChange={handleInputChange}
                  />
                </span>
              </FormGroup>
              <FormGroup>
                <Label for="jobTitle">
                  Job Title<span style={{ color: "red" }}>*</span>
                </Label>
                <Input
                  type="text"
                  name="jobTitle"
                  id="jobTitle"
                  value={currentJob.jobTitle}
                  onChange={handleInputChange}
                  required
                />
              </FormGroup>
              <FormGroup>
                <Label for="jobID">
                  Booking Reference/Number
                  <span style={{ color: "red" }}>*</span>
                </Label>
                <Input
                  type="text"
                  name="jobID"
                  id="jobID"
                  value={currentJob.jobID || ""}
                  onChange={handleInputChange}
                  required
                />
              </FormGroup>
              <FormGroup>
                <Label for="jobDescription">Job Description</Label>
                <Input
                  type="textarea"
                  name="jobDescription"
                  id="jobDescription"
                  value={currentJob.jobDescription}
                  onChange={handleInputChange}
                />
              </FormGroup>
              <FormGroup>
                <Label for="jobType">
                  Job Type<span style={{ color: "red" }}>*</span>
                </Label>
                <Input
                  type="select"
                  name="jobType"
                  id="jobType"
                  value={currentJob.jobType}
                  onChange={handleInputChange}
                >
                  <option value="">Select job type</option>
                  <option value="Support worker">Support worker</option>
                  <option value="Sleep in">Sleep in</option>
                  <option value="Health Care Assistant">
                    Health Care Assistant
                  </option>
                  <option value="Registered Mental Health Nurse">
                    Registered Mental Health Nurse
                  </option>
                  <option value="Registered General Nurse">
                    Registered General Nurse
                  </option>
                  <option value="Doctor">Doctor</option>
                </Input>
              </FormGroup>

              {(currentJob.employee || currentJob.email) && (
                <FormGroup>
                  <UserPill
                    employeeName={currentJob.employee}
                    employeeEmail={currentJob.email}
                    employeeLocation={currentJob.location}
                  />

                  <Label for="employee">Employee</Label>
                  <Input
                    type="text"
                    name="employee"
                    id="employee"
                    value={currentJob.employee}
                    onChange={handleInputChange}
                    required
                  />
                </FormGroup>
              )}

              <FormGroup>
                <Label for="location">Location</Label>
                <PlacesAutocomplete
                  value={locationInput}
                  onChange={setLocationInput}
                  onSelect={async address => {
                    setLocationInput(address)
                    try {
                      const results = await geocodeByAddress(address)
                      const latLng = await getLatLng(results[0])
                      setCurrentJob(prevJob => ({
                        ...prevJob,
                        location: address,
                        coordinates: `${latLng.lat}, ${latLng.lng}`,
                      }))
                      setCoordinatesStatus({ resolved: true, message: "✓" })
                    } catch (error) {
                      console.error("Error getting coordinates:", error)
                      setCoordinatesStatus({
                        resolved: false,
                        message: " ",
                      })
                    }
                  }}
                >
                  {({
                    getInputProps,
                    suggestions,
                    getSuggestionItemProps,
                    loading,
                  }) => (
                    <div>
                      <Input
                        {...getInputProps({
                          placeholder: "Search Places ...",
                          className: "location-search-input",
                          required: true,
                          style: coordinatesStatus.resolved
                            ? { backgroundColor: "darkgreen", color: "white" }
                            : {},
                        })}
                        style={{ flex: 1, marginRight: "5px" }}
                      />
                      <div className="autocomplete-dropdown-container">
                        {loading && <div>Loading...</div>}
                        {suggestions.map(suggestion => {
                          const className = suggestion.active
                            ? "suggestion-item--active"
                            : "suggestion-item"
                          const style = suggestion.active
                            ? { backgroundColor: "#fafafa", cursor: "pointer" }
                            : { backgroundColor: "#ffffff", cursor: "pointer" }
                          return (
                            <div
                              key={suggestion.placeId}
                              {...getSuggestionItemProps(suggestion, {
                                className,
                                style,
                              })}
                            >
                              <span>{suggestion.description}</span>
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  )}
                </PlacesAutocomplete>
                <span style={{ flex: 0, width: "10%", fontSize: "20" }}>
                  {coordinatesStatus.message}
                </span>
              </FormGroup>

              <FormGroup style={{ visibility: "hidden" }}>
                <Label for="coordinates">Coordinates</Label>
                <Input
                  type="text"
                  name="coordinates"
                  id="coordinates"
                  value={currentJob.coordinates}
                  onChange={handleInputChange}
                  required
                  disabled={true}
                  style={{
                    backgroundColor: coordinatesStatus.resolved
                      ? "lightgreen"
                      : "lightcoral",
                  }}
                />
              </FormGroup>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" type="submit">
              {currentJob.jobID ? "Update" : "Create"}
            </Button>
            <Button color="secondary" onClick={toggleModal}>
              Cancel
            </Button>
          </ModalFooter>
        </Form>
      </Modal>

      {jobs && jobs.length > 0 ? (
        <Table
          {...getTableProps()}
          className="table table-striped table-bordered"
        >
          <thead>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()} key={headerGroup.id}>
                {headerGroup.headers.map(column => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    key={column.id}
                  >
                    {column.render("Header")}
                    {column.isSorted
                      ? column.isSortedDesc
                        ? " 🔽"
                        : " 🔼"
                      : ""}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map(row => {
              prepareRow(row)
              return (
                <tr {...row.getRowProps()} key={row.id}>
                  {row.cells.map(cell => (
                    <td {...cell.getCellProps()} key={cell.column.id}>
                      {cell.render("Cell")}
                    </td>
                  ))}
                </tr>
              )
            })}
          </tbody>
        </Table>
      ) : (
        <p>No jobs available</p>
      )}
    </div>
  )
}

export default Booking
