import React, { useEffect, useState } from "react"
import { Link, useParams, useNavigate } from "react-router-dom"
import { Card, CardBody, Col } from "reactstrap"
import JobDataService from "./../../../services/JobDataService"
import ApplicationDataService from "./../../../services/ApplicationDataService"
import WorkersAllocationService from "./../../../services/WorkersAllocationService"

// import images
import adobephotoshop from "../../../assets/images/companies/adobe-photoshop.svg"

const Overview = () => {
  const [job, setJob] = useState(null)
  const [allJobs, setAllJobs] = useState(null)
  const [email, setEmail] = useState("")
  const [userName, setUserName] = useState("")
  const [companyName, setCompanyName] = useState("")
  const navigate = useNavigate()

  const [currentJob, setCurrentJob] = useState({
    jobTitle: "",
    jobDescription: "",
    jobType: "",
    provider: "",
    careHome: "",
    workerType: "",
    noofVacancies: "",
    location: "",
    startDate: "",
    startTime: "",
    endDate: "",
    endTime: "",
    enableRecurrence: "",
    breaks: "",
    jobstatus: "",
    jobID: "",
    category: "",
    featured: "",
  })

  useEffect(() => {
    if (localStorage.getItem("authUserDetails")) {
      let userDetails = JSON.parse(localStorage.getItem("authUserDetails"))

      let userEmail = userDetails.email
      if (userEmail) setEmail(userEmail)

      let name = userDetails.fullName
      if (name) setUserName(name)

      let company = userDetails.userCompany
      if (company) setCompanyName(company)
    } else {
      console.log("No user details found in localStorage.")
    }
  }, [])

  const applyNow = async job => {
    console.log("Job details" + JSON.stringify(job))
    const newApplication = {
      applicantName: userName,
      applicantEmail: email,
      jobId: job.key,
      appliedDate: new Date().toISOString(),
      breaks: job.breaks,
      careHome: job.careHome,
      category: job.category,
      coordinates: job.coordinates,
      enableRecurrence: job.enableRecurrence,
      endTime: job.endTime,
      workerType: job.workerType,
    }

    try {
      // Add the current user as a suggested worker for this job
      await WorkersAllocationService.addWorkerToCategory(job.key, "suggested", {
        key: job.key, // Assuming the email is unique and can be used as a key
        name: userName,
        email: email,
        workerType: currentJob.workerType,
      })

      // Create a new application
      await ApplicationDataService.createApplication(newApplication)

      console.log("New application inserted with reference: ", job.key)

      
      navigate("/confirm")
    } catch (error) {
      console.error("Error inserting application: ", error)
    }
  }

  const { jobID } = useParams()

  useEffect(() => {
    JobDataService.getAll(data => {
      const filteredJob = data.find(job => job.key === jobID)
      if (filteredJob) {
        setAllJobs([filteredJob])
      } else {
        setAllJobs([])
      }
    })
  }, [jobID])

  if (!allJobs) {
    return <div>Loading...</div>
  }

  return (
    <React.Fragment>
      {allJobs && allJobs[0] && (
        <Col xl={3}>
          <Card>
            <CardBody>
              <h5 className="fw-semibold">Overview</h5>
              <div className="table-responsive">
                <table className="table">
                  <tbody>
                    <tr>
                      <th scope="col">Job ID</th>
                      <td scope="col">{allJobs[0].jobID || allJobs[0].key}</td>
                    </tr>
                    <tr>
                      <th scope="col">Job Title</th>
                      <td scope="col">{allJobs[0].jobTitle}</td>
                    </tr>
                    <tr>
                      <th scope="row">Experience:</th>
                      <td>{allJobs[0].jobExperience}</td>
                    </tr>
                    <tr>
                      <th scope="row">Vacancy</th>
                      <td>{allJobs[0].noofVacancies}</td>
                    </tr>
                    <tr>
                      <th scope="row">Job Type</th>
                      <td>
                        <span className="badge badge-soft-success">
                          {allJobs[0].jobType}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">Status</th>
                      <td>
                        <span className="badge badge-soft-info">
                          {allJobs[0].jobstatus}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">Posted Date</th>
                      <td>{allJobs[0].startDate}</td>
                    </tr>
                    <tr>
                      <th scope="row">Close Date</th>
                      <td>{allJobs[0].endDate}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="hstack gap-2">
                <button
                  onClick={() => applyNow(allJobs[0])}
                  className="btn btn-soft-primary w-100"
                >
                  Apply Now
                </button>
              </div>
            </CardBody>
          </Card>

          <Card>
            <CardBody>
              <div className="text-center">
                <img
                  src={adobephotoshop}
                  alt=""
                  height="50"
                  className="mx-auto d-block"
                />
                <h5 className="mt-3 mb-1">{companyName}</h5>
              </div>
              <ul className="list-unstyled mt-4">
                <li>
                  <div className="d-flex">
                    <i className="bx bx-phone text-primary fs-4"></i>
                    <div className="ms-3">
                      <h6 className="fs-14 mb-2">Phone</h6>
                    </div>
                  </div>
                </li>
                <li className="mt-3">
                  <div className="d-flex">
                    <i className="bx bx-mail-send text-primary fs-4"></i>
                    <div className="ms-3">
                      <h6 className="fs-14 mb-2">Email</h6>
                    </div>
                  </div>
                </li>
                <li className="mt-3">
                  <div className="d-flex">
                    <i className="bx bx-globe text-primary fs-4"></i>
                    <div className="ms-3">
                      <h6 className="fs-14 mb-2">Website</h6>
                    </div>
                  </div>
                </li>
                <li className="mt-3">
                  <div className="d-flex">
                    <i className="bx bx-map text-primary fs-4"></i>
                    <div className="ms-3">
                      <h6 className="fs-14 mb-2">Location</h6>
                    </div>
                  </div>
                </li>
              </ul>
              <div className="mt-4">
                <Link
                  to="#"
                  className="btn btn-soft-primary btn-hover w-100 rounded"
                >
                  <i className="mdi mdi-eye"></i> View Profile
                </Link>
              </div>
            </CardBody>
          </Card>
        </Col>
      )}
    </React.Fragment>
  )
}

export default Overview
