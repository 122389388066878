import { getDatabase, ref, get, set, remove } from "firebase/database"
import { v4 as uuidv4 } from "uuid"

const db = getDatabase()

const TimesheetService = {
  getTimesheetById(timesheetId) {
    const timesheetRef = ref(db, `/Timesheets/${timesheetId}`)
    return get(timesheetRef).then(snapshot => {
      const data = snapshot.val()
      console.log("Fetched data for timesheetId:", timesheetId, data)
      return data ? { key: timesheetId, ...data } : null
    })
  },

  getAllTimesheets() {
    const timesheetsRef = ref(db, `/Timesheets`)
    return get(timesheetsRef).then(snapshot => {
      const data = snapshot.val()
      console.log("Fetched all timesheets:", data)
      return data ? Object.keys(data).map(key => ({ key, ...data[key] })) : []
    })
  },

  addTimesheet(timesheet) {
    const timesheetId = uuidv4()
    const timesheetRef = ref(db, `/Timesheets/${timesheetId}`)
    return set(timesheetRef, timesheet)
      .then(() => {
        console.log(`Timesheet ${timesheetId} added successfully`)
        return { ...timesheet, key: timesheetId }
      })
      .catch(error => {
        console.error(`Error adding timesheet: ${error}`)
        throw error
      })
  },

  updateTimesheet(timesheetId, timesheet) {
    const timesheetRef = ref(db, `/Timesheets/${timesheetId}`)
    return set(timesheetRef, timesheet)
      .then(() => {
        console.log(`Timesheet ${timesheetId} updated successfully`)
      })
      .catch(error => {
        console.error(`Error updating timesheet: ${error}`)
        throw error
      })
  },

  removeTimesheet(timesheetId) {
    const timesheetRef = ref(db, `/Timesheets/${timesheetId}`)
    return remove(timesheetRef)
      .then(() => {
        console.log(`Timesheet ${timesheetId} removed successfully`)
      })
      .catch(error => {
        console.error(`Error removing timesheet: ${error}`)
        throw error
      })
  },
}

export default TimesheetService
