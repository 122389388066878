import React, { useState, useEffect } from "react"
import { Button, Form, FormGroup, Label, Input } from "reactstrap"
import { getAuth, updateProfile } from "firebase/auth"
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage"
import { getFirestore } from "firebase/firestore"
import logUserActivity from "helpers/activityLogger";

const UserUpdate = () => {
  const [userProfile, setUserProfile] = useState({
    displayName: "",
    photoFile: null,
  })
  const [updateMessage, setUpdateMessage] = useState("") // State to hold the update message
  const auth = getAuth()
  const storage = getStorage()
  const firestore = getFirestore()

  useEffect(() => {
    if (auth.currentUser) {
      const { displayName } = auth.currentUser
      setUserProfile(prevState => ({ ...prevState, displayName }))
    }
  }, [auth.currentUser])

  const handleFileChange = e => {
    try {
      setUserProfile(prevState => ({
        ...prevState,
        photoFile: e.target.files[0],
      }))
    } catch (error) {
      console.log(error.message)
    }
  }

  const handleChange = e => {
    const { name, value } = e.target
    setUserProfile(prevState => ({ ...prevState, [name]: value }))
  }

  const handleSubmit = async e => {
    e.preventDefault()
    const { displayName, photoFile } = userProfile

    try {
      if (photoFile) {
        // Upload image to Firebase Storage
        const storageRef = ref(storage, `userphotos/${auth.currentUser.uid}`)
        await uploadBytes(storageRef, photoFile)
        const photoURL = await getDownloadURL(storageRef)

        // Update user profile in Firebase Authentication
        await updateProfile(auth.currentUser, { displayName, photoURL });


        setUpdateMessage("Profile updated successfully")
      }
       else 
      {
        // Update only the display name if no photo is uploaded
        await updateProfile(auth.currentUser, { displayName })
        setUpdateMessage("Display name updated successfully")
      }

              const now = new Date()
              const date = now.toLocaleDateString()
              const time = now.toLocaleTimeString()

              // Log the activity
              logUserActivity({
                date: date,
                time: time,
                activity:
                  "User profile " + displayName + " updated successfully.",
              })
                   
    } catch (error) {
      console.error("Error updating profile: ", error)
      setUpdateMessage("Error updating profile")
    }
  }

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <FormGroup>
          <Label for="displayName">Display Name</Label>
          <Input
            type="text"
            name="displayName"
            id="displayName"
            value={userProfile.displayName || ""}
            onChange={handleChange}
          />
        </FormGroup>
        <FormGroup>
          <Label for="photoFile">Profile Photo</Label>
          <Input
            type="file"
            name="photoFile"
            id="photoFile"
            onChange={handleFileChange}
          />
        </FormGroup>
        <Button type="submit" color="danger">
          Update Profile
        </Button>
      </Form>
      {updateMessage && (
        <div style={{ marginTop: "20px", color: "green" }}>{updateMessage}</div>
      )}
    </>
  )
}

export default UserUpdate
