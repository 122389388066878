import { initializeApp } from "firebase/app"
import {
  getDatabase,
  ref,
  push,
  onValue,
  update,
  remove,
} from "firebase/database"

// Firebase configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_APIKEY,
  authDomain: process.env.REACT_APP_AUTHDOMAIN,
  databaseURL: process.env.REACT_APP_DATABASEURL,
  projectId: process.env.REACT_APP_PROJECTID,
  storageBucket: process.env.REACT_APP_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_APPID,
  measurementId: process.env.REACT_APP_MEASUREMENTID,
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
const db = getDatabase(app)

const defaultJobTypes = [
  "Support worker",
  "Sleep in",
  "Health Care Assistant",
  "Registered Mental Health Nurse",
  "Registered General Nurse",
  "Doctor",
]

class JobTypeDataService {
  constructor() {
    this.dbRef = ref(getDatabase(), "/jobTypes")
    this.initializeDefaultJobTypes() // Initialize default job types when service is used
  }

  initializeDefaultJobTypes() {
    onValue(this.dbRef, snapshot => {
      const data = snapshot.val()
      if (!data) {
        // If no job types exist, add the default ones
        defaultJobTypes.forEach(jobType => {
          this.createJobType({ name: jobType })
        })
      }
    })
  }

  getJobTypeCount(callback) {
    return onValue(this.dbRef, snapshot => {
      const data = snapshot.val()
      const count = data ? Object.keys(data).length : 0
      callback(count)
    })
  }

  getAll() {
    return new Promise((resolve, reject) => {
      onValue(
        this.dbRef,
        snapshot => {
          const data = snapshot.val()
          let jobTypes = []

          if (data) {
            jobTypes = Object.keys(data).map(key => ({
              key,
              ...data[key],
            }))
          }

          resolve(jobTypes)
        },
        error => {
          reject(error)
        }
      )
    })
  }
  
  createJobType(jobType) {
    return push(this.dbRef, jobType)
  }

  updateJobType(key, value) {
    const specificJobTypeRef = ref(getDatabase(), `/jobTypes/${key}`)
    return update(specificJobTypeRef, value)
  }

  deleteJobType(key) {
    const specificJobTypeRef = ref(getDatabase(), `/jobTypes/${key}`)
    return remove(specificJobTypeRef)
  }
}

export default new JobTypeDataService()
