import React, { useState, useEffect } from "react"
import { Button } from "devextreme-react/button"
import {
  Form,
  SimpleItem,
  RequiredRule,
  ButtonItem,
  GroupItem,
} from "devextreme-react/form"
import { Popup } from "devextreme-react/popup"
import TimesheetDataService from "services/TimesheetDataService"
import WorkersAllocationService from "services/WorkersAllocationService"
import DataGrid, { Column, Editing } from "devextreme-react/data-grid"
import "devextreme/dist/css/dx.light.css"

const TimeModal = ({ visible, onHiding, jobData }) => {
  const [timesheets, setTimesheets] = useState([])
  const [workers, setWorkers] = useState([])
  const [attachments, setAttachments] = useState([])
  const [proofs, setProofs] = useState([])
   const [selectedDate, setSelectedDate] = useState(new Date()) 
  const [currentTimesheet, setCurrentTimesheet] = useState({
    timesheetID: "",
    date: new Date(), // Default to today's date
    fromDuration: "",
    toDuration: "",
    breakTime: "",
    status: "",
    workerID: jobData?.workerID || "",
    workerEmail: "",
    jobID: jobData?.jobID || "", // Ensure jobID is initialized
    attachments: [],
    proofs: [],
    DateSubmitted: "",
  })

  const [email, setEmail] = useState("")
  const [companyName, setCompanyName] = useState("")

  // Fetch workers related to the job
  useEffect(() => {
    if (jobData?.jobID) {
      WorkersAllocationService.getAllocationsByCategory(
        jobData.jobID,
        "selected"
      )
        .then(workers => setWorkers(workers))
        .catch(error =>
          console.error("Error fetching selected workers:", error)
        )
    }
  }, [jobData?.jobID])

  // Fetch existing timesheet data for the job and prefill the form
  useEffect(() => {
    if (jobData?.jobID) {
      TimesheetDataService.getTimesheetById(jobData.jobID).then(timesheet => {
        if (timesheet) {
          setCurrentTimesheet({
            ...timesheet,
            jobID: jobData.jobID, // Ensure jobID is part of the fetched data
            attachments: timesheet.attachments || [],
            proofs: timesheet.proofs || [],
          })
          setAttachments(timesheet.attachments || [])
          setProofs(timesheet.proofs || [])
        }
      })
    }
  }, [jobData?.jobID])

  useEffect(() => {
    const userDetails = localStorage.getItem("authUserDetails")
    if (userDetails) {
      const { email: userEmail, userCompany } = JSON.parse(userDetails)
      if (userEmail) setEmail(userEmail)
      if (userCompany) setCompanyName(userCompany)
    }
  }, [])

  // const handleInputChange = e => {
  //   const { dataField, value } = e
  //   setCurrentTimesheet(prevTimesheet => ({
  //     ...prevTimesheet,
  //     [dataField]: value,
  //   }))
  // }

  const handleInputChange = e => {
    const { dataField, value } = e

    // Handle date formatting if needed
    const formattedValue = dataField === "date" ? new Date(value) : value

    setCurrentTimesheet(prevTimesheet => ({
      ...prevTimesheet,
      [dataField]: formattedValue, // Make sure to update the field with the correct value
    }))
  }

  // Handle date selection and store it in selectedDate state
  const handleDateChange = e => {
    const { value } = e
    setSelectedDate(value) // Store the selected date
    handleInputChange({ dataField: "date", value }) // Update currentTimesheet with the selected date
  }

  const handleFileUpload = (e, type) => {
    const files = Array.from(e.target.files)
    if (type === "attachments") {
      setAttachments(prev => [...prev, ...files])
    } else if (type === "proofs") {
      setProofs(prev => [...prev, ...files])
    }
  }

  const handleFileDelete = (file, type) => {
    if (type === "attachments") {
      setAttachments(prev => prev.filter(f => f !== file))
    } else if (type === "proofs") {
      setProofs(prev => prev.filter(f => f !== file))
    }
  }

  const uploadFilesToStorage = async (files, path) => {
    const uploadedFiles = []
    for (const file of files) {
      const fileUrl = await TimesheetDataService.uploadFile(file, path)
      uploadedFiles.push({ name: file.name, url: fileUrl })
    }
    return uploadedFiles
  }

  const submitHandler = async e => {
    if (e && typeof e.preventDefault === "function") {
      e.preventDefault()
    }

    const { timesheetID, ...timesheetData } = currentTimesheet

    try {
      // Upload attachments and proofs to Firebase Storage
      const uploadedAttachments = await uploadFilesToStorage(
        attachments,
        `timesheets/${jobData?.jobID}/attachments`
      )
      const uploadedProofs = await uploadFilesToStorage(
        proofs,
        `timesheets/${jobData?.jobID}/proofs`
      )

      timesheetData.attachments = uploadedAttachments
      timesheetData.proofs = uploadedProofs
      timesheetData.jobID = jobData?.jobID
      timesheetData.DateSubmitted = selectedDate
      timesheetData.date = selectedDate

      if (timesheetID) {
        await TimesheetDataService.updateTimesheet(timesheetID, timesheetData)
      } else {
        await TimesheetDataService.createTimesheet(timesheetData)
      }

      resetForm()
      onHiding()
    } catch (error) {
      console.error("Error in form submission:", error)
    }
  }

  const resetForm = () => {
    setCurrentTimesheet({
      timesheetID: "",
      date: new Date(), // Reset to today's date
      fromDuration: "",
      toDuration: "",
      breakTime: "",
      status: "",
      workerID: jobData?.workerID || "",
      workerEmail: email,
      jobID: jobData?.jobID || "",
      attachments: [],
      proofs: [],
      DateSubmitted: new Date(),

    })
    setAttachments([])
    setProofs([])
  }

  return (
    <Popup
      visible={visible}
      onHiding={onHiding}
      dragEnabled={true}
      closeOnOutsideClick={true}
      showTitle={true}
      title={`Timesheet Details - Job ID: ${jobData?.jobID || "N/A"}`}
      width={700}
      height={700}
    >
      <div>
        <Form
          formData={currentTimesheet}
          onFieldDataChanged={handleInputChange}
        >
          <SimpleItem
            dataField="date"
            editorType="dxDateBox"
            editorOptions={{
              type: "date",
              value: selectedDate, // Set default date to selectedDate (which defaults to today)
              displayFormat: "yyyy-MM-dd",
              onValueChanged: handleDateChange, // Handle date changes
            }}
          >
            <RequiredRule message="Date is required" />
          </SimpleItem>

          <SimpleItem
            dataField="workerID"
            label={{ text: "Worker" }}
            editorType="dxSelectBox"
            editorOptions={{
              items: workers.map(worker => ({
                id: worker.key,
                text: `${worker.firstName} ${worker.lastName}`,
              })),
              displayExpr: "text",
              valueExpr: "id",
              value: currentTimesheet.workerID,
              onValueChanged: e =>
                handleInputChange({ dataField: "workerID", value: e.value }),
            }}
          >
            <RequiredRule message="Worker is required" />
          </SimpleItem>
          <SimpleItem
            dataField="fromDuration"
            editorType="dxTextBox"
            editorOptions={{ placeholder: "Start time" }}
          >
            <RequiredRule message="Start time is required" />
          </SimpleItem>
          <SimpleItem
            dataField="toDuration"
            editorType="dxTextBox"
            editorOptions={{ placeholder: "End time" }}
          >
            <RequiredRule message="End time is required" />
          </SimpleItem>
          <SimpleItem
            dataField="breakTime"
            editorType="dxNumberBox"
            editorOptions={{ min: 0, placeholder: "minutes" }}
          >
            <RequiredRule message="Break time is required" />
          </SimpleItem>
          {/* <SimpleItem
            dataField="status"
            editorType="dxSelectBox"
            editorOptions={{ items: ["New", "In Progress", "Approved"] }}
          >
            <RequiredRule message="Status is required" />
          </SimpleItem> */}

          <GroupItem caption="Attachments">
            <input
              type="file"
              multiple
              onChange={e => handleFileUpload(e, "attachments")}
            />
            <ul>
              {attachments.map((file, index) => (
                <li key={index}>
                  {file.name}
                  <Button
                    text="Delete"
                    onClick={() => handleFileDelete(file, "attachments")}
                  />
                </li>
              ))}
            </ul>
          </GroupItem>

          <GroupItem caption="Proofs">
            <input
              type="file"
              multiple
              onChange={e => handleFileUpload(e, "proofs")}
            />
            <ul>
              {proofs.map((file, index) => (
                <li key={index}>
                  {file.name}
                  <Button
                    text="Delete"
                    onClick={() => handleFileDelete(file, "proofs")}
                  />
                </li>
              ))}
            </ul>
          </GroupItem>

          <ButtonItem
            horizontalAlignment="right"
            buttonOptions={{
              text: "Submit",
              onClick: submitHandler,
              type: "success",
            }}
          />
          <ButtonItem
            horizontalAlignment="center"
            buttonOptions={{
              text: "Cancel",
              onClick: onHiding,
              type: "danger",
            }}
            style={{
              width: "100%", // Make the button fill the entire width
              marginLeft: "10px", // Add a small margin on the left
              marginRight: "10px", // Add a small margin on the right
              height: "40px", // Adjust height if needed
            }}
          />
        </Form>
      </div>
    </Popup>
  )
}

export default TimeModal
