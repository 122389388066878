import React, { useState, useEffect } from "react"
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
} from "reactstrap"
import { v4 as uuidv4 } from "uuid"
import TimesheetDataService from "services/TimesheetDataService"

const TimeModal = ({ visible, onHiding, jobData }) => {
  const [timesheet, setTimesheet] = useState([])
  const [currentTimesheet, setCurrentTimesheet] = useState({
    timesheetID: "",
    fromDuration: "",
    toDuration: "",
    status: "",
    workerID: jobData?.workerID || "",
  })

  useEffect(() => {
    const unsubscribe = TimesheetDataService.getAll(data => {
      setTimesheet(data)
    })
    return () => unsubscribe && unsubscribe()
  }, [currentTimesheet])

  useEffect(() => {
    if (!currentTimesheet.timesheetID) {
      setCurrentTimesheet(prevTS => ({
        ...prevTS,
        timesheetID: generateTimesheetID(),
      }))
    }
  }, [currentTimesheet])

  const generateTimesheetID = () => {
    const year = new Date().getFullYear()
    const uuid = uuidv4()
    const shortUuid = `${uuid.slice(0, 2)}-${uuid.slice(-2)}`
    return `TST-${year}-${shortUuid}`.toUpperCase()
  }

  const handleInputChange = event => {
    const { name, value } = event.target
    setCurrentTimesheet({ ...currentTimesheet, [name]: value })
  }

  const resetForm = () => {
    setCurrentTimesheet({
      timesheetID: "",
      fromDuration: "",
      toDuration: "",
      status: "",
      workerID: jobData?.workerID || "",
    })
  }

  const submitHandler = event => {
    event.preventDefault()
    const { timesheetID, ...timesheetData } = currentTimesheet
    if (timesheetID) {
      TimesheetDataService.updateTimesheet(timesheetID, timesheetData).then(
        () => {
          resetForm()
          onHiding()
        }
      )
    } else {
      TimesheetDataService.createTimesheet(timesheetData).then(() => {
        resetForm()
        onHiding()
      })
    }
  }

  const editTimesheet = timesheet => {
    setCurrentTimesheet({ ...timesheet, timesheetID: timesheet.key || "" })
  }

  return (
    <Modal isOpen={visible} toggle={onHiding}>
      <ModalHeader toggle={onHiding}>
        {currentTimesheet.timesheetID ? "Update Timesheet" : "Create Timesheet"}
      </ModalHeader>
      <Form onSubmit={submitHandler}>
        <ModalBody>
          <FormGroup>
            <Label for="timesheetID">Timesheet ID</Label>
            <Input
              type="text"
              name="timesheetID"
              id="timesheetID"
              value={currentTimesheet.timesheetID || ""}
              onChange={handleInputChange}
              readOnly
            />
          </FormGroup>
          <FormGroup>
            <Label for="fromDuration">Start time</Label>
            <Input
              type="time"
              name="fromDuration"
              id="fromDuration"
              value={currentTimesheet.fromDuration}
              onChange={handleInputChange}
            />
          </FormGroup>
          <FormGroup>
            <Label for="toDuration">End time</Label>
            <Input
              type="time"
              name="toDuration"
              id="toDuration"
              value={currentTimesheet.toDuration}
              onChange={handleInputChange}
            />
          </FormGroup>
          <FormGroup>
            <Label for="workerID">Worker ID</Label>
            <Input
              type="text"
              name="workerID"
              id="workerID"
              value={currentTimesheet.workerID}
              onChange={handleInputChange}
            />
          </FormGroup>
          <FormGroup>
            <Label for="status">Status</Label>
            <select
              name="status"
              value={currentTimesheet.status}
              onChange={handleInputChange}
              className="form-control"
            >
              <option value="">Select status</option>
              <option value="New">New</option>
              <option value="Progress">In Progress</option>
              <option value="Approved">Approved</option>
            </select>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" type="submit">
            {currentTimesheet.timesheetID ? "Update" : "Create"}
          </Button>
          <Button color="secondary" onClick={onHiding}>
            Cancel
          </Button>
        </ModalFooter>
      </Form>

      <Table>
        <thead>
          <tr>
            <th>Timesheet ID</th>
            <th>From</th>
            <th>To</th>
            <th>Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {timesheet.map(work => (
            <tr key={work.key}>
              <td>{work.key}</td>
              <td>{work.fromDuration}</td>
              <td>{work.toDuration}</td>
              <td>
                <span
                  className={`badge badge-${
                    (work.status && work.status.toLowerCase()) || "new"
                  }`}
                >
                  {work.status || "New"}
                </span>
              </td>
              <td>
                <Button onClick={() => editTimesheet(work)}>Edit</Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Modal>
  )
}

export default TimeModal
