import React, { useState, useEffect } from "react";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Col,
  Row,
  FormText,
} from "reactstrap"
import { v4 as uuidv4 } from "uuid"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"

const TimesheetForm = () => {
  const [company, setCompany] = useState("")

  const [timesheet, setTimesheet] = useState({
    createdBy: "Current User", // Replace with actual user data
    createdDate: new Date(),
    lastModifiedBy: "Current User", // Replace with actual user data
    lastModifiedDate: new Date(),
    name: "",
    owner: "",
    serviceResource: "",
    status: "None",
    endDate: new Date(),
    startDate: new Date(),
    description: "",
    duration: "",
    endTime: "",
    startTime: "",
    locationTimeZone: "",
    subject: "",
    type: "None",
    workOrder: "",
    workOrderLineItem: "",
    signedFile: null,
  })

  useEffect(() => {
    if (localStorage.getItem("authUserDetails")) {
      let userDetails = JSON.parse(localStorage.getItem("authUserDetails"))

      let userCompany = userDetails.userCompany

      if (userCompany) setCompany(userCompany)
    } else {
      console.log("No user details found in localStorage.")
    }
  }, [])

  const handleInputChange = e => {
    const { name, value } = e.target
    setTimesheet({ ...timesheet, [name]: value })
  }

  const handleFileChange = e => {
    setTimesheet({ ...timesheet, signedFile: e.target.files[0] })
  }

  const handleSubmit = e => {
    e.preventDefault()
    // Submit logic here
    console.log(timesheet)
  }

  return (
    <div className="page-content" style={{ display: "block" }}>
      <Form onSubmit={handleSubmit}>
        <Row form>
          <Col md={6}>
            <FormGroup>
              <Label for="createdBy">Created By</Label>
              <Input
                type="text"
                name="createdBy"
                id="createdBy"
                value={timesheet.createdBy}
                readOnly
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="createdDate">Created Date</Label>
              <DatePicker
                selected={timesheet.createdDate}
                onChange={date =>
                  setTimesheet({ ...timesheet, createdDate: date })
                }
                dateFormat="yyyy-MM-dd"
                className="form-control"
                readOnly
              />
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col md={6}>
            <FormGroup>
              <Label for="lastModifiedBy">Last Modified By</Label>
              <Input
                type="text"
                name="lastModifiedBy"
                id="lastModifiedBy"
                value={timesheet.lastModifiedBy}
                readOnly
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="lastModifiedDate">Last Modified Date</Label>
              <DatePicker
                selected={timesheet.lastModifiedDate}
                onChange={date =>
                  setTimesheet({ ...timesheet, lastModifiedDate: date })
                }
                dateFormat="yyyy-MM-dd"
                className="form-control"
                readOnly
              />
            </FormGroup>
          </Col>
        </Row>
        <FormGroup>
          <Label for="name">Name</Label>
          <Input
            type="text"
            name="name"
            id="name"
            value={timesheet.name}
            onChange={handleInputChange}
          />
        </FormGroup>
        <FormGroup>
          <Label for="owner">Owner</Label>
          <Input
            type="text"
            name="owner"
            id="owner"
            value={timesheet.owner}
            onChange={handleInputChange}
          />
        </FormGroup>
        <FormGroup>
          <Label for="serviceResource">Service Resource</Label>
          <Input
            type="text"
            name="serviceResource"
            id="serviceResource"
            value={timesheet.serviceResource}
            onChange={handleInputChange}
          />
        </FormGroup>
        {/* <FormGroup>
        <Label for="status">Status</Label>
        <Input
          type="select"
          name="status"
          id="status"
          value={timesheet.status}
          onChange={handleInputChange}
        >
          <option>None</option>
          <option>New</option>
          <option>Submitted</option>
          <option>Approved</option>
        </Input>
      </FormGroup> */}
        <Row form>
          <Col md={6}>
            <FormGroup>
              <Label for="startDate">Start Date</Label>
              <DatePicker
                selected={timesheet.startDate}
                onChange={date =>
                  setTimesheet({ ...timesheet, startDate: date })
                }
                dateFormat="yyyy-MM-dd"
                className="form-control"
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="endDate">End Date</Label>
              <DatePicker
                selected={timesheet.endDate}
                onChange={date => setTimesheet({ ...timesheet, endDate: date })}
                dateFormat="yyyy-MM-dd"
                className="form-control"
              />
            </FormGroup>
          </Col>
        </Row>
        <FormGroup>
          <Label for="description">Description</Label>
          <Input
            type="textarea"
            name="description"
            id="description"
            value={timesheet.description}
            onChange={handleInputChange}
          />
        </FormGroup>
        <FormGroup>
          <Label for="duration">Duration (in Minutes)</Label>
          <Input
            type="number"
            name="duration"
            id="duration"
            value={timesheet.duration}
            onChange={handleInputChange}
            readOnly
          />
        </FormGroup>
        <Row form>
          <Col md={6}>
            <FormGroup>
              <Label for="startTime">Start Time</Label>
              <Input
                type="datetime-local"
                name="startTime"
                id="startTime"
                value={timesheet.startTime}
                onChange={handleInputChange}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="endTime">End Time</Label>
              <Input
                type="datetime-local"
                name="endTime"
                id="endTime"
                value={timesheet.endTime}
                onChange={handleInputChange}
              />
            </FormGroup>
          </Col>
        </Row>
        <FormGroup>
          <Label for="locationTimeZone">Location Time Zone</Label>
          <Input
            type="text"
            name="locationTimeZone"
            id="locationTimeZone"
            value={timesheet.locationTimeZone}
            onChange={handleInputChange}
          />
        </FormGroup>
        <FormGroup>
          <Label for="subject">Subject</Label>
          <Input
            type="text"
            name="subject"
            id="subject"
            value={timesheet.subject}
            onChange={handleInputChange}
          />
        </FormGroup>
        <FormGroup>
          <Label for="type">Type</Label>
          <Input
            type="select"
            name="type"
            id="type"
            value={timesheet.type}
            onChange={handleInputChange}
          >
            <option>None</option>
            <option>Direct</option>
            <option>Indirect</option>
          </Input>
        </FormGroup>
        <FormGroup>
          <Label for="workOrder">Work Order</Label>
          <Input
            type="text"
            name="workOrder"
            id="workOrder"
            value={timesheet.workOrder}
            onChange={handleInputChange}
          />
        </FormGroup>
        <FormGroup>
          <Label for="workOrderLineItem">Work Order Line Item</Label>
          <Input
            type="text"
            name="workOrderLineItem"
            id="workOrderLineItem"
            value={timesheet.workOrderLineItem}
            onChange={handleInputChange}
          />
        </FormGroup>
        <FormGroup>
          <Label for="signedFile">Signed Timesheet</Label>
          <Input
            type="file"
            name="signedFile"
            id="signedFile"
            onChange={handleFileChange}
          />
          <FormText color="muted">
            Upload a copy of the signed timesheet.
          </FormText>
        </FormGroup>
        <Button color="primary" type="submit">
          Submit Timesheet
        </Button>
      </Form>
    </div>
  )
}

export default TimesheetForm
