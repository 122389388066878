import React from "react"
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap"
import "./activitydetail.css" // Adjust the path as necessary

const ActivityDetailModal = ({ activity, isOpen, toggle }) => {
  const handlePrint = () => {
    window.print()
  }

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Activity Details</ModalHeader>
      <ModalBody>
        <p>
          <strong>Date:</strong> {activity?.date}
        </p>
        <p>
          <strong>Time:</strong> {activity?.time}
        </p>
        <p>
          <strong>User:</strong> {activity?.user}
        </p>
        <p>
          <strong>User Type:</strong> {activity?.userType}
        </p>
        <p>
          <strong>Activity:</strong>{" "}
          <span className="activity-detail-text">{activity?.activity}</span>
        </p>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>
          Close
        </Button>
        <Button color="primary" onClick={handlePrint}>
          Print
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default ActivityDetailModal
