import React from "react"
import { Popup } from "devextreme-react/popup"
import { Form, SimpleItem, RequiredRule, TextArea } from "devextreme-react/form"

const CancelJobModal = ({ visible, onHiding, jobData, onSubmit }) => {
  const handleSubmit = e => {
    e.preventDefault()
    onSubmit(jobData)
    onHiding()
  }

  return (
    <Popup
      visible={visible}
      onHiding={onHiding}
      dragEnabled={true}
      closeOnOutsideClick={true}
      showTitle={true}
      title="Cancel Job"
      width={400}
      height={300}
      position={{ my: "center", at: "center", of: window }}
    >
      <form onSubmit={handleSubmit}>
        <Form formData={jobData}>
          <SimpleItem dataField="jobTitle" isRequired={true}>
            <RequiredRule />
          </SimpleItem>
          <SimpleItem
            dataField="startDate"
            editorType="dxDateBox"
            isRequired={true}
          >
            <RequiredRule />
          </SimpleItem>
          <SimpleItem
            dataField="endDate"
            editorType="dxDateBox"
            isRequired={true}
          >
            <RequiredRule />
          </SimpleItem>
          <SimpleItem
            dataField="cancelReason"
            editorType="dxTextArea"
            isRequired={true}
            editorOptions={{
              placeholder: "Enter reason for cancellation",
            }}
          >
            <RequiredRule />
          </SimpleItem>
        </Form>
        <div style={{ textAlign: "right", marginTop: "20px" }}>
          <button type="submit" className="dx-button dx-button-success">
            Submit
          </button>
        </div>
      </form>
    </Popup>
  )
}

export default CancelJobModal
