import React, { useState, useEffect } from "react"
import TimesheetService from "services/TimesheetFormService" // Adjust the path as needed
import { Table } from "reactstrap"
import "react-datepicker/dist/react-datepicker.css"

const TimesheetList = () => {
  const [timesheets, setTimesheets] = useState([])
  const [categorizedTimesheets, setCategorizedTimesheets] = useState({})

  useEffect(() => {
    const fetchTimesheets = async () => {
      try {
        const data = await TimesheetService.getAllTimesheets()
        setTimesheets(data)
        categorizeTimesheets(data)
      } catch (error) {
        console.error("Error fetching timesheets:", error)
      }
    }

    fetchTimesheets()
  }, [])

  const categorizeTimesheets = data => {
    const categorized = data.reduce((acc, timesheet) => {
      const { startDate, type } = timesheet
      const period = new Date(startDate).getFullYear() // Categorize by year

      if (!acc[period]) {
        acc[period] = {}
      }

      if (!acc[period][type]) {
        acc[period][type] = []
      }

      acc[period][type].push(timesheet)
      return acc
    }, {})

    setCategorizedTimesheets(categorized)
  }

  return (
    <div className="page-content">
  
      <h1>Timesheet List</h1>
      {Object.keys(categorizedTimesheets).map(period => (
        <div key={period}>
          <h2>Year: {period}</h2>
          {Object.keys(categorizedTimesheets[period]).map(type => (
            <div key={type}>
              <h3>Type: {type}</h3>
              <Table striped>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Owner</th>
                    <th>Start Date</th>
                    <th>End Date</th>
                    <th>Description</th>
                  </tr>
                </thead>
                <tbody>
                  {categorizedTimesheets[period][type].map(timesheet => (
                    <tr key={timesheet.key}>
                      <td>{timesheet.name}</td>
                      <td>{timesheet.owner}</td>
                      <td>{timesheet.startDate}</td>
                      <td>{timesheet.endDate}</td>
                      <td>{timesheet.description}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          ))}
        </div>
      ))}
    </div>
  )
}

export default TimesheetList
