import React, { useState, useEffect } from "react"
import TimesheetDataService from "services/TimesheetDataService"
import { Popup } from "devextreme-react/popup"
import { Button } from "devextreme-react/button"
import { Form, SimpleItem, GroupItem } from "devextreme-react/form"
import DataGrid, { Column } from "devextreme-react/data-grid"
import "devextreme/dist/css/dx.light.css"

// Helper function to convert Firestore timestamp to a JavaScript Date object
const convertTimestampToDate = timestamp => {
  return new Date(timestamp.seconds * 1000)
}

const ApproveTimesheets = () => {
  const [timesheets, setTimesheets] = useState([])
  const [selectedTimesheet, setSelectedTimesheet] = useState(null)
  const [showPopup, setShowPopup] = useState(false)
  const [disputeReason, setDisputeReason] = useState("")

  // Fetch timesheets on component mount
  useEffect(() => {
    const fetchTimesheets = async () => {
      try {
        const allTimesheets = await TimesheetDataService.getAllTimesheets()

        // Format timesheets, if needed, based on provided structure
        // const formattedTimesheets = allTimesheets.map(ts => ({
        //   ...ts,
        //   date: ts.date ? convertTimestampToDate(ts.date) : null,
        //   fromDuration: ts.fromDuration
        //     ? convertTimestampToDate(ts.fromDuration)
        //     : null,
        //   toDuration: ts.toDuration
        //     ? convertTimestampToDate(ts.toDuration)
        //     : null,
        // }))

        const isValidDate = date => !isNaN(new Date(date).getTime())

        const formattedTimesheets = allTimesheets.map(ts => ({
          ...ts,
          fromDuration: isValidDate(ts.fromDuration)
            ? new Date(ts.fromDuration)
            : null,
          toDuration: isValidDate(ts.toDuration)
            ? new Date(ts.toDuration)
            : null,
        }))


        setTimesheets(formattedTimesheets)
      } catch (error) {
        console.error("Error fetching timesheets:", error)
      }
    }

    fetchTimesheets()
  }, [])

  const handleShowPopup = timesheet => {
    setSelectedTimesheet(timesheet)
    setShowPopup(true)
  }

  const handleClosePopup = () => {
    setShowPopup(false)
    setSelectedTimesheet(null)
    setDisputeReason("") // Reset dispute reason
  }

  const handleStatusChange = async status => {
    if (!selectedTimesheet) return

    try {
      const updatedTimesheet = { ...selectedTimesheet, status }
      await TimesheetDataService.updateTimesheet(
        selectedTimesheet.id,
        updatedTimesheet
      )

      setTimesheets(prevTimesheets =>
        prevTimesheets.map(ts =>
          ts.id === selectedTimesheet.id ? updatedTimesheet : ts
        )
      )

      handleClosePopup()
    } catch (error) {
      console.error("Error updating status:", error)
    }
  }

  const handleRaiseDispute = async () => {
    if (!selectedTimesheet) return

    try {
      const updatedTimesheet = {
        ...selectedTimesheet,
        status: "Dispute",
        disputeReason,
      }
      await TimesheetDataService.updateTimesheet(
        selectedTimesheet.id,
        updatedTimesheet
      )

      setTimesheets(prevTimesheets =>
        prevTimesheets.map(ts =>
          ts.id === selectedTimesheet.id ? updatedTimesheet : ts
        )
      )

      handleClosePopup()
    } catch (error) {
      console.error("Error raising dispute:", error)
    }
  }

  const handleDeleteTimesheet = async timesheetID => {
    try {
      await TimesheetDataService.deleteTimesheet(timesheetID)

      // Remove deleted timesheet from state
      setTimesheets(prevTimesheets =>
        prevTimesheets.filter(ts => ts.id !== timesheetID)
      )
    } catch (error) {
      console.error("Error deleting timesheet:", error)
    }
  }

  return (
    <div className="page-content">
      <h1>Approve Timesheets</h1>
      <span style={{ float: "right" }}>
        {`Displaying ${timesheets.length} timesheet(s)`}
      </span>

      <DataGrid dataSource={timesheets} keyExpr="id" showBorders={true}>
        {/* Removed Timesheet ID and Worker Email Columns */}
        <Column dataField="jobID" caption="Job ID" />
        <Column dataField="workerID" caption="Worker ID" />
        <Column dataField="status" caption="Status" />
        {/* <Column dataField="date" caption="Date" dataType="date" /> */}
        {/* <Column
          dataField="fromDuration"
          caption="Duration From"
          // dataType="datetime"
        />{" "}
        <Column
          dataField="toDuration"
          caption="Duration To"
          dataType="datetime"
        />{" "} */}
        <Column
          dataField="breakTime"
          caption="Break Time (minutes)"
          dataType="number"
        />
        <Column
          type="buttons"
          buttons={[
            {
              hint: "View Details",
              icon: "info",
              onClick: e => handleShowPopup(e.row.data),
            },
            {
              hint: "Delete",
              icon: "trash",
              onClick: e => handleDeleteTimesheet(e.row.data.id),
            },
          ]}
        />
      </DataGrid>

      {selectedTimesheet && (
        <Popup
          visible={showPopup}
          onHiding={handleClosePopup}
          dragEnabled={true}
          closeOnOutsideClick={true}
          showTitle={true}
          title="Timesheet Details"
          width={600}
          height={600}
        >
          <div>
            <Form formData={selectedTimesheet}>
              <SimpleItem dataField="jobID" label={{ text: "Job ID" }} />{" "}
              {/* Display Job ID */}
              <SimpleItem dataField="workerID" label={{ text: "Worker ID" }} />
              <SimpleItem
                dataField="status"
                editorType="dxSelectBox"
                editorOptions={{ items: ["Approved", "Dispute", "Rejected"] }}
                label={{ text: "Status" }}
              />
              <SimpleItem
                dataField="fromDuration"
                label={{ text: "Duration From" }}
              />{" "}
              {/* Updated label */}
              <SimpleItem
                dataField="toDuration"
                label={{ text: "Duration To" }}
              />{" "}
              {/* Updated label */}
              <SimpleItem
                dataField="breakTime"
                label={{ text: "Break Time" }}
              />
              {/* Display Attachments and Proofs */}
              <GroupItem caption="Attachments">
                <ul>
                  {selectedTimesheet.attachments?.map((file, index) => (
                    <li key={index}>
                      <a
                        href={file.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {file.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </GroupItem>
              <GroupItem caption="Proofs">
                <ul>
                  {selectedTimesheet.proofs?.map((file, index) => (
                    <li key={index}>
                      <a
                        href={file.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {file.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </GroupItem>
              <SimpleItem
                dataField="disputeReason"
                editorType="dxTextArea"
                editorOptions={{
                  value: disputeReason,
                  onValueChanged: e => setDisputeReason(e.value),
                }}
                label={{ text: "Dispute Reason" }}
              />
            </Form>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "20px",
              }}
            >
              <Button
                text="Approve"
                type="success"
                onClick={() => handleStatusChange("Approved")}
              />
              <Button
                text="Reject"
                type="danger"
                onClick={() => handleStatusChange("Rejected")}
              />
              <Button text="Raise Dispute" onClick={handleRaiseDispute} />
              <Button text="Close" onClick={handleClosePopup} />
            </div>
          </div>
        </Popup>
      )}
    </div>
  )
}

export default ApproveTimesheets
