import React, { useState, useRef, useEffect } from "react"
import "./invoices.css"
import { Label, FormGroup, Input } from "reactstrap"
import FinanceDataService from "./../../services/FinanceDataService" // Update with the correct path
import { jsPDF } from "jspdf"
import html2canvas from "html2canvas"
import { v4 as uuidv4 } from "uuid"
import { Link, useNavigate } from "react-router-dom"


const InvoiceManager = () => {
  const [invoices, setInvoices] = useState([])
  const [selectedInvoice, setSelectedInvoice] = useState(null)
  const [showModal, setShowModal] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    FinanceDataService.getAll(fetchedInvoices => {
      setInvoices(fetchedInvoices)
    })
  }, [])

 const handleInvoiceClick = invoice => {
   setSelectedInvoice(invoice)
   setShowModal(true);
 }
  const createInvoice = () => {
    navigate("/invoicepage")
  }
  return (
    <>
      <div className="page-content">
        <span style={{ float: "right" }}>
          <button
            className="btn btn-primary btn-block "
            type="submit"
            onClick={createInvoice}
          >
            Create a new invoice
          </button>
          <br/>
          <div
            style={{ float: "right" }}
          >{`Displaying ${invoices.length} invoice(s)`}</div>
        </span>
        <h2>Invoices</h2>

        <div className="invoice-grid">
          {invoices.map(invoice => (
            <div
              key={invoice.key}
              className="invoice-card"
              onClick={() => handleInvoiceClick(invoice)}
            >
              <h3>
                {invoice.invoiceHeader?.invoiceNumber || invoice.invoiceNo}
              </h3>
              <p>
                Due Date: {invoice.invoiceHeader?.dueDate || invoice.dueDate}
              </p>
              <p>
                Grand Total: £
                {invoice.grandTotal.toFixed(2) || invoice.amount.toFixed(2)}
              </p>
              {/* Check if the file URL exists and render a link */}
              {invoice.fileURL && (
                <a
                  href={invoice.fileURL}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  View Attachment
                </a>
              )}
            </div>
          ))}
        </div>

        {showModal && (
          <InvoiceModal
            show={showModal}
            onClose={() => {
              setShowModal(false)
              setSelectedInvoice(null)
            }}
            invoiceData={selectedInvoice}
          />
        )}
      </div>
    </>
  )
}

  const InvoiceModal = ({ show, onClose, invoiceData }) => {
    const printRef = useRef()

    const handlePrint = () => {
      const content = printRef.current
      const pri = document.getElementById("ifmcontentstoprint").contentWindow
      pri.document.open()
      pri.document.write(content.innerHTML)
      pri.document.close()
      pri.focus()
      pri.print()
    }

    if (!show) {
      return null
    }

    return (
      <>
        <div className="invoice-modal-overlay">
          <div className="invoice-modal-container">
            <iframe
              id="ifmcontentstoprint"
              style={{ height: 0, width: 0, position: "absolute" }}
            >
              {" "}
            </iframe>

            <div
              style={{
                position: "fixed",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                background: "rgba(0, 0, 0, 0.6)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                zIndex: "9999",
              }}
            >
              <div
                style={{
                  background: "white",
                  padding: 20,
                  borderRadius: 5,
                  width: "70%",
                  maxHeight: "90%",
                  overflowY: "auto",
                }}
              >
                <div style={{ marginTop: 20 }}>
                  <button onClick={handlePrint}>Print Invoice</button>
                  <button onClick={onClose} style={{ marginLeft: 10 }}>
                    Close
                  </button>
                </div>
                <div
                  ref={printRef}
                  style={
                    {
                      /* ...content styles... */
                    }
                  }
                >
                  <h2>
                    Invoice Number: {invoiceData.invoiceHeader.invoiceNumber}
                  </h2>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginBottom: 20,
                    }}
                  >
                    <div>
                      <h3>From:</h3>
                      <div>{invoiceData.invoiceHeader.fromOrg}</div>
                      <div>{invoiceData.invoiceHeader.fromOrgAddress}</div>
                      <div>Email: {invoiceData.invoiceHeader.fromOrgEmail}</div>
                    </div>
                    <div>
                      <h3>To:</h3>
                      <div>{invoiceData.invoiceHeader.toOrg}</div>
                      <div>{invoiceData.invoiceHeader.toOrgAddress}</div>
                      <div>Email: {invoiceData.invoiceHeader.toOrgEmail}</div>
                      <div>VAT: {invoiceData.invoiceHeader.toOrgVAT}</div>
                    </div>
                  </div>

                  <table style={{ width: "100%", borderCollapse: "collapse" }}>
                    <thead>
                      <tr>
                        <th
                          style={{
                            border: "1px solid black",
                            textAlign: "left",
                          }}
                        >
                          Agency Name
                        </th>
                        <th
                          style={{
                            border: "1px solid black",
                            textAlign: "left",
                          }}
                        >
                          Activity
                        </th>
                        <th
                          style={{
                            border: "1px solid black",
                            textAlign: "left",
                          }}
                        >
                          Description
                        </th>
                        <th
                          style={{
                            border: "1px solid black",
                            textAlign: "left",
                          }}
                        >
                          Hours
                        </th>
                        <th
                          style={{
                            border: "1px solid black",
                            textAlign: "left",
                          }}
                        >
                          Rate
                        </th>
                        <th
                          style={{
                            border: "1px solid black",
                            textAlign: "left",
                          }}
                        >
                          Amount
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {invoiceData.lineItems.map((item, index) => (
                        <tr key={index}>
                          <td style={{ border: "1px solid black" }}>
                            {item.agencyName}
                          </td>
                          <td style={{ border: "1px solid black" }}>
                            {item.activity}
                          </td>
                          <td style={{ border: "1px solid black" }}>
                            {item.description}
                          </td>
                          <td style={{ border: "1px solid black" }}>
                            {item.hours}
                          </td>
                          <td style={{ border: "1px solid black" }}>
                            {item.nightRate}
                          </td>
                          <td style={{ border: "1px solid black" }}>
                            {item.amount}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>

                  <div style={{ marginTop: 20, float: "right" }}>
                    <div>
                      <h5>
                        Total:{" "}
                        <span style={{ float: "right" }}>
                          £{invoiceData.total.toFixed(2)}
                        </span>
                      </h5>
                    </div>
                    <div>
                      <h5>
                        VAT:{" "}
                        <span style={{ float: "right" }}>
                          £{invoiceData.vat.toFixed(2)}
                        </span>
                      </h5>
                    </div>
                    <div style={{ fontStyle: "bold" }}>
                      <h4>
                        Grand Total:{"  "}
                        <span style={{ float: "right" }}>
                          £{invoiceData.grandTotal.toFixed(2)}
                        </span>
                      </h4>
                    </div>
                  </div>

                  <div style={{ marginTop: 20 }}>
                    <div>
                      <strong>Invoice Date:</strong>{" "}
                      {invoiceData.invoiceHeader.invoiceDate}
                    </div>
                    <div>
                      <strong>Due Date:</strong>{" "}
                      {invoiceData.invoiceHeader.dueDate}
                    </div>
                    <div>
                      <strong>Terms:</strong> {invoiceData.invoiceHeader.terms}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }

export default InvoiceManager