import React, { useState, useEffect } from "react"
import {
  Button,
  ListGroup,
  ListGroupItem,
  Spinner,
  Alert,
  Container,
  Row,
  Col,
} from "reactstrap"
import ActivityDetailModal from "./ActivityDetailModal"
import ActivityDataService from "./../../services/ActivityDataService" // Update with the correct path

const ActivityManager = () => {
  const [activities, setActivities] = useState([])
  const [selectedActivity, setSelectedActivity] = useState(null)
  const [modalOpen, setModalOpen] = useState(false)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  useEffect(() => {
    ActivityDataService.getAll()
      .then(fetchedActivities => {
        fetchedActivities.sort((a, b) => new Date(b.date) - new Date(a.date))
        setActivities(fetchedActivities)
        setLoading(false)
      })
      .catch(err => {
        setError("Failed to fetch activities")
        setLoading(false)
      })
  }, [])

  const handleActivityClick = activity => {
    setSelectedActivity(activity)
    setModalOpen(true)
  }

  const toggleModal = () => {
    setModalOpen(!modalOpen)
  }

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "..."
    }
    return text
  }

  return (
   <div className="page-content">
      <Container className="my-5">
        <h1 className="text-center mb-4">Activities</h1>
        {loading && <Spinner className="d-block mx-auto" color="primary" />}
        {error && <Alert color="danger">{error}</Alert>}
        <Row>
          <Col xs="12">
            <ListGroup>
              {activities.map((activity, index) => (
                <ListGroupItem
                  key={index}
                  className="d-flex justify-content-between align-items-center"
                >
                  <span>
                    {truncateText(`${activity.activity} - ${activity.date}`, 100)}
                  </span>
                  <Button
                    color="primary"
                    onClick={() => handleActivityClick(activity)}
                  >
                    View Details
                  </Button>
                </ListGroupItem>
              ))}
            </ListGroup>
          </Col>
        </Row>
        <ActivityDetailModal
          activity={selectedActivity}
          isOpen={modalOpen}
          toggle={toggleModal}
        />
      </Container>
    </div>
  )
}

export default ActivityManager
