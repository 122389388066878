import React, { useState, useEffect } from "react"
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Alert,
  ListGroup,
  ListGroupItem,
} from "reactstrap"
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage"
import {
  getFirestore,
  doc,
  setDoc,
  collection,
  addDoc,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import { deleteObject } from "firebase/storage"

import { deleteDoc } from "firebase/firestore"; // For Firestore deletion

import OrganisationDataService from "services/OrganisationDataService"

import WorkersDataService from "services/WorkersDataService"
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
import { editProfile } from "store/actions"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import style from "./workermanager.css"
import { v4 as uuidv4 } from "uuid"
import { getFirebaseBackend } from "../../helpers/firebase_helper"
import "./worker.css";
import logUserActivity  from "helpers/activityLogger";
import ActivityDataService from "./../../services/ActivityDataService";
import ActivityDetailModal from "../Activity/ActivityDetailModal";
// import { jobType } from "common/data"
import JobTypeDataService from "services/JobTypeDataService";
 

const WorkerManager = () => {
  const [worker, setWorker] = useState([])
  const [dropdownOpenStates, setDropdownOpenStates] = useState({})
  const [emailError, setEmailError] = useState("")
  const [companyName, setCompanyName] = useState("")
  const [organisations, setOrganisations] = useState([])

  const [superadmin, setSuperAdmin] = useState(false)
  const [client, setClient] = useState(false)
  const [clientmanager, setClientManager] = useState(false)
  const [agencysuperadmin, setAgencySuperAdmin] = useState(false)

  const storage = getStorage()
  const firestore = getFirestore()

  const [currentOrg, setCurrentOrg] = useState({
    businessName: "",
    businessID: "",
  })

  useEffect(() => {
    if (!localStorage.getItem("authUserDetails")) {
      // console.log("No user details found...")
    } else {
      // console.log("LS " + localStorage.getItem("authUserDetails"))
      if (localStorage.getItem("authUserDetails")) {
        let userDetails = JSON.parse(localStorage.getItem("authUserDetails"))

        const company = userDetails.userCompany
        console.log("Company is: " + company)
        setCompanyName(company)

        if (userDetails.userType === "Super Admin") {
          console.log("setting as Super Admin.")

          //setSuperAdmin(true)
        } else {
          location.href = "/dashboard"
        }
      } else {
        console.log("No user details found in localStorage.")
      }
    }
  }, [])

  useEffect(() => {
    const unsubscribe = OrganisationDataService.getAll(
      data => {
        setOrganisations(data)
      },
      [currentOrg]
    )

    return () => unsubscribe && unsubscribe()
  }, [])

  const [newCertificate, setNewCertificate] = useState({
    CertificateType: "",
    CertificateValidUntil: "",
    CertificateDoc: null,
  })

  const [selectedOrg, setSelectedOrg] = useState("")

  const handleSelectChange = e => {
    setSelectedOrg(e.target.value)
  }

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "..."
    }
    return text
  }

  const [certificates, setCertificates] = useState([])

  const [currentWorker, setCurrentWorker] = useState({
    firstName: "",
    lastName: "",
    alsoKnownAs: "",
    workerEmailAddress: "",
    emailError: "",
    dateOfBirth: null,
    gender: "",
    phoneNumber: "",
    country: "",
    postcode: "",
    residentialAddress: "",
    howlongintheaddress: "",
    fromDuration: "",
    toDuration: "",
    proofOfAddress: "",
    vatNumber: "",
    status: "",
    workerID: "",

    //Documents
    cv: "",
    covid: "",
    nationalInsurance: "",
    referenceProof: "",
    passport: "",
    passportexpiryDate: null,
    proofOfAddress: "",
    dbs: "",
    dbsexpiryDate: null,
    visa_workpermit: "",
    visa_workpermitDate: null,

    //Certificates
    totalUKCareExperienceinYears: 0,
    totalUKCareExperienceinMonths: 0,
    Certificates: [],

    //Emergency
    emergencyContactName: "",
    emergencyRelationship: "",
    emergencyPriContact: "",
    emergencySecContact: "",

    //Vaccination
    vaccinationType: "",
    vaccinationDate: null,
    vaccinationProductName: "",
    vaccinationClinicSite: "",
    vaccinationIsCovid: false,
    vaccinationDoc: null,
    vaccinationAllergy: "",
    vaccinationTreatment: "",
    vaccinationOtherInfo: "",

    //Reference
    referenceType: "",
    referenceDateDate: null,
    referenceName: "",
    referenceDoc: null,
    referenceOtherInfo: "",

    ReferenceChecked: false,
    jobType: "",

    createdAt: Date.now(),
    provider: "",
    jobType: "Support worker",
  })

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isDocModalOpen, setIsDocModalOpen] = useState(false)
  const [isCertModalOpen, setIsCertModalOpen] = useState(false)
  const [isEmergencyModalOpen, setIsEmergencyModalOpen] = useState(false)
  const [isVacModalOpen, setIsVacModalOpen] = useState(false)
  const [isRefModalOpen, setIsRefModalOpen] = useState(false)
  const [isAuditModalOpen, setIsAuditModalOpen] = useState(false)
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(true)
  const [activities, setActivities] = useState([])
  const [selectedActivity, setSelectedActivity] = useState(null)
  const [modalOpen, setModalOpen] = useState(false)
  const [jobTypes, setJobTypes] = useState([])

  const now = new Date()
  const date = now.toLocaleDateString()
  const time = now.toLocaleTimeString()

  const [searchQuery, setSearchQuery] = useState("") // To track search input
  const [filterType, setFilterType] = useState("") // To track selected activity type
  const [sortOrder, setSortOrder] = useState("asc") // To track sort order (asc or desc)

  useEffect(() => {
    const unsubscribe = WorkersDataService.getAll(
      data => {
        setWorker(data)
      },
      [currentWorker]
    )

    return () => unsubscribe && unsubscribe()
  }, [currentWorker])

  useEffect(() => {
    const fetchJobTypes = async () => {
      try {
        const fetchedJobTypes = await JobTypeDataService.getAll()

        // Remove duplicates
        const uniqueJobTypes = []
        const jobTypeNames = new Set()

        fetchedJobTypes.forEach(jobType => {
          if (!jobTypeNames.has(jobType.name)) {
            jobTypeNames.add(jobType.name)
            uniqueJobTypes.push(jobType)
          }
        })

        setJobTypes(uniqueJobTypes)
      } catch (error) {
        console.error("Error fetching job types: ", error)
      }
    }

    fetchJobTypes()
  }, [])

  useEffect(() => {
    const fetchCertificates = async () => {
      const db = getFirestore()
      const q = query(
        collection(db, "workers", currentWorker.workerID, "certificates")
      )

      try {
        const querySnapshot = await getDocs(q)
        const fetchedCertificates = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }))
        setCertificates(fetchedCertificates)
      } catch (error) {
        console.error("Error fetching certificates: ", error)
      }
    }

    if (currentWorker && currentWorker.workerID) {
      fetchCertificates()
    }
  }, [currentWorker])

  useEffect(() => {
    // console.log(`${currentWorker.firstName} ${currentWorker.lastName}`)
    if (currentWorker && currentWorker.firstName && currentWorker.lastName) {
      ActivityDataService.getAll()
        .then(fetchedActivities => {
          const filteredActivities = fetchedActivities.filter(
            activity =>
              activity.activityType === "Worker" &&
              activity.worker ===
                `${currentWorker.firstName}.${currentWorker.lastName}`
          )
          filteredActivities.sort((a, b) => new Date(b.date) - new Date(a.date))
          setActivities(filteredActivities)
          setLoading(false)
        })
        .catch(err => {
          setError("Failed to fetch activities")
          setLoading(false)
        })
    }
  }, [currentWorker])

  const handleActivityClick = activity => {
    setSelectedActivity(activity)
    setModalOpen(false)
  }

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen)

    if (isModalOpen) {
      resetForm()
    }
  }

  const toggleDocModal = () => {
    setIsDocModalOpen(!isDocModalOpen)

    if (isDocModalOpen) {
      resetDocForm()
    }
  }

  const toggleCertificateModal = () => {
    setIsCertModalOpen(!isCertModalOpen)

    if (isCertModalOpen) {
      resetCertForm()
    }
  }

  const filteredAndSortedActivities = activities
    .filter(activity => {
      // Filter by search query and type
      const matchesSearch = activity.activity
        .toLowerCase()
        .includes(searchQuery.toLowerCase())
      const matchesType = filterType
        ? activity.activityType === filterType
        : true
      return matchesSearch && matchesType
    })
    .sort((a, b) => {
      // Sort by date
      const dateA = new Date(a.date)
      const dateB = new Date(b.date)
      return sortOrder === "asc" ? dateA - dateB : dateB - dateA
    })


  const toggleEmergencyModal = () => {
    setIsEmergencyModalOpen(!isEmergencyModalOpen)

    if (isEmergencyModalOpen) {
      resetCertForm()
    }
  }

  const toggleVacModal = () => {
    setIsVacModalOpen(!isVacModalOpen)

    if (isVacModalOpen) {
      resetVacForm()
    }
  }

  const handleDeleteVaccinationDoc = async docUrl => {
    try {
      // 1. Delete the vaccination document from Firebase Storage
      const storageRef = ref(storage, docUrl)
      await deleteObject(storageRef)
      console.log(
        `Deleted vaccination document from Firebase Storage: ${docUrl}`
      )

      // 2. Update the worker's state to remove the document reference
      setCurrentWorker(prevWorker => ({
        ...prevWorker,
        vaccinationDoc: null, // Remove the document reference
      }))

      // Optionally, you can update the worker data in Firestore as well
      if (currentWorker.workerID) {
        const updatedWorkerData = { ...currentWorker, vaccinationDoc: null }
        await WorkersDataService.updateWorker(
          currentWorker.workerID,
          updatedWorkerData
        )
      }

      console.log("Vaccination document deleted successfully.")
    } catch (error) {
      console.error(`Error deleting vaccination document: ${error.message}`)
    }
  }

  const toggleRefModal = () => {
    setIsRefModalOpen(!isRefModalOpen)

    if (isRefModalOpen) {
      resetRefForm()
    }
  }

  const toggleAuditModal = () => {
    setIsAuditModalOpen(!isAuditModalOpen)

    if (isAuditModalOpen) {
      resetAuditForm()
    }
  }

  const handleInputChange = event => {
    const { name, value } = event.target

    if (name === "workerID") {
      const fixedPrefix = currentWorker.workerID.substring(0, 14)

      if (value.startsWith(fixedPrefix)) {
        setCurrentWorker({ ...currentWorker, [name]: value })
      }
    } else if (name === "provider") {
      currentWorker.provider = company
      setCurrentWorker(prevWorker => ({ ...prevWorker, provider: company }))
    } else {
      setCurrentWorker({ ...currentWorker, [name]: value })
    }
  }

  const handleEmailInputChange = event => {
    const { name, value } = event.target

    if (name === "workerEmailAddress") {
      if (!validateEmail(value)) {
        setEmailError("Invalid email address")
        return
      } else {
        setEmailError("")
      }
    }
    setCurrentWorker({ ...currentWorker, [name]: value })
  }

  function validateEmail(email) {
    const re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
    return re.test(String(email).toLowerCase())
  }

  // const handleFileChange = event => {
  //   const { name, files } = event.target
  //   if (files.length > 0) {
  //     setCurrentWorker(prevState => ({
  //       ...prevState,
  //       [name]: files[0],
  //     }))
  //   }
  // }

  const handleFileChange = event => {
    const { files } = event.target
    const fileList = Array.from(files)

    const newDocs = fileList.map(file => ({
      name: file.name,
      file: file,
      url: URL.createObjectURL(file), // Temporary URL for preview purposes
    }))

    setCurrentWorker(prevWorker => ({
      ...prevWorker,
      referenceDocs: [...(prevWorker.referenceDocs || []), ...newDocs],
    }))
  }

  const submitRefHandler = async e => {
    e.preventDefault()
    const { workerID, referenceDocs, ...workerData } = currentWorker

    try {
      if (workerID) {
        const uploadedDocs = await Promise.all(
          referenceDocs.map(async doc => {
            if (doc.file) {
              const storageRef = ref(
                storage,
                `references/${workerID}/${uuidv4()}`
              )
              await uploadBytes(storageRef, doc.file)
              const url = await getDownloadURL(storageRef)
              return { name: doc.name, url } // Save name and URL
            }
            return doc // If already uploaded, keep existing reference
          })
        )

        const updatedData = { ...workerData, referenceDocs: uploadedDocs }

        await WorkersDataService.updateWorker(workerID, updatedData)
        resetRefForm()
        toggleRefModal()
      } else {
        console.log("Worker ID is required")
      }
    } catch (error) {
      console.log("Update error: " + error.message)
    }
  }

  const handleDeleteReferenceDoc = async (docUrl, index) => {
    try {
      // Remove from Firebase storage
      const storageRef = ref(storage, docUrl)
      await deleteObject(storageRef)

      // Update the state to remove the document
      setCurrentWorker(prevWorker => {
        const updatedDocs = prevWorker.referenceDocs.filter(
          (doc, i) => i !== index
        )
        return { ...prevWorker, referenceDocs: updatedDocs }
      })

      console.log("Document deleted successfully.")
    } catch (error) {
      console.error("Error deleting document:", error.message)
    }
  }

  function getValidDate(date) {
    if (!date) return null

    const parsedDate = new Date(date)
    return isNaN(parsedDate.getTime()) ? null : parsedDate
  }

  const handleBirthExpiryDateChange = date => {
    if (date instanceof Date && !isNaN(date)) {
      setCurrentWorker({ ...currentWorker, dateOfBirth: date })
    }
  }

  const handleDBSExpiryDateChange = date => {
    if (date instanceof Date && !isNaN(date)) {
      setCurrentWorker({ ...currentWorker, dbsexpiryDate: date })
    }
  }

  const handleVisaWorkpermitDateChange = date => {
    if (date instanceof Date && !isNaN(date)) {
      setCurrentWorker({ ...currentWorker, visa_workpermitDate: date })
    }
  }

  const handlePassportExpiryDateChange = date => {
    if (date instanceof Date && !isNaN(date)) {
      setCurrentWorker({ ...currentWorker, passportexpiryDate: date })
    }
  }

  const generateWorkerID = () => {
    const year = new Date().getFullYear()
    const uuid = uuidv4()
    const shortUuid = `${uuid.slice(0, 2)}-${uuid.slice(-2)}`
    return `WOR-${year}-${shortUuid}`.toUpperCase()
  }

  useEffect(() => {
    if (!currentWorker.workerID) {
      console.log(`${JSON.stringify(currentWorker)}`)
      setCurrentWorker(prevWorker => ({
        ...prevWorker,
        workerID: generateWorkerID(),
        companyName: companyName,
      }))
    }
  }, [currentWorker])

  const handleDeleteCertificate = async certificate => {
    try {
      // 1. Delete the certificate from Firebase Storage
      const storageRef = ref(storage, certificate.CertificateDoc)
      await deleteObject(storageRef)
      console.log(`Deleted certificate file: ${certificate.CertificateDoc}`)

      // 2. Remove the certificate from Firestore
      const db = getFirestore()
      const certDocRef = doc(
        db,
        "workers",
        currentWorker.workerID,
        "certificates",
        certificate.id
      )
      await deleteDoc(certDocRef)
      console.log(`Deleted certificate entry from Firestore: ${certificate.id}`)

      // 3. Update local state to remove the deleted certificate
      setCertificates(prevCertificates =>
        prevCertificates.filter(cert => cert.id !== certificate.id)
      )

      // Optionally, log the activity
      logUserActivity({
        date: date,
        time: time,
        activity: `Worker ${currentWorker.firstName} ${currentWorker.lastName}'s certificate deleted successfully.`,
        activityType: "Worker",
        worker: `${currentWorker.firstName}.${currentWorker.lastName}`,
      })

      console.log("Certificate deleted successfully")
    } catch (error) {
      console.error(`Error deleting certificate: ${error.message}`)
    }
  }

  const submitHandler = event => {
    event.preventDefault()
    const { workerID, ...workerData } = currentWorker

    workerData.companyID = selectedOrg // Make sure selectedOrg is the company ID
    workerData.provider = selectedOrg

    if (workerID) {
      WorkersDataService.updateWorker(workerID, workerData).then(() => {
        // Log the activity
        logUserActivity({
          date: date,
          time: time,
          activity: `Worker ${workerData.firstName} ${workerData.lastName}'s record updated successfully.`,
          activityType: "Worker",
          worker: `${workerData.firstName}.${workerData.lastName}`,
        })
        resetForm()
        toggleModal()
      })
    } else {
      WorkersDataService.createWorker(workerData).then(() => {
        // Log the activity
        logUserActivity({
          date: date,
          time: time,
          activity: `Worker ${workerData.firstName} ${workerData.lastName}'s record created successfully.`,
          activityType: "Worker",
          worker: `${workerData.firstName}.${workerData.lastName}`,
        })
        resetForm()
        toggleModal()
      })
    }
  }

  const submitCertHandler = async e => {
    e.preventDefault()
    const { workerID, ...workerData } = currentWorker

    const db = getFirestore()
    const storage = getStorage()

    const storageRef = ref(
      storage,
      `certificates/${workerID}/${newCertificate.CertificateDoc.name}`
    )
    await uploadBytes(storageRef, newCertificate.CertificateDoc)
      .then(async snapshot => {
        const downloadURL = await getDownloadURL(snapshot.ref)

        const certificateData = {
          CertificateType: newCertificate.CertificateType,
          CertificateValidUntil: newCertificate.CertificateValidUntil,
          CertificateDoc: downloadURL,
        }

        try {
          const workerCertsRef = collection(
            db,
            "workers",
            workerID,
            "certificates"
          )

          try {
            const docRef = await addDoc(workerCertsRef, certificateData)

            // Log the activity
            logUserActivity({
              date: date,
              time: time,
              activity: `Worker ${workerData.firstName} ${workerData.lastName}'s certificate updated successfully.`,
              activityType: "Worker",
              worker: `${workerData.firstName}.${workerData.lastName}`,
            })

            setCertificates(prev => [
              ...prev,
              { id: docRef.id, ...certificateData },
            ])
          } catch (error) {
            console.error("Battling with updating of firestore: ", error)
          }

          setNewCertificate({
            CertificateType: "",
            CertificateValidUntil: "",
            CertificateDoc: null,
          })
        } catch (error) {
          console.error("Error updating firestore: ", error)
        }
      })
      .catch(error => {
        console.error("Error uploading file: ", error)
      })
  }

  // Function to delete document from Firebase storage and update worker's document reference
  const handleDeleteDocument = async (docField, docUrl) => {
    try {
      const storageRef = ref(storage, docUrl)

      // Delete from Firebase Storage
      await deleteObject(storageRef)
      console.log(`Deleted document from Firebase Storage: ${docUrl}`)

      // Update the worker's state and remove the document reference
      setCurrentWorker(prevWorker => ({
        ...prevWorker,
        [docField]: null, // Set the specific document field to null
      }))

      // Optionally, you could also update the worker in Firestore to persist the change
      if (currentWorker.workerID) {
        const updatedWorkerData = { ...currentWorker, [docField]: null }
        await WorkersDataService.updateWorker(
          currentWorker.workerID,
          updatedWorkerData
        )
      }

      console.log(`Document field ${docField} deleted successfully`)
    } catch (error) {
      console.error(`Error deleting document: ${error.message}`)
    }
  }

  const submitEmergencyHandler = async e => {
    e.preventDefault()
    const { workerID, ...workerData } = currentWorker
    if (workerID) {
      WorkersDataService.updateWorker(workerID, workerData).then(() => {
        // Log the activity
        logUserActivity({
          date: date,
          time: time,
          activity: `Worker ${workerData.firstName} ${workerData.lastName}'s emergency record updated successfully.`,
          activityType: "Worker",
          worker: `${workerData.firstName}.${workerData.lastName}`,
        })

        resetForm()
        toggleEmergencyModal()
      })
    } else {
      WorkersDataService.createWorker(workerData).then(() => {
        // Log the activity
        logUserActivity({
          date: date,
          time: time,
          activity: `Worker ${workerData.firstName} ${workerData.lastName}'s emergency record created successfully.`,
          activityType: "Worker",
          worker: `${workerData.firstName}.${workerData.lastName}`,
        })

        resetForm()
        toggleEmergencyModal()
      })
    }
  }

  const submitVacHandler = async e => {
    e.preventDefault()
    const { workerID, vaccinationDoc, ...workerData } = currentWorker

    try {
      if (workerID) {
        // Upload the vaccination document and get its download URL
        const uploadDocument = async (document, path) => {
          if (document) {
            const documentRef = ref(storage, `documents/${workerID}/${path}`)
            await uploadBytes(documentRef, document)
            return getDownloadURL(documentRef)
          }
          return null
        }

        const vaccinationDocUrl = await uploadDocument(
          vaccinationDoc,
          "vaccinationDoc"
        )

        // Log the activity
        logUserActivity({
          date: date,
          time: time,
          activity: `Worker ${workerData.firstName} ${workerData.lastName}'s vaccination record updated successfully.`,
          activityType: "Worker",
          worker: `${workerData.firstName}.${workerData.lastName}`,
        })

        const updatedData = {
          ...workerData,
          vaccinationDoc: vaccinationDocUrl,
        }

        WorkersDataService.updateWorker(workerID, updatedData).then(() => {
          resetForm()
          toggleVacModal()
        })
      } else {
        console.log("Worker ID is required")
      }
    } catch (error) {
      console.log("Update error: " + error.message)
    }
  }

  const submitAuditHandler = async e => {
    e.preventDefault()
    const { workerID, vaccinationDoc, ...workerData } = currentWorker

    try {
      if (workerID) {
        // Upload the vaccination document and get its download URL
        const uploadDocument = async (document, path) => {
          if (document) {
            const documentRef = ref(storage, `documents/${workerID}/${path}`)
            await uploadBytes(documentRef, document)
            return getDownloadURL(documentRef)
          }
          return null
        }

        const vaccinationDocUrl = await uploadDocument(
          vaccinationDoc,
          "vaccinationDoc"
        )

        const updatedData = {
          ...workerData,
          vaccinationDoc: vaccinationDocUrl,
        }

        WorkersDataService.updateWorker(workerID, updatedData).then(() => {
          resetForm()
          toggleVacModal()
        })
      } else {
        console.log("Worker ID is required")
      }
    } catch (error) {
      console.log("Update error: " + error.message)
    }
  }

  const submitDocHandler = async event => {
    event.preventDefault()
    const {
      workerID,
      cv,
      covid,
      nationalInsurance,
      referenceProof,
      dbs,
      visa_workpermit,
      proofOfAddress,
      ...otherData
    } = currentWorker // Use currentWorker to access worker data

    try {
      if (workerID) {
        const uploadDocument = async (document, path) => {
          if (document) {
            const documentRef = ref(storage, `documents/${workerID}/${path}`)
            await uploadBytes(documentRef, document)
            return getDownloadURL(documentRef)
          }
          return null
        }

        const cvUrl = await uploadDocument(cv, "cv")
        if (cvUrl) {
          logUserActivity({
            date: date,
            time: time,
            activity: `Worker ${currentWorker.firstName} ${currentWorker.lastName}'s CV updated successfully.`,
            activityType: "Worker",
            worker: `${currentWorker.firstName}.${currentWorker.lastName}`,
          })
        }

        const covidUrl = await uploadDocument(covid, "covid")
        if (covidUrl) {
          logUserActivity({
            date: date,
            time: time,
            activity: `Worker ${currentWorker.firstName} ${currentWorker.lastName}'s COVID document updated successfully.`,
            activityType: "Worker",
            worker: `${currentWorker.firstName}.${currentWorker.lastName}`,
          })
        }

        const nationalInsuranceUrl = await uploadDocument(
          nationalInsurance,
          "nationalInsurance"
        )
        if (nationalInsuranceUrl) {
          logUserActivity({
            date: date,
            time: time,
            activity: `Worker ${currentWorker.firstName} ${currentWorker.lastName}'s NI document updated successfully.`,
            activityType: "Worker",
            worker: `${currentWorker.firstName}.${currentWorker.lastName}`,
          })
        }

        const passportUrl = await uploadDocument(passport, "passport")
        if (passportUrl) {
          logUserActivity({
            date: date,
            time: time,
            activity: `Worker ${currentWorker.firstName} ${currentWorker.lastName}'s passport uploaded successfully.`,
            activityType: "Worker",
            worker: `${currentWorker.firstName}.${currentWorker.lastName}`,
          })
        }

        const referenceProofUrl = await uploadDocument(
          referenceProof,
          "referenceProof"
        )
        if (referenceProofUrl) {
          logUserActivity({
            date: date,
            time: time,
            activity: `Worker ${currentWorker.firstName} ${currentWorker.lastName}'s reference proof updated successfully.`,
            activityType: "Worker",
            worker: `${currentWorker.firstName}.${currentWorker.lastName}`,
          })
        }

        const dbsUrl = await uploadDocument(dbs, "dbs")
        if (dbsUrl) {
          logUserActivity({
            date: date,
            time: time,
            activity: `Worker ${currentWorker.firstName} ${currentWorker.lastName}'s DBS document updated successfully.`,
            activityType: "Worker",
            worker: `${currentWorker.firstName}.${currentWorker.lastName}`,
          })
        }

        const visaWorkpermitUrl = await uploadDocument(
          visa_workpermit,
          "visa_workpermit"
        )
        if (visaWorkpermitUrl) {
          logUserActivity({
            date: date,
            time: time,
            activity: `Worker ${currentWorker.firstName} ${currentWorker.lastName}'s visa work permit updated successfully.`,
            activityType: "Worker",
            worker: `${currentWorker.firstName}.${currentWorker.lastName}`,
          })
        }

        const proofOfAddressUrl = await uploadDocument(
          proofOfAddress,
          "proofOfAddress"
        )
        if (proofOfAddressUrl) {
          logUserActivity({
            date: date,
            time: time,
            activity: `Worker ${currentWorker.firstName} ${currentWorker.lastName}'s proof of address updated successfully.`,
            activityType: "Worker",
            worker: `${currentWorker.firstName}.${currentWorker.lastName}`,
          })
        }

        const updatedData = {
          ...otherData,
          cv: cvUrl,
          covid: covidUrl,
          nationalInsurance: nationalInsuranceUrl,
          referenceProof: referenceProofUrl,
          dbs: dbsUrl,
          passport: passportUrl,
          visa_workpermit: visaWorkpermitUrl,
          proofOfAddress: proofOfAddressUrl,
        }

        await WorkersDataService.updateWorker(workerID, updatedData)
        resetDocForm()
        toggleDocModal()
      } else {
        console.log("Worker ID is required")
      }
    } catch (error) {
      console.log("Update error: " + error.message)
    }
  }

  const resetForm = () => {
    setCurrentWorker({
      firstName: "",
      lastName: "",
      alsoKnownAs: "",
      workerEmailAddress: "",
      dateOfBirth: null,
      gender: "",
      phoneNumber: "",
      country: "",
      postcode: "",
      residentialAddress: "",
      howlongintheaddress: "",
      fromDuration: "",
      toDuration: "",
      proofOfAddress: "",
      vatNumber: "",
      status: "",
      workerID: "",
    })
  }

  const resetDocForm = () => {
    setCurrentWorker({
      cv: "",
      covid: "",
      nationalInsurance: "",
      referenceProof: "",
      passport: "",
      passportexpiryDate: null,
      proofOfAddress: "",
      dbs: "",
      dbsexpiryDate: null,
      visa_workpermit: "",
      visa_workpermitDate: null,
    })
  }

  const resetCertForm = () => {
    setCurrentWorker({
      CertificateType: "",
      CertificateValidUntil: null,
      certificates: [],
    })
  }

  const resetVacForm = () => {
    setCurrentWorker({})
  }

  const resetRefForm = () => {
    setCurrentWorker({})
  }

  const resetAuditForm = () => {
    setCurrentWorker({})
  }

  const resetEmergencyForm = () => {
    setCurrentWorker({
      emergencyContactName: "",
      emergencyRelationship: "",
      emergencyPriContact: "",
      emergencySecContact: "",
    })
  }

  const editProfile = work => {
    setCurrentWorker({ ...work, workerID: work.key || "" })

    setSelectedOrg(work.companyID || "") // Ensure the companyID is stored in the worker data

    toggleModal()
  }

  const editDocuments = work => {
    setCurrentWorker({ ...work, workerID: work.key || "" })

    toggleDocModal()
  }

  const editCertificates = work => {
    setCurrentWorker({ ...work, workerID: work.key || "" })

    toggleCertificateModal()
  }

  const editEmergency = work => {
    setCurrentWorker({ ...work, workerID: work.key || "" })

    toggleEmergencyModal()
  }

  const editVaccinations = work => {
    setCurrentWorker({ ...work, workerID: work.key || "" })

    toggleVacModal()
  }

  const editReferences = work => {
    setCurrentWorker({ ...work, workerID: work.key || "" })

    toggleRefModal()
  }

  const editAudits = work => {
    setCurrentWorker({ ...work, workerID: work.key || "" })

    toggleAuditModal()
  }

  const deleteWorker = key => {
    WorkersDataService.deleteWorker(key)
  }

  const toggleDropdown = key => {
    setDropdownOpenStates(prevState => ({
      ...prevState,
      [key]: !prevState[key],
    }))
  }

  function getValidDate(date) {
    if (!date) return null
    const parsedDate = new Date(date)
    return isNaN(parsedDate.getTime()) ? null : parsedDate
  }

  useEffect(() => {
    setCurrentWorker(current => ({
      ...current,
      companyName: companyName,
    }))
  }, [companyName])

  return (
    <div className="page-content">
      <div className="doc-container">
        <Button
          style={{ margin: "10px" }}
          color="primary"
          onClick={() => {
            toggleModal()
          }}
        >
          Add New Worker
        </Button>
      </div>
      <span
        style={{ float: "right" }}
      >{`Displaying ${worker.length} worker(s)`}</span>
      <Modal
        isOpen={isModalOpen}
        toggle={toggleModal}
        backdrop={true}
        size="lg"
      >
        <ModalHeader toggle={toggleModal}>
          {currentWorker.workerID ? "Worker" : "Create Worker"} |{" "}
          {currentWorker.firstName} {currentWorker.lastName}
        </ModalHeader>
        <Form onSubmit={submitHandler}>
          <ModalBody>
            <div>Fields marked with an asterisk (*) are required.</div>
            <FormGroup>
              <Label for="workerID">
                Worker ID<span style={{ color: "red" }}>*</span>
              </Label>
              <Input
                type="text"
                name="workerID"
                id="workerID"
                value={currentWorker.workerID || ""}
                onChange={handleInputChange}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for="businessName">
                First Name<span style={{ color: "red" }}>*</span>
              </Label>
              <Input
                type="text"
                name="firstName"
                id="firstName"
                value={currentWorker.firstName}
                onChange={handleInputChange}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for="lastName">
                Last Name<span style={{ color: "red" }}>*</span>
              </Label>
              <Input
                type="text"
                name="lastName"
                id="lastName"
                value={currentWorker.lastName}
                onChange={handleInputChange}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for="alsoKnownAs">Also known as</Label>
              <Input
                type="text"
                name="alsoKnownAs"
                id="alsoKnownAs"
                value={currentWorker.alsoKnownAs}
                onChange={handleInputChange}
              />
            </FormGroup>

            <FormGroup>
              <Label for="jobType">
                Job Type<span style={{ color: "red" }}>*</span>
              </Label>
              <Input
                type="select"
                name="jobType"
                id="jobType"
                value={currentWorker.jobType}
                onChange={handleInputChange}
                required
              >
                <option value="">Select Job Type</option>
                {jobTypes.map(jobType => (
                  <option key={jobType.key} value={jobType.name}>
                    {jobType.name}
                  </option>
                ))}
              </Input>
            </FormGroup>

            <FormGroup>
              <Label for="workerEmailAddress">
                Worker Email Address<span style={{ color: "red" }}>*</span>
              </Label>
              <div>
                <Input
                  type="email"
                  name="workerEmailAddress"
                  id="workerEmailAddress"
                  value={currentWorker.workerEmailAddress}
                  onChange={handleInputChange}
                  className={emailError ? "error-class" : ""}
                  required
                />
                {emailError && (
                  <div className="error-message">{emailError}</div>
                )}
              </div>
            </FormGroup>
            <FormGroup>
              <Label for="dateOfBirth">
                Date Of Birth<span style={{ color: "red" }}>*</span>
              </Label>
              <DatePicker
                selected={getValidDate(currentWorker.dateOfBirth)}
                onChange={handleBirthExpiryDateChange}
                className="form-control"
                dateFormat="dd/MM/yyyy"
                required
              />
            </FormGroup>

            <FormGroup>
              <Label for="gender">
                Gender<span style={{ color: "red" }}>*</span>
              </Label>
              <select
                name="gender"
                id="gender"
                value={currentWorker.gender}
                onChange={handleInputChange}
                className="form-control"
                required
              >
                <option value="">Select Gender</option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
              </select>
            </FormGroup>

            <FormGroup>
              <Label for="phoneNumber">
                Phone Number<span style={{ color: "red" }}>*</span>
              </Label>
              <Input
                type="text"
                name="phoneNumber"
                id="phoneNumber"
                value={currentWorker.phoneNumber}
                onChange={handleInputChange}
                required
              />
            </FormGroup>

            <FormGroup>
              <Label for="country">
                Country<span style={{ color: "red" }}>*</span>
              </Label>
              <Input
                type="text"
                name="country"
                id="country"
                value={currentWorker.country}
                onChange={handleInputChange}
                required
              />
            </FormGroup>

            <FormGroup>
              <Label for="postcode">
                Post code<span style={{ color: "red" }}>*</span>
              </Label>
              <Input
                type="text"
                name="postcode"
                id="postcode"
                value={currentWorker.postcode}
                onChange={handleInputChange}
                required
              />
            </FormGroup>

            <FormGroup>
              <Label for="residentialAddress">
                Residential Address<span style={{ color: "red" }}>*</span>
              </Label>
              <Input
                type="text"
                name="residentialAddress"
                id="residentialAddress"
                value={currentWorker.residentialAddress}
                onChange={handleInputChange}
                required
              />
            </FormGroup>

            <FormGroup>
              <Label for="howlongintheaddress">
                How long have you lived in the Address?
                <span style={{ color: "red" }}>*</span>
              </Label>
              <Input
                type="text"
                name="howlongintheaddress"
                id="howlongintheaddress"
                value={currentWorker.howlongintheaddress}
                onChange={handleInputChange}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for="fromDuration">
                Duration From<span style={{ color: "red" }}>*</span>
              </Label>
              <DatePicker
                selected={getValidDate(currentWorker.fromDuration)}
                onChange={date =>
                  setCurrentWorker({ ...currentWorker, fromDuration: date })
                }
                className="form-control"
                dateFormat="dd/MM/yyyy"
                required
              />
            </FormGroup>

            <FormGroup>
              <Label for="toDuration">
                Duration To<span style={{ color: "red" }}>*</span>
              </Label>
              <DatePicker
                selected={getValidDate(currentWorker.toDuration)}
                onChange={date =>
                  setCurrentWorker({ ...currentWorker, toDuration: date })
                }
                className="form-control"
                dateFormat="dd/MM/yyyy"
                required
              />
            </FormGroup>

            <FormGroup>
              <Label for="companyName">Company</Label>
              <Input
                type="text"
                name="companyName"
                id="companyName"
                value={currentWorker.companyName}
                onChange={handleInputChange}
                disabled={true}
                style={{ display: "none" }}
              />
              <select
                className="form-control"
                value={selectedOrg}
                onChange={handleSelectChange}
              >
                <option value="">Select Organisation</option>
                {organisations.map(org => (
                  <option key={org.key} value={org.key}>
                    {org.businessName}
                  </option>
                ))}
              </select>
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" type="submit">
              {currentWorker.workerID ? "Save" : "Create"}
            </Button>
            <Button color="secondary" onClick={toggleModal}>
              Cancel
            </Button>
          </ModalFooter>
        </Form>
      </Modal>

      <Modal
        isOpen={isDocModalOpen}
        toggle={toggleDocModal}
        backdrop={true}
        size="lg"
      >
        <ModalHeader toggle={toggleDocModal}>
          {currentWorker.workerID ? "Document" : "Create Document"} |{" "}
          {currentWorker.firstName} {currentWorker.lastName}
        </ModalHeader>
        <Form onSubmit={submitDocHandler}>
          <ModalBody>
            <FormGroup>
              <div className="doc-container">
                <Label for="cv">CV</Label>
                {currentWorker.cv && (
                  <>
                    <a
                      href={currentWorker.cv || "#"}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      View Uploaded CV
                    </a>
                    <Button
                      color="danger"
                      size="sm"
                      onClick={() =>
                        handleDeleteDocument("cv", currentWorker.cv)
                      }
                      style={{ marginLeft: "10px" }}
                    >
                      Delete
                    </Button>
                  </>
                )}
              </div>
              <Input
                type="file"
                name="cv"
                id="cv"
                onChange={handleFileChange}
              />
            </FormGroup>
            <FormGroup>
              <div className="doc-container">
                <Label for="covid">COVID</Label>
                {currentWorker.covid && (
                  <>
                    <a
                      href={currentWorker.covid || "#"}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      View Uploaded COVID
                    </a>
                    <Button
                      color="danger"
                      size="sm"
                      onClick={() =>
                        handleDeleteDocument("covid", currentWorker.covid)
                      }
                      style={{ marginLeft: "10px" }}
                    >
                      Delete
                    </Button>
                  </>
                )}
              </div>
              <Input
                type="file"
                name="covid"
                id="covid"
                onChange={handleFileChange}
              />
            </FormGroup>

            <FormGroup>
              <div className="doc-container">
                <Label for="nationalInsurance">National Insurance</Label>
                {currentWorker.nationalInsurance && (
                  <>
                    <a
                      href={currentWorker.nationalInsurance || "#"}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      View Uploaded NI
                    </a>
                    <Button
                      color="danger"
                      size="sm"
                      onClick={() =>
                        handleDeleteDocument(
                          "nationalInsurance",
                          currentWorker.nationalInsurance
                        )
                      }
                      style={{ marginLeft: "10px" }}
                    >
                      Delete
                    </Button>
                  </>
                )}
              </div>
              <Input
                type="file"
                name="nationalInsurance"
                id="nationalInsurance"
                onChange={handleFileChange}
              />
            </FormGroup>

            <FormGroup>
              <div className="doc-container">
                <Label for="passport">Passport</Label>
                {currentWorker.passport && (
                  <>
                    <a
                      href={currentWorker.passport || "#"}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      View Uploaded passport
                    </a>
                    <Button
                      color="danger"
                      size="sm"
                      onClick={() =>
                        handleDeleteDocument("passport", currentWorker.passport)
                      }
                      style={{ marginLeft: "10px" }}
                    >
                      Delete
                    </Button>
                  </>
                )}
              </div>
              <Input
                type="file"
                name="passport"
                id="passport"
                onChange={handleFileChange}
              />
            </FormGroup>

            <FormGroup>
              <Label for="passportexpiryDate">Passport Expiry Date</Label>
              <DatePicker
                selected={getValidDate(currentWorker.passportexpiryDate)}
                onChange={handlePassportExpiryDateChange}
                className="form-control"
                dateFormat="dd/MM/yyyy"
              />
            </FormGroup>

            <FormGroup>
              <div className="doc-container">
                <Label for="dbs">DBS</Label>
                {currentWorker.dbs && (
                  <>
                    <a
                      href={currentWorker.dbs || "#"}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      View Uploaded DBS
                    </a>
                    <Button
                      color="danger"
                      size="sm"
                      onClick={() =>
                        handleDeleteDocument("dbs", currentWorker.dbs)
                      }
                      style={{ marginLeft: "10px" }}
                    >
                      Delete
                    </Button>
                  </>
                )}
              </div>
              <Input
                type="file"
                name="dbs"
                id="dbs"
                onChange={handleFileChange}
              />
            </FormGroup>

            <FormGroup>
              <Label for="dbsexpiryDate">DBS Expiry Date</Label>

              <DatePicker
                selected={getValidDate(currentWorker.dbsexpiryDate)}
                onChange={handleDBSExpiryDateChange}
                className="form-control"
                dateFormat="dd/MM/yyyy"
              />
            </FormGroup>

            <FormGroup>
              <div className="doc-container">
                <Label for="visa_workpermit">Visa Work permit</Label>
                {currentWorker.visa_workpermit && (
                  <>
                    <a
                      href={currentWorker.visa_workpermit || "#"}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      View Uploaded visa work permit
                    </a>
                    <Button
                      color="danger"
                      size="sm"
                      onClick={() =>
                        handleDeleteDocument(
                          "visa_workpermit",
                          currentWorker.visa_workpermit
                        )
                      }
                      style={{ marginLeft: "10px" }}
                    >
                      Delete
                    </Button>
                  </>
                )}
              </div>
              <Input
                type="file"
                name="visa_workpermit"
                id="visa_workpermit"
                onChange={handleFileChange}
              />
            </FormGroup>

            <FormGroup>
              <Label for="visa_workpermitDate">Visa Work Permit Date</Label>

              <DatePicker
                selected={getValidDate(currentWorker.visa_workpermitDate)}
                onChange={handleVisaWorkpermitDateChange}
                className="form-control"
                dateFormat="dd/MM/yyyy"
              />
            </FormGroup>

            <FormGroup>
              <div className="doc-container">
                <Label for="proofOfAddress">Proof Of Address</Label>
                {currentWorker.proofOfAddress && (
                  <>
                    <a
                      href={currentWorker.proofOfAddress || "#"}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      View Uploaded Proof Of Address
                    </a>
                    <Button
                      color="danger"
                      size="sm"
                      onClick={() =>
                        handleDeleteDocument(
                          "proofOfAddress",
                          currentWorker.proofOfAddress
                        )
                      }
                      style={{ marginLeft: "10px" }}
                    >
                      Delete
                    </Button>
                  </>
                )}
              </div>
              <Input
                type="file"
                name="proofOfAddress"
                id="proofOfAddress"
                onChange={handleFileChange}
              />
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" type="submit">
              {currentWorker.workerID ? "Save" : "Create"}
            </Button>
            <Button color="secondary" onClick={toggleDocModal}>
              Cancel
            </Button>
          </ModalFooter>
        </Form>
      </Modal>

      <Modal
        isOpen={isCertModalOpen}
        toggle={toggleCertificateModal}
        backdrop={true}
        size="lg"
      >
        <ModalHeader toggle={toggleCertificateModal}>
          {currentWorker.workerID ? "Certificate" : "Create Certificate"} |{" "}
          {currentWorker.firstName} {currentWorker.lastName}
        </ModalHeader>
        <form
          onSubmit={submitCertHandler}
          style={{
            padding: "20px",
            maxWidth: "500px",
            margin: "0 auto",
            backgroundColor: "#f5f5f5",
            borderRadius: "8px",
            boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
          }}
        >
          <div style={{ marginBottom: "10px" }}>
            <input
              type="text"
              value={newCertificate.CertificateType}
              onChange={e =>
                setNewCertificate({
                  ...newCertificate,
                  CertificateType: e.target.value,
                })
              }
              placeholder="Certificate Type"
              style={{
                padding: "10px",
                width: "100%",
                borderRadius: "4px",
                border: "1px solid #ddd",
              }}
            />
          </div>
          <div style={{ marginBottom: "10px" }}>
            <input
              type="date"
              value={newCertificate.CertificateValidUntil}
              onChange={e =>
                setNewCertificate({
                  ...newCertificate,
                  CertificateValidUntil: e.target.value,
                })
              }
              style={{
                padding: "10px",
                width: "100%",
                borderRadius: "4px",
                border: "1px solid #ddd",
              }}
            />
          </div>
          <div style={{ marginBottom: "20px" }}>
            <input
              type="file"
              onChange={e =>
                setNewCertificate({
                  ...newCertificate,
                  CertificateDoc: e.target.files[0],
                })
              }
              style={{ width: "100%" }}
            />
          </div>
          <button
            type="submit"
            style={{
              padding: "10px 20px",
              backgroundColor: "#007bff",
              color: "white",
              border: "none",
              borderRadius: "4px",
              cursor: "pointer",
            }}
          >
            Add Certificate
          </button>
        </form>

        <ul
          style={{
            listStyleType: "none",
            padding: "0",
            maxWidth: "500px",
            margin: "20px auto",
          }}
        >
          {certificates.map((certificate, index) => (
            <li
              key={index}
              style={{
                background: "#fff",
                margin: "10px 0",
                padding: "15px",
                borderRadius: "4px",
                boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
              }}
            >
              <div>
                Type: {certificate.CertificateType}, Valid Until:{" "}
                {certificate.CertificateValidUntil}
                <a
                  href={certificate.CertificateDoc}
                  rel="noreferrer"
                  target="_blank"
                  style={{
                    marginLeft: "10px",
                    textDecoration: "none",
                    color: "#007bff",
                  }}
                >
                  View Document
                </a>
                <Button
                  color="danger"
                  size="sm"
                  onClick={() => handleDeleteCertificate(certificate)}
                  style={{ marginLeft: "10px" }}
                >
                  Delete
                </Button>
              </div>
            </li>
          ))}
        </ul>
        <Button color="secondary" onClick={toggleCertificateModal}>
          Close
        </Button>
      </Modal>

      <Modal
        isOpen={isEmergencyModalOpen}
        toggle={toggleEmergencyModal}
        backdrop={true}
        size="lg"
      >
        <ModalHeader toggle={toggleEmergencyModal}>
          {currentWorker.workerID ? "Emergency" : "Create Emergency"} |{" "}
          {currentWorker.firstName} {currentWorker.lastName}
        </ModalHeader>
        <form onSubmit={submitEmergencyHandler}>
          <ModalBody>
            <FormGroup>
              <Label for="emergencyContactName">Emergency Contact Name</Label>
              <Input
                type="text"
                name="emergencyContactName"
                id="emergencyContactName"
                value={currentWorker.emergencyContactName || ""}
                onChange={handleInputChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="emergencyRelationship">
                Emergency Relationship<span style={{ color: "red" }}>*</span>
              </Label>
              <Input
                type="text"
                name="emergencyRelationship"
                id="emergencyContactName"
                value={currentWorker.emergencyRelationship || ""}
                onChange={handleInputChange}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for="emergencyPriContact">
                Emergency Primary Contact<span style={{ color: "red" }}>*</span>
              </Label>
              <Input
                type="text"
                name="emergencyPriContact"
                id="emergencyPriContact"
                value={currentWorker.emergencyPriContact || ""}
                onChange={handleInputChange}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for="emergencySecContact">
                Emergency Secondary Contact
                <span style={{ color: "red" }}>*</span>
              </Label>
              <Input
                type="text"
                name="emergencySecContact"
                id="emergencySecContact"
                value={currentWorker.emergencySecContact || ""}
                onChange={handleInputChange}
                required
              />
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" type="submit">
              {currentWorker.workerID ? "Save" : "Create"}
            </Button>
            <Button color="secondary" onClick={toggleEmergencyModal}>
              Cancel
            </Button>
          </ModalFooter>
        </form>
      </Modal>

      <Modal
        isOpen={isVacModalOpen}
        toggle={toggleVacModal}
        backdrop={true}
        size="lg"
      >
        <ModalHeader toggle={toggleVacModal}>
          {currentWorker.workerID ? "Vaccination" : "Create Vaccination"} |{" "}
          {currentWorker.firstName} {currentWorker.lastName}
        </ModalHeader>
        <form onSubmit={submitVacHandler}>
          <ModalBody>
            <FormGroup>
              <Label for="vaccinationType">Vaccination Type</Label>
              <Input
                type="text"
                name="vaccinationType"
                id="vaccinationType"
                value={currentWorker.vaccinationType}
                onChange={handleInputChange}
              />
            </FormGroup>

            <FormGroup>
              <Label for="vaccinationDate">Vaccination Date</Label>
              <Input
                type="date"
                name="vaccinationDate"
                id="vaccinationDate"
                value={currentWorker.vaccinationDate}
                onChange={handleInputChange}
              />
            </FormGroup>

            <FormGroup>
              <Label for="vaccinationProductName">Product Name</Label>
              <Input
                type="text"
                name="vaccinationProductName"
                id="vaccinationProductName"
                value={currentWorker.vaccinationProductName}
                onChange={handleInputChange}
              />
            </FormGroup>

            <FormGroup>
              <Label for="vaccinationClinicSite">Clinic Site</Label>
              <Input
                type="text"
                name="vaccinationClinicSite"
                id="vaccinationClinicSite"
                value={currentWorker.vaccinationClinicSite}
                onChange={handleInputChange}
              />
            </FormGroup>

            <FormGroup>
              <Label for="vaccinationIsCovid">Is Covid</Label>
              <Input
                type="text"
                name="vaccinationIsCovid"
                id="vaccinationIsCovid"
                value={currentWorker.vaccinationIsCovid}
                onChange={handleInputChange}
              />
            </FormGroup>

            <FormGroup>
              <Label for="vaccinationDoc">Vaccination Document</Label>
              <div className="doc-container">
                {currentWorker.vaccinationDoc && (
                  <>
                    <a
                      href={currentWorker.vaccinationDoc || "#"}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      View Vaccination Document
                    </a>
                    <Button
                      color="danger"
                      size="sm"
                      onClick={() =>
                        handleDeleteVaccinationDoc(currentWorker.vaccinationDoc)
                      }
                      style={{ marginLeft: "10px" }}
                    >
                      Delete
                    </Button>
                  </>
                )}
              </div>
              <Input
                type="file"
                name="vaccinationDoc"
                id="vaccinationDoc"
                onChange={handleFileChange}
              />
            </FormGroup>

            <FormGroup>
              <Label for="vaccinationAllergy">Allergy</Label>
              <Input
                type="text"
                name="vaccinationAllergy"
                id="vaccinationAllergy"
                value={currentWorker.vaccinationAllergy}
                onChange={handleInputChange}
              />
            </FormGroup>

            <FormGroup>
              <Label for="vaccinationTreatment">Treatment</Label>
              <Input
                type="text"
                name="vaccinationTreatment"
                id="vaccinationTreatment"
                value={currentWorker.vaccinationTreatment}
                onChange={handleInputChange}
              />
            </FormGroup>

            <FormGroup>
              <Label for="vaccinationOtherInfo">Other Information</Label>
              <Input
                type="text"
                name="vaccinationOtherInfo"
                id="vaccinationOtherInfo"
                value={currentWorker.vaccinationOtherInfo}
                onChange={handleInputChange}
              />
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" type="submit">
              {currentWorker.workerID ? "Save" : "Create"}
            </Button>
            <Button color="secondary" onClick={toggleVacModal}>
              Cancel
            </Button>
          </ModalFooter>
        </form>
      </Modal>

      <Modal
        isOpen={isRefModalOpen}
        toggle={toggleRefModal}
        backdrop={true}
        size="lg"
      >
        <ModalHeader toggle={toggleRefModal}>
          {currentWorker.workerID ? "References" : "Create References"} |{" "}
          {currentWorker.firstName} {currentWorker.lastName}
        </ModalHeader>
        <form onSubmit={submitRefHandler}>
          <ModalBody>
            <FormGroup>
              <Label for="referenceType">Reference Type</Label>
              <Input
                type="text"
                name="referenceType"
                id="referenceType"
                value={currentWorker.referenceType}
                onChange={handleInputChange}
              />
            </FormGroup>

            <FormGroup>
              <Label for="referenceDate">Reference Date</Label>
              <Input
                type="date"
                name="referenceDate"
                id="referenceDate"
                value={currentWorker.referenceDate}
                onChange={handleInputChange}
              />
            </FormGroup>

            <FormGroup>
              <Label for="referenceName">Reference Name</Label>
              <Input
                type="text"
                name="referenceName"
                id="referenceName"
                value={currentWorker.referenceName}
                onChange={handleInputChange}
              />
            </FormGroup>

            <FormGroup>
              <Label for="referenceDocs">Reference Documents</Label>
              <Input
                type="file"
                name="referenceDocs"
                id="referenceDocs"
                multiple
                onChange={handleFileChange}
              />
            </FormGroup>

            {/* Display the list of uploaded documents */}
            <ul>
              {currentWorker.referenceDocs &&
                currentWorker.referenceDocs.map((doc, index) => (
                  <li key={index} style={{ marginBottom: "10px" }}>
                    <a
                      href={doc.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ marginRight: "10px" }}
                    >
                      {doc.name}
                    </a>
                    <Button
                      color="danger"
                      size="sm"
                      onClick={() => handleDeleteReferenceDoc(doc.url, index)}
                    >
                      Delete
                    </Button>
                  </li>
                ))}
            </ul>

            <FormGroup>
              <Label for="referenceOtherInfo">Other Information</Label>
              <Input
                type="text"
                name="referenceOtherInfo"
                id="referenceOtherInfo"
                value={currentWorker.referenceOtherInfo}
                onChange={handleInputChange}
              />
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" type="submit">
              {currentWorker.workerID ? "Save" : "Create"}
            </Button>
            <Button color="secondary" onClick={toggleRefModal}>
              Cancel
            </Button>
          </ModalFooter>
        </form>
      </Modal>

      <Modal
        isOpen={isAuditModalOpen}
        toggle={toggleAuditModal}
        backdrop={true}
        size="lg"
      >
        <ModalHeader toggle={toggleAuditModal}>
          {currentWorker.workerID ? "Audit trail" : "Audit trail"} |{" "}
          {currentWorker.firstName} {currentWorker.lastName}
        </ModalHeader>

        <form onSubmit={submitAuditHandler}>
          <ModalBody>
            {/* Search Field */}
            <FormGroup>
              <Label for="searchQuery">Search Activities</Label>
              <Input
                type="text"
                name="searchQuery"
                id="searchQuery"
                placeholder="Search by activity description..."
                value={searchQuery}
                onChange={e => setSearchQuery(e.target.value)}
              />
            </FormGroup>

            {/* Filter by Activity Type */}
            <FormGroup>
              <Label for="filterType">Filter by Type</Label>
              <Input
                type="select"
                name="filterType"
                id="filterType"
                value={filterType}
                onChange={e => setFilterType(e.target.value)}
              >
                <option value="">All Types</option>
                <option value="Worker">Worker</option>
                <option value="Certificate">Certificate</option>
                <option value="Document">Document</option>
                <option value="Emergency">Emergency</option>
                {/* Add other types here as necessary */}
              </Input>
            </FormGroup>

            {/* Sort by Date (Toggle Ascending/Descending) */}
            <Button
              color="primary"
              onClick={() => setSortOrder(sortOrder === "asc" ? "desc" : "asc")}
            >
              Sort by Date ({sortOrder === "asc" ? "Ascending" : "Descending"})
            </Button>

            {/* List of Activities */}
            <ListGroup>
              {filteredAndSortedActivities.map((activity, index) => (
                <ListGroupItem
                  key={index}
                  className="d-flex justify-content-between align-items-center"
                >
                  <span>
                    {truncateText(
                      `${activity.activity} - ${activity.date}`,
                      100
                    )}
                  </span>
                </ListGroupItem>
              ))}
            </ListGroup>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={toggleAuditModal}>
              Close
            </Button>
          </ModalFooter>
        </form>
      </Modal>
      <ActivityDetailModal
        activity={selectedActivity}
        isOpen={modalOpen}
        toggle={toggleModal}
      />
      <Table>
        <thead>
          <tr>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Worker ID</th>
            <th>Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {worker.map(work => (
            <tr key={work.key}>
              <td>{work.firstName}</td>
              <td>{work.lastName}</td>
              <td>{work.key}</td>
              <td>
                <span
                  className={`badge badge-${
                    (work.status && work.status.toLowerCase()) || "new"
                  }`}
                >
                  {work.status || "New"}
                </span>
              </td>
              <td>
                <Dropdown
                  className="custom-dropdown-menu"
                  isOpen={dropdownOpenStates[work.key]}
                  toggle={() => toggleDropdown(work.key)}
                >
                  <DropdownToggle caret>Actions</DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem onClick={() => editProfile(work)}>
                      Profile
                    </DropdownItem>
                    <DropdownItem onClick={() => editDocuments(work)}>
                      Documents
                    </DropdownItem>
                    <DropdownItem onClick={() => editCertificates(work)}>
                      Certificates
                    </DropdownItem>
                    <DropdownItem onClick={() => editEmergency(work)}>
                      Emergency
                    </DropdownItem>
                    <DropdownItem onClick={() => editVaccinations(work)}>
                      Vaccinations
                    </DropdownItem>
                    <DropdownItem onClick={() => editReferences(work)}>
                      References
                    </DropdownItem>
                    <DropdownItem onClick={() => editAudits(work)}>
                      Audits
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  )
}

export default WorkerManager
