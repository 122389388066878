import React, { useState, useEffect } from "react"
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap"
import ApplicationDataService from "services/ApplicationDataService"

const ApplicationWorker = () => {
  const [application, setApplication] = useState([])
  const [currentApplication, setCurrentApplication] = useState({
    createdBy: "",
    createdOn: "",
    status: "",
    applicationID: "",
  })
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [email, setEmail] = useState("");

    useEffect(() => {
      if (localStorage.getItem("authUserDetails")) {
        let userDetails = JSON.parse(localStorage.getItem("authUserDetails"))

        let useremail = userDetails.email

        if (useremail) setEmail(useremail)
      } 
    else 
    {
        console.log("No user details found in localStorage.")
    }
    }, [])

      useEffect(() => {
        const unsubscribe = ApplicationDataService.getAll(data => {
          const filteredJobs = data.filter(job => job.applicantEmail === email)
          setApplication(filteredJobs)
        })
        return () => unsubscribe && unsubscribe()
      }, [email]);

  const toggleModal = () => {
    console.log(isModalOpen)
    setIsModalOpen(!isModalOpen)
    if (isModalOpen) {
      resetForm()
    }
  }

const formatDateForInput = isoDate => {
  if (!isoDate) {
    return ""
  }
  return isoDate.split("T")[0]
}

const formattedDate =
  currentApplication && currentApplication.appliedDate
    ? formatDateForInput(currentApplication.appliedDate)
    : ""


  const handleInputChange = event => {
    const { name, value } = event.target
    setCurrentApplication({ ...currentApplication, [name]: value })
  }

  const submitHandler = event => {
    event.preventDefault()
    const { applicationID, ...applicationData } = currentApplication
    if (applicationID) {
      ApplicationDataService.updateApplication(
        applicationID,
        applicationData
      ).then(() => {
        resetForm()
        toggleModal()
      })
    } else {
      ApplicationDataService.createApplication(applicationData).then(() => {
        resetForm()
        toggleModal()
      })
    }
  }

  const resetForm = () => {
    setCurrentApplication({
      createdBy: "",
      createdOn: "",
      status: "",
      applicationID: "",
    })
  }

  const editApplication = application => {
    setCurrentApplication({
      ...application,
      applicationID: application.key || "",
    })
    toggleModal()
  }

  const deleteApplication = key => {
    ApplicationDataService.deleteApplication(key)
  }

  return (
    <div className="page-content">
      {/* <Button color="primary" onClick={() => toggleModal()}> */}
      <Button style={{ margin: "10px" }} color="primary">
        My Application{application.length !== 1 ? "s" : ""}
      </Button>
      <br />
      <Modal isOpen={isModalOpen} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>
          {currentApplication.applicationID
            ? "Update Application"
            : "Create Application"}
        </ModalHeader>
        <Form onSubmit={submitHandler}>
          <ModalBody>
            <FormGroup>
              <Label for="agreementID">Application ID</Label>
              <Input
                type="text"
                name="applicationID"
                id="applicationID"
                value={currentApplication.applicationID || ""}
                onChange={handleInputChange}
                disabled={true}
              />
            </FormGroup>
            <FormGroup>
              <Label for="applicationName">Care Home</Label>
              <Input
                type="text"
                name="applicationName"
                id="applicationName"
                value={currentApplication.careHome}
                onChange={handleInputChange}
                disabled={true}
              />
            </FormGroup>
            <FormGroup>
              <Label for="applicationName">Client</Label>
              <Input
                type="text"
                name="applicationName"
                id="applicationName"
                value={currentApplication.careHome}
                onChange={handleInputChange}
                disabled={true}
              />
            </FormGroup>

            <FormGroup>
              <Label for="location">Location</Label>
              <Input
                type="text"
                name="location"
                id="location"
                value={currentApplication.location}
                onChange={handleInputChange}
                disabled={true}
              />
            </FormGroup>

            <FormGroup>
              <Label for="appliedDate">Application date</Label>
              <Input
                type="date"
                name="appliedDate"
                id="appliedDate"
                value={formattedDate}
                onChange={handleInputChange}
                disabled={true}
              />
            </FormGroup>

            {/* <FormGroup>
              <Label for="status">Status</Label>
              <Input
                type="text"
                name="status"
                id="status"
                value={currentOrg.status}
                onChange={handleInputChange}
              />
            </FormGroup> */}
          </ModalBody>
          <ModalFooter>
            <Button color="primary" type="submit">
              {currentApplication.applicationID ? "Update" : "Create"}
            </Button>
            <Button color="secondary" onClick={toggleModal}>
              Cancel
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
      <Table>
        <thead>
          <tr>
            <th>Application</th>
            <th>Date of application</th>
            <th>Location</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {application.map(app => (
            <tr key={app.key}>
              <td>{app.careHome}</td>
              <td>{formatDateForInput(app.appliedDate)}</td>
              <td>{app.location}</td>

              {/* Add additional cells for other fields */}
              <td>
                <Button onClick={() => editApplication(app)}>View</Button>
                &nbsp;&nbsp;&nbsp;
                {/* <Button onClick={() => deleteApplication(app.key)}>
                  Delete
                </Button> */}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  )
}

export default ApplicationWorker
