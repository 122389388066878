import React, { useState, useEffect } from 'react';
import {
  Button, Form, FormGroup, Label, Input, Table,
  Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap';
import ApplicationDataService from 'services/ApplicationDataService';

const ApplicationManager = () => {
  const [application, setApplication] = useState([]);
  const [currentApplication, setCurrentApplication] = useState({
      createdBy: '',
      createdOn: '',
      status: '',
      applicationID: ''
  });
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const unsubscribe = ApplicationDataService.getAll((data) => {
      setApplication(data);
    }, [currentApplication]);

    return () => unsubscribe && unsubscribe();
  }, []);

  const toggleModal = () => {
    console.log(isModalOpen);
    setIsModalOpen(!isModalOpen);
    if (isModalOpen) 
    {
     resetForm();
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setCurrentApplication({ ...currentApplication, [name]: value });
  };

  const submitHandler = (event) => {
    event.preventDefault();
    const { applicationID, ...applicationData } = currentApplication;
    if (applicationID) {
     ApplicationDataService.updateApplication(applicationID, applicationData).then(() => {
        resetForm();
        toggleModal();
      });
    } else {
      AgreementDataService.createApplication(applicationData).then(() => {
        resetForm();
        toggleModal();
      });
    }
  };

  const resetForm = () => {
  setCurrentApplication ({
      createdBy: '',
      createdOn: '',
      status: '',
      applicationID: ''
    });
  };


const editApplication = (application) => {
  setCurrentApplication({...application,
    applicationID: application.key || ''
  });
  toggleModal();
};

  const deleteApplication = (key) => {
    ApplicationDataService.deleteApplication(key);
  };

  return (
    <div className="page-content">
      {/* <Button
        color="primary"
        style={{ padding: "10px" }}
        onClick={() => toggleModal()}
      >
        Add New Application
      </Button> */}
      <Modal isOpen={isModalOpen} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>
          {currentApplication.applicationID
            ? "Update Application"
            : "Create Application"}
        </ModalHeader>
        <Form onSubmit={submitHandler}>
          <ModalBody>
            <FormGroup>
              <Label for="agreementID">Application ID</Label>
              <Input
                type="text"
                name="applicationID"
                id="applicationID"
                value={currentApplication.applicationID || ""}
                onChange={handleInputChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="applicationName">Client</Label>
              <Input
                type="text"
                name="applicationName"
                id="applicationName"
                value={currentApplication.careHome}
                onChange={handleInputChange}
              />
            </FormGroup>

            <FormGroup>
              <Label for="createdBy">Created by</Label>
              <Input
                type="text"
                name="createdBy"
                id="createdBy"
                value={
                  currentApplication.user ? currentApplication.user.email : ""
                }
                onChange={handleInputChange}
              />
            </FormGroup>

            <FormGroup>
              <Label for="appliedDate">Application date</Label>
              <Input
                type="date"
                name="appliedDate"
                id="appliedDate"
                value={currentApplication.appliedDate}
                onChange={handleInputChange}
              />
            </FormGroup>

            {/* <FormGroup>
              <Label for="status">Status</Label>
              <Input
                type="text"
                name="status"
                id="status"
                value={currentOrg.status}
                onChange={handleInputChange}
              />
            </FormGroup> */}
          </ModalBody>
          <ModalFooter>
            <Button color="primary" type="submit">
              {currentApplication.applicationID ? "Update" : "Create"}
            </Button>
            <Button color="secondary" onClick={toggleModal}>
              Cancel
            </Button>
          </ModalFooter>
        </Form>
      </Modal>

      <Table>
        <thead>
          <tr>
            <th>Application</th>
            {/* <th>Agreement ID</th> */}
            {/* Add additional headers for other fields */}
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {application.map(app => (
            <tr key={app.key}>
              <td>{app.key}</td>
              {/* <td>{agree.agreementID}</td> */}
              {/* Add additional cells for other fields */}
              <td>
                <Button onClick={() => editApplication(app)}>Edit</Button>
                &nbsp;&nbsp;&nbsp;
                <Button onClick={() => deleteApplication(app.key)}>
                  Delete
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  )
};

export default ApplicationManager;
