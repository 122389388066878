import React, { useState, useEffect } from "react"
import {
  Container,
  Row,
  Col,
  CardBody,
  Card,
  Button,
  Form,
  Label,
  Input,
  FormFeedback,
} from "reactstrap"
import { Link } from "react-router-dom"
import { getAuth, signInWithEmailAndPassword } from "firebase/auth"

const LockScreen = () => {
  document.title = "Lock Screen | Care-Tip - Admin & Dashboard"

  const [name, setname] = useState("")
  const [password, setPassword] = useState("")
  const [passwordError, setPasswordError] = useState("")

  useEffect(() => {
    const authUser = JSON.parse(localStorage.getItem("authUser"))
    // console.log("Auth...:" + JSON.stringify(authUser));

    if (authUser) {
      setname(authUser.email)

      // Optionally, clear all localStorage items except the username
      // Object.keys(localStorage).forEach(key => {
      //   if (key !== "authUser") localStorage.setItem(key, null)
      // })
    }
  }, [])

  // const handleSubmit = e => {
  //   e.preventDefault()
  //   // Here you would validate the password. Placeholder for your validation logic
  //   if (password === "YourPasswordLogicHere") {
  //     // Password is correct
  //     console.log("Password verified. Implement redirect or unlock logic here.")
  //     window.location.href = "/dashboard";
  //   } else {
  //     setPasswordError("Incorrect password. Please try again.")
  //   }
  // }

  const handleSubmit = e => {
    e.preventDefault()

    const auth = getAuth()
    signInWithEmailAndPassword(auth, name, password)
      .then(userCredential => {
        // Signed in
        console.log("User logged in:", userCredential.user)
        // Redirect to dashboard or unlock the screen
        window.location.href = "/dashboard"
      })
      .catch(error => {
        const errorCode = error.code
        const errorMessage = error.message
        // Handle Errors here, such as incorrect password, no user, etc.
        console.log("Email: " + name + " Password: " + password)
        console.error(
          "Error signing in with password and email",
          errorCode,
          errorMessage
        )
        setPasswordError("Incorrect password or email. Please try again.")
      })
  }

  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md="8" lg="6" xl="5">
              <Card className="overflow-hidden">
                {/* Card content goes here */}
                <CardBody className="pt-0">
                  <div>
                    {/* Other content */}
                    <Form className="form-horizontal" onSubmit={handleSubmit}>
                      <div className="user-thumb text-center mb-4">
                        {/* User thumb */}
                        <h5 className="font-size-15 mt-3">{name}</h5>
                      </div>

                      <div className="mb-3">
                        <Label className="form-label">Password</Label>
                        <Input
                          name="password"
                          className="form-control"
                          placeholder="Enter password"
                          type="password"
                          value={password}
                          onChange={e => setPassword(e.target.value)}
                          invalid={!!passwordError}
                        />
                        {passwordError && (
                          <FormFeedback type="invalid">
                            {passwordError}
                          </FormFeedback>
                        )}
                      </div>

                      <div className="text-end">
                        <Button
                          color="primary"
                          className=" w-md "
                          type="submit"
                        >
                          Unlock
                        </Button>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              {/* Footer */}
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}
export default LockScreen
