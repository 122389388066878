// Firebase imports
import { initializeApp } from "firebase/app"
import {
  getDatabase,
  ref,
  push,
  onValue,
  update,
  remove,
  query,
  orderByChild,
  equalTo,
  get,
} from "firebase/database"

// Firebase configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_APIKEY,
  authDomain: process.env.REACT_APP_AUTHDOMAIN,
  databaseURL: process.env.REACT_APP_DATABASEURL,
  projectId: process.env.REACT_APP_PROJECTID,
  storageBucket: process.env.REACT_APP_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_APPID,
  measurementId: process.env.REACT_APP_MEASUREMENTID,
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
const db = getDatabase(app)

class WorkersDataService {
  constructor() {
    this.dbRef = ref(getDatabase(), "/worker")
  }

  // Fetch workers by jobID using Realtime Database
  async getWorkersByJob(jobID) {
    const workersByJobRef = query(
      this.dbRef,
      orderByChild("jobID"),
      equalTo(jobID)
    )

    try {
      const snapshot = await get(workersByJobRef)
      if (snapshot.exists()) {
        const data = snapshot.val()
        // Map the result into an array format
        return Object.keys(data).map(key => ({ id: key, ...data[key] }))
      } else {
        console.log("No workers found for the jobID:", jobID)
        return []
      }
    } catch (error) {
      console.error("Error fetching workers by job:", error)
      throw error
    }
  }

  getWorkersCount(callback) {
    return onValue(this.dbRef, snapshot => {
      const data = snapshot.val()
      const count = data ? Object.keys(data).length : 0
      callback(count)
    })
  }

  getWorkersByTypeAndProvider(jobType, provider, callback) {
    const workersByProviderRef = query(
      this.dbRef,
      orderByChild("provider"),
      equalTo(provider)
    )

    const promise = get(workersByProviderRef)
      .then(snapshot => {
        if (snapshot.exists()) {
          const data = snapshot.val()
          const workers = Object.keys(data)
            .map(key => ({ key, ...data[key] }))
            .filter(worker => worker.jobType === jobType)
          return workers
        } else {
          return []
        }
      })
      .catch(error => {
        console.error("Error fetching workers by type and provider:", error)
        throw error
      })

    if (callback && typeof callback === "function") {
      promise
        .then(workers => callback(workers))
        .catch(error => callback(null, error))
    }

    return promise
  }

  getWorkersByCompanyName(companyName, callback) {
    const workersByCompanyRef = query(
      this.dbRef,
      orderByChild("companyName"),
      equalTo(companyName)
    )

    return get(workersByCompanyRef).then(snapshot => {
      const data = snapshot.val()
      let workers = []
      if (data) {
        workers = Object.keys(data).map(key => ({ key, ...data[key] }))
      }
      if (typeof callback === "function") {
        callback(workers)
      } else {
        console.error("Callback is not a function")
      }
    })
  }

  getAll(callback) {
    const workersByLatestRef = query(this.dbRef, orderByChild("createdAt"))

    return onValue(workersByLatestRef, snapshot => {
      const data = snapshot.val()
      let workers = []

      if (data) {
        workers = Object.keys(data).map(key => ({ key, ...data[key] }))
        workers.sort((a, b) => (b.createdAt || 0) - (a.createdAt || 0))
      }

      if (typeof callback === "function") {
        callback(workers)
      } else {
        console.error("Callback is not a function")
      }
    })
  }

  findWorkerByEmail(email) {
    return this.getAll().then(workers => {
      const foundWorker = workers.find(
        worker => worker.workerEmailAddress === email
      )
      return foundWorker
    })
  }

  createWorker(worker) {
    return push(this.dbRef, worker)
  }

  updateWorker(key, value) {
    const specificWorkerRef = ref(getDatabase(), `/worker/${key}`)
    return update(specificWorkerRef, value)
  }

  deleteWorker(key) {
    const specificWorkerRef = ref(getDatabase(), `/worker/${key}`)
    return remove(specificWorkerRef)
  }
}

export default new WorkersDataService()
