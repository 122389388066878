import React, { useState, useEffect } from "react"
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap"
import BusinessUnitDataService from "services/BusinessUnitDataService"
import OrganisationDataService from "services/OrganisationDataService"
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
import { v4 as uuidv4 } from "uuid"
import "./businessunitmanager.css"

const BusinessUnitManager = () => {
  const [businessunits, setBusinessUnits] = useState([])
  const [organisations, setOrganisations] = useState({})
  const [dropdownOpenStates, setDropdownOpenStates] = useState({})
  const [emailError, setEmailError] = useState("")

  const [currentBU, setCurrentBU] = useState({
    provider: "",
    businessUnit: "",
    businessName: "",
    Status: "",
    businessUnitID: "",
  })

  const [isModalOpen, setIsModalOpen] = useState(false)

  useEffect(() => {
    const unsubscribe = BusinessUnitDataService.getAll(data => {
      setBusinessUnits(data)
    })
    return () => unsubscribe && unsubscribe()
  }, [])

  useEffect(() => {
    const fetchOrganisations = async () => {
      try {
        OrganisationDataService.getAll(orgData => {
          const orgMap = {}
          orgData.forEach(org => {
            orgMap[org.key] = org.businessName
          })
          setOrganisations(orgMap)
        })
      } catch (error) {
        console.error("Error fetching organisations:", error)
      }
    }

    fetchOrganisations()
  }, [])

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen)
    if (isModalOpen) {
      resetForm()
    }
  }

  function validateEmail(email) {
    const re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
    return re.test(String(email).toLowerCase())
  }

  const handleInputChange = event => {
    const { name, value } = event.target

    if (
      name === "businessEmailAddress" ||
      name === "digitalBillingAddress" ||
      name === "digitalccBillingAddress"
    ) {
      if (!validateEmail(value)) {
        setEmailError("Invalid email address")
      } else {
        setEmailError("")
      }
    }

    if (name === "businessUnitID") {
      const fixedPrefix = currentBU.businessUnitID.substring(0, 14)

      if (value.startsWith(fixedPrefix)) {
        setCurrentBU({ ...currentBU, [name]: value })
      }
    } else {
      setCurrentBU({ ...currentBU, [name]: value })
    }
  }

  const submitHandler = async event => {
    event.preventDefault()
    const { businessUnitID, ...organisationData } = currentBU

    try {
      const updatedData = {
        ...organisationData,
      }

      if (businessUnitID) {
        BusinessUnitDataService.updateBusinessUnit(
          businessUnitID,
          updatedData
        ).then(() => {
          resetForm()
          toggleModal()
        })
      } else {
        BusinessUnitDataService.createBusinessUnit(updatedData).then(() => {
          resetForm()
          toggleModal()
        })
      }
    } catch (error) {
      console.log(error)
    }
  }

  const generateBusinessUnitID = () => {
    const year = new Date().getFullYear()
    const uuid = uuidv4()
    const shortUuid = `${uuid.slice(0, 2)}-${uuid.slice(-2)}`
    return `BUN-${year}-${shortUuid}`.toUpperCase()
  }

  useEffect(() => {
    if (!currentBU.businessUnitID) {
      setCurrentBU(prevOrg => ({
        ...prevOrg,
        businessUnitID: generateBusinessUnitID(),
      }))
    }
  }, [currentBU])

  const resetForm = () => {
    setCurrentBU({
      businessUnitID: "",
      provider: "",
      businessUnit: "",
      businessName: "",
      Status: "",
      createdAt: Date.now(),
    })
  }

  const editBusinessUnit = org => {
    setCurrentBU({ ...org, businessUnitID: org.key || "" })
    toggleModal()
  }

  const deleteBusinessUnit = key => {
    BusinessUnitDataService.deleteBusinessUnit(key)
  }

  const toggleDropdown = key => {
    setDropdownOpenStates(prevState => ({
      ...prevState,
      [key]: !prevState[key],
    }))
  }

  return (
    <div className="page-content">
      <Button
        color="primary"
        style={{ margin: "10px" }}
        onClick={() => toggleModal()}
      >
        Add New Business Unit
      </Button>
      <span
        style={{ float: "right" }}
      >{`Displaying ${businessunits.length} business unit(s)`}</span>

      <Modal
        isOpen={isModalOpen}
        toggle={toggleModal}
        size="xl"
        backdrop={true}
      >
        <ModalHeader toggle={toggleModal}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {currentBU.businessUnitID
              ? "Update Business Unit"
              : "Create Business Unit"} | { currentBU.key}
          </div>
        </ModalHeader>
        <Form onSubmit={submitHandler}>
          <ModalBody>
            <div>Fields marked with an asterisk (*) are required.</div>
            <FormGroup>
              <Label for="businessUnitID">
                Business Unit ID<span style={{ color: "red" }}>*</span>
              </Label>
              <Input
                type="text"
                name="businessUnitID"
                id="businessUnitID"
                value={currentBU.businessUnitID || ""}
                onChange={handleInputChange}
                disabled={!currentBU.businessUnitID}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for="provider">
                Provider<span style={{ color: "red" }}>*</span>
              </Label>
              <Input
                type="select"
                name="provider"
                id="provider"
                value={currentBU.provider}
                onChange={handleInputChange}
                required
              >
                <option value="">Select a Provider</option>
                {Object.keys(organisations).map(orgKey => (
                  <option key={orgKey} value={orgKey}>
                    {organisations[orgKey]}
                  </option>
                ))}
              </Input>
            </FormGroup>
            <FormGroup>
              <Label for="businessName">
                Business Name<span style={{ color: "red" }}>*</span>
              </Label>
              <Input
                type="text"
                name="businessName"
                id="businessName"
                value={currentBU.businessName}
                onChange={handleInputChange}
                required
              />
              <Input
                type="text"
                name="businessUnit"
                id="businessUnit"
                value={currentBU.businessName}
                onChange={handleInputChange}
                style={{ display: "none" }}
              />
            </FormGroup>
            <FormGroup>
              <Label for="Status">Status</Label>
              <select
                name="Status"
                id="Status"
                value={currentBU.Status}
                onChange={handleInputChange}
                className="form-control"
              >
                <option value="">Select status</option>
                <option value="New">New</option>
                <option value="Progress">In Progress</option>
                <option value="Approved">Approved</option>
              </select>
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" type="submit">
              {currentBU.businessUnitID ? "Update" : "Create"}
            </Button>
            <Button color="secondary" onClick={toggleModal}>
              Cancel
            </Button>
          </ModalFooter>
        </Form>
      </Modal>

      <Table>
        <thead>
          <tr>
            <th>Business Name</th>
            <th>Provider</th>
            <th>ID</th>
            <th>Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {businessunits.map(org => (
            <tr key={org.key}>
              <td>{org.businessName}</td>
              <td>{organisations[org.provider] || org.provider}</td>{" "}
              {/* Display business name */}
              <td>{org.key}</td>
              <td>
                <span
                  className={`badge badge-${
                    (org.Status && org.Status.toLowerCase()) || "new"
                  }`}
                >
                  {org.Status || "New"}
                </span>
              </td>
              <td style={{ overflow: "hidden" }}>
                <Dropdown
                  className="custom-dropdown-menu"
                  styles={{ position: "relative" }}
                  isOpen={dropdownOpenStates[org.key]}
                  toggle={() => toggleDropdown(org.key)}
                >
                  <DropdownToggle
                    caret
                    styles={{ position: "relative", zIndex: 999999 }}
                  >
                    Actions
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem onClick={() => editBusinessUnit(org)}>
                      Edit Business Unit
                    </DropdownItem>
                    <DropdownItem onClick={() => deleteBusinessUnit(org.key)}>
                      Delete Business Unit
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  )
}

export default BusinessUnitManager
