import firebase from 'firebase/compat/app';

// Add the Firebase products that you want to use
import "firebase/compat/auth";
import "firebase/compat/firestore";
import { useHistory } from 'react-router-dom';

class FirebaseAuthBackend {
  constructor(firebaseConfig) {
    try 
    {
      if (firebaseConfig) {
        //console.log('initializing...')
        // Initialize Firebase
        firebase.initializeApp(firebaseConfig)
        //console.log('initialize done...' + JSON.stringify(firebaseConfig));

        firebase.auth().onAuthStateChanged(user => {
          if (user) {
            localStorage.setItem("authUser", JSON.stringify(user))
          } else {
            //console.log('no user exist')
            localStorage.removeItem("authUser")
            // const navigate = useNavigate();
            //navigate(`/login`);
          }
        })
      }
    } catch (error) {
      console.log(error)
    }
   this.db = firebase.firestore();
  }
  

  /**
   * Registers the user with given details
   */
  registerUser = (email, password) => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .createUserWithEmailAndPassword(email, password)
        .then(
          user => {
            resolve(firebase.auth().currentUser)
          },
          error => {
            reject(this._handleError(error))
          }
        )
    })
  }

  /**
   * Registers the user with given details
   */
  editProfileAPI = (email, password) => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .createUserWithEmailAndPassword(email, password)
        .then(
          user => {
            resolve(firebase.auth().currentUser)
          },
          error => {
            reject(this._handleError(error))
          }
        )
    })
  }

  /**
   * Login user with given details
   */
  loginUser = (email, password) => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .signInWithEmailAndPassword(email, password)
        .then(
          user => {
            resolve(firebase.auth().currentUser)
          },
          error => {
            reject(this._handleError(error))
          }
        )
        .then(() => {
          var user = firebase.auth().currentUser
          if (user) {
            return this.db
              .doc(`users/${user.uid}`)
              .get()
              .then(doc => {
                if (doc.exists) 
                {
                  console.log("doc data: ", doc.data());
                  localStorage.setItem(
                    "authUserDetails",
                    JSON.stringify(doc.data())
                  )

                  return doc.data() // Return the document data
                } 
                else 
                {
                  console.log("Document not found")
                  return null
                }
              })
              .catch(error => {
                console.error("Error getting document:", error)
                return null
              })
          } else {
            console.log("No user is currently signed in.")
            return null
          }
        })
        .then(result => {
          // Handle the result here
          console.log("Result from Firestore: ", result)
        })

    })
  }

  /**
   * forget Password user with given details
   */
  forgetPassword = email => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .sendPasswordResetEmail(email, {
          url:
            window.location.protocol + "//" + window.location.host + "/login",
        })
        .then(() => {
          resolve(true)
        })
        .catch(error => {
          reject(this._handleError(error))
        })
    })
  }

  /**
   * Logout the user
   */
  logout = () => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .signOut()
        .then(() => {
          resolve(true)
        })
        .catch(error => {
          reject(this._handleError(error))
        })
    })
  }

  /**
   * Social Login user with given details
   */

  socialLoginUser = async type => {
    let provider
    if (type === "google") {
      provider = new firebase.auth.GoogleAuthProvider()
    } else if (type === "facebook") {
      provider = new firebase.auth.FacebookAuthProvider()
    }
    try {
      const result = await firebase.auth().signInWithPopup(provider)
      const user = result.user
      return user
    } catch (error) {
      throw this._handleError(error)
    }
  }

  // add more details to the user profile
  addNewUserToFirestore = (uid, userDetails) => {
    const collection = firebase.firestore().collection("users")

    
    const pictureURL = `https://eu.ui-avatars.com/api/?name=${userDetails.firstName}+${userDetails.lastName}&size=250`

    // Prepare the details to be saved in Firestore
    const details = {
      firstName: userDetails.given_name || userDetails.firstName || "Unknown",
      lastName: userDetails.family_name || userDetails.lastName || "Unknown",
      fullName:
        userDetails.fullName ||
        `${userDetails.firstName || ""} ${userDetails.lastName || ""}`.trim(),
      email: userDetails.email || "No Email",
      userType: userDetails.userType || "Worker",
      userCompany: userDetails.userCompany || "Unknown Company",
      picture: userDetails.picture || pictureURL,
      workerID: userDetails.workerID || "",
      address: userDetails.address || "112-116 New Oxford St, London",
      mailReady: userDetails.mailReady || true,
      createdDtm: firebase.firestore.FieldValue.serverTimestamp(),
      lastLoginTime: firebase.firestore.FieldValue.serverTimestamp(),
    }

    // Use the provided uid for the document id
    collection
      .doc(uid)
      .set(details)
      .then(() => console.log("User added/updated successfully"))
      .catch(error => console.error("Error adding/updating user: ", error))

    return { uid, details }
  }
 

  static async getUser(uid) {
    try 
    {
      const userRef = doc(db, "users", uid);
      const docSnap = await getDoc(userRef);

      if (docSnap.exists()) 
      {
        return docSnap.data(); // Returns user data as an object
      } 
      else 
      {
        console.log("No such user!");
        return null // No user found
      }
    } 
    catch (error) 
    {
      console.error("Error getting user:", error)
      throw error // Or handle the error as needed
    }
  }

  setLoggeedInUser = user => {
    localStorage.setItem("authUser", JSON.stringify(user))
  }

  /**
   * Returns the authenticated user
   */
  getAuthenticatedUser = () => {
    if (!localStorage.getItem("authUser")) return null
    return JSON.parse(localStorage.getItem("authUser"))
  }

  /**
   * Handle the error
   * @param {*} error
   */
  _handleError(error) {
    // var errorCode = error.code;
    var errorMessage = error.message
    return errorMessage
  }
}

let _fireBaseBackend = null;

/**
 * Initilize the backend
 * @param {*} config
 */
const initFirebaseBackend = config => {
  try 
  {
  if (!_fireBaseBackend) 
  {
    _fireBaseBackend = new FirebaseAuthBackend(config);
  }
  return _fireBaseBackend;
  } catch (error) {
    console.log("Unable to initialise firebase back end")
  }
};

/**
 * Returns the firebase backend
 */
const getFirebaseBackend = () => 
{
  //console.log("Get firebase backend");
  try 
  {
      // console.log("fbb: " + JSON.stringify(_fireBaseBackend));
      return _fireBaseBackend;
  } 
  catch (error) {
    console.log(error.message);
    return null;
  }
};

export { initFirebaseBackend, getFirebaseBackend };
