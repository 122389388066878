import React from "react";

import { Row, Col, CardBody, Card, Container } from "reactstrap";

import { Link } from "react-router-dom";

// import images
import profile from "assets/images/caretip.jpg";
import logo from "assets/images/caretip.jpg";

const PrivacyChoices = props => {
  //meta title
  document.title = "Privacy Choices | Care-Tip - Privacy Choices";
  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary-subtle">
                  <Row>
                    <Col className="col-7">
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Care-Tip</h5>
                        <p> Privacy Choices</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/" className="logo-light-element">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logo}
                            alt=""
                            className="rounded-circle"
                            height="80"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    <div className="page-content">
                      <div className="container mt-5">
                        <h1>Privacy Choices</h1>
                        <p>Last updated: 18th January 2024</p>

                        <h2>Your Privacy Choices</h2>
                        <p>
                          At Care-Tip, we understand the importance of privacy
                          and give you control over your personal information.
                          Below you can find various privacy settings and
                          choices available to you.
                        </p>

                        <h3>Manage Your Account Information</h3>
                        <p>
                          You can view and update your account information at
                          any time. This includes your personal details, contact
                          information, and professional qualifications.
                        </p>

                        <h3>Control Your Communication Preferences</h3>
                        <p>
                          Choose how you want to receive communications from
                          Care-Tip. You can manage your email, SMS, and push
                          notification preferences.
                        </p>

                        <h3>Data Sharing with Partners</h3>
                        <p>
                          You have control over whether your data is shared with
                          our recruitment partners. Opt-in or opt-out of data
                          sharing for recruitment opportunities.
                        </p>

                        <h3>Cookie Preferences</h3>
                        <p>
                          Customize your cookie settings to control the tracking
                          and data collection on our platform.
                        </p>

                        <h3>Account Deletion</h3>
                        <p>
                          If you decide to stop using Care-Tip, you can request
                          account deletion. This will remove your personal data
                          from our platform in accordance with our data
                          retention policy.
                        </p>

                        <h3>Feedback and Support</h3>
                        <p>
                          Your feedback is important to us. For any questions
                          about your privacy choices or for assistance in
                          managing these settings, please contact us at:
                          <a href="mailto:support@care-tip.com">
                            support@care-tip.com.
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  © {new Date().getFullYear()} Care-Tip. Crafted with{" "}
                  <i className="mdi mdi-heart text-danger" /> by Precious Tech
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
};

export default PrivacyChoices;

