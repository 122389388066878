// activityLogger.js
import ActivityDataService from "./../services/ActivityDataService" // Adjust the path as needed

const logUserActivity = ({ date, time, activity, activityType, worker }) => {
  // Fetch user details from localStorage
  const userDetails = JSON.parse(localStorage.getItem("authUserDetails")) || {}
  const user = userDetails.email || "No email";
  const userType = userDetails.userType || "No userType";
  // const activityType = ""
  // const userDisplayName = userDetails.displayName || "No name";


  // Create an activity object
  const activityLog = {
    date,
    time,
    activity,
    user,
    userType,
    activityType,
    worker
  }

  // Use ActivityDataService to save this activity log
  ActivityDataService.createActivity(activityLog)
    .then(() => {
      console.log("Activity logged successfully")
    })
    .catch(error => {
      console.error("Error logging activity:", error)
    })
}

export default logUserActivity
