import React, { useState, useRef, useEffect } from "react"
import "./invoicepage.css"
import { Label, FormGroup, Input } from "reactstrap"
import FinanceDataService from "./../../services/FinanceDataService" // Update with the correct path
import { jsPDF } from "jspdf"
import html2canvas from "html2canvas"
import { v4 as uuidv4 } from "uuid";

const InvoicePage = () => {
  const invoiceRef = useRef(null)

  const [invoiceHeader, setInvoiceHeader] = useState({
    invoiceID: "",
    fromOrg: "",
    fromOrgAddress: "",
    fromOrgEmail: "",
    toOrg: "",
    toOrgAddress: "",
    toOrgEmail: "",
    toOrgVAT: "",
    invoiceNumber: "",
    invoiceDate: "",
    dueDate: "",
    terms: "",
  })
  const [lineItems, setLineItems] = useState([])
  const [vatRate, setVatRate] = useState(0.2) // Example VAT rate of 20%
  const [showModal, setShowModal] = useState(false)
  const [invoiceData, setInvoiceData] = useState({}) // Placeholder for invoice data
  const [selectedFile, setSelectedFile] = useState(null);

  useEffect(() => {
    // Fetch all invoices when component mounts
    FinanceDataService.getAll(fetchedInvoices => {
      if (fetchedInvoices.length > 0) {
        // For demonstration, set the first invoice in the list
        setInvoiceData(fetchedInvoices[0])
      }
    })
  }, [])

  const handleShowInvoice = () => {
    setShowModal(true)
  }


          const generateJobID = () => {
            const year = new Date().getFullYear()
            const uuid = uuidv4()
            const shortUuid = `${uuid.slice(0, 2)}-${uuid.slice(-2)}`
            return `INV-${year}-${shortUuid}`.toUpperCase()
          }

  const handleHeaderChange = e => {
    setInvoiceHeader({ ...invoiceHeader, [e.target.name]: e.target.value })
  }

  useEffect(() => {
    setInvoiceHeader({ invoiceID: generateJobID() });
  }, [])

  const handleAddLineItem = () => {
    setLineItems([
      ...lineItems,
      {
        agencyName: "",
        activity: "",
        description: "",
        hours: 0,
        nightRate: 0,
        amount: 0,
      },
    ])
  }

  const handleLineItemChange = (index, e) => {
    const updatedItems = [...lineItems];
    const currentItem = updatedItems[index]

    updatedItems[index][e.target.name] = e.target.value;

        if (e.target.name === "hours" || e.target.name === "nightRate") {
          const hours = parseFloat(currentItem.hours) || 0
          const nightRate = parseFloat(currentItem.nightRate) || 0
          currentItem.amount = hours * nightRate
        }

    setLineItems(updatedItems);
  }

  const calculateTotal = () => {
    return lineItems.reduce(
      (total, item) => total + item.hours * item.nightRate,
      0
    )
  }

  const calculateVAT = total => {
    return total * vatRate
  }

  const calculateGrandTotal = (total, vat) => {
    return total + vat
  }

  const LineHeadItem = ({ item, onChange, index, onRemove }) => {
    return (
      <div className="line-item">
        <span></span>
        <h3>Agency Name</h3>

        <h3>Activity</h3>
        <h3>Description</h3>

        <h3>Hours</h3>
        <h3>Night Rate</h3>
        <h3>Amount</h3>
        <h3></h3>
      </div>
    )
  }
  const LineItem = ({ item, onChange, index, onRemove }) => {
    return (
      <div className="line-item">
        <span>{index + 1}.</span>
        <input
          type="text"
          name="agencyName"
          placeholder="Agency Name"
          value={item.agencyName}
          onChange={e => onChange(index, e)}
        />
        <input
          type="text"
          name="activity"
          placeholder="Activity"
          value={item.activity}
          onChange={e => onChange(index, e)}
        />
        <input
          type="text"
          name="description"
          placeholder="Description"
          value={item.description}
          onChange={e => onChange(index, e)}
        />
        <input
          type="number"
          name="hours"
          placeholder="Hours"
          value={item.hours}
          onChange={e => onChange(index, e)}
        />
        <input
          type="number"
          name="nightRate"
          placeholder="Night Rate"
          value={item.nightRate}
          onChange={e => onChange(index, e)}
        />
        <input
          type="number"
          name="amount"
          placeholder="Amount"
          value={item.amount}
          disabled={true}
          onChange={e => onChange(index, e)}
        />
        <button className="removebtn" onClick={() => onRemove(index)}>
          Remove
        </button>
      </div>
    )
  }

  const handleRemoveLineItem = index => {
    const updatedItems = lineItems.filter((_, i) => i !== index)
    setLineItems(updatedItems)
  }

  const downloadPdfDocument = () => {
    html2canvas(invoiceRef.current)
      .then(canvas => {
        const imgData = canvas.toDataURL("image/png")
        const pdf = new jsPDF({
          orientation: "portrait",
          unit: "mm",
          format: "a4",
        })
        const imgWidth = 210
        const imgHeight = (canvas.height * imgWidth) / canvas.width
        pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight)
        pdf.save("download.pdf")
      })
      .catch(error => {
        console.error("Error in html2canvas or jsPDF: ", error)
      })
  }

  const handleFileChange = e => {
    setSelectedFile(e.target.files[0])
  }


  const InvoiceModal = ({ show, onClose, invoiceData }) => {
    const printRef = useRef()

    const handlePrint = () => {
      const content = printRef.current
      const pri = document.getElementById("ifmcontentstoprint").contentWindow
      pri.document.open()
      pri.document.write(content.innerHTML)
      pri.document.close()
      pri.focus()
      pri.print()
    }

    if (!show) {
      return null
    }

    return (
      <>
        <iframe
          id="ifmcontentstoprint"
          style={{ height: 0, width: 0, position: "absolute" }}
        ></iframe>

        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background: "rgba(0, 0, 0, 0.6)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: "9999",
          }}
        >
          <div
            style={{
              background: "white",
              padding: 20,
              borderRadius: 5,
              width: "70%",
              maxHeight: "90%",
              overflowY: "auto",
            }}
          >
            <div style={{ marginTop: 20 }}>
              <button onClick={handlePrint}>Print Invoice</button>
              <button onClick={onClose} style={{ marginLeft: 10 }}>
                Close
              </button>
            </div>
            <div
              ref={printRef}
              style={
                {
                  /* ...content styles... */
                }
              }
            >
              <h2>Invoice Number: {invoiceData.invoiceHeader.invoiceNumber}</h2>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: 20,
                }}
              >
                <div>
                  <h3>From:</h3>
                  <p>{invoiceData.invoiceHeader.fromOrgAddress}</p>
                  <p>Email: {invoiceData.invoiceHeader.fromOrgEmail}</p>
                </div>
                <div>
                  <h3>To:</h3>
                  <p>{invoiceData.invoiceHeader.toOrgAddress}</p>
                  <p>Email: {invoiceData.invoiceHeader.toOrgEmail}</p>
                  <p>VAT: {invoiceData.invoiceHeader.toOrgVAT}</p>
                </div>
             
              </div>

              <table style={{ width: "100%", borderCollapse: "collapse" }}>
                <thead>
                  <tr>
                    <th
                      style={{ border: "1px solid black", textAlign: "left" }}
                    >
                      Agency Name
                    </th>
                    <th
                      style={{ border: "1px solid black", textAlign: "left" }}
                    >
                      Activity
                    </th>
                    <th
                      style={{ border: "1px solid black", textAlign: "left" }}
                    >
                      Description
                    </th>
                    <th
                      style={{ border: "1px solid black", textAlign: "left" }}
                    >
                      Hours
                    </th>
                    <th
                      style={{ border: "1px solid black", textAlign: "left" }}
                    >
                      Rate
                    </th>
                    <th
                      style={{ border: "1px solid black", textAlign: "left" }}
                    >
                      Amount
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {invoiceData.lineItems.map((item, index) => (
                    <tr key={index}>
                      <td style={{ border: "1px solid black" }}>
                        {item.agencyName}
                      </td>
                      <td style={{ border: "1px solid black" }}>
                        {item.activity}
                      </td>
                      <td style={{ border: "1px solid black" }}>
                        {item.description}
                      </td>
                      <td style={{ border: "1px solid black" }}>
                        {item.hours}
                      </td>
                      <td style={{ border: "1px solid black" }}>
                        {item.nightRate}
                      </td>
                      <td style={{ border: "1px solid black" }}>
                        {item.amount}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              <div style={{ marginTop: 20 }}>
                <p>Total: {invoiceData.total}</p>
                <p>VAT: {invoiceData.vat}</p>
                <p>Grand Total: {invoiceData.grandTotal}</p>
              </div>

              <div style={{ marginTop: 20 }}>
                <p>
                  <strong>Invoice Date:</strong>{" "}
                  {invoiceData.invoiceHeader.invoiceDate}
                </p>
                <p>
                  <strong>Due Date:</strong> {invoiceData.invoiceHeader.dueDate}
                </p>
                <p>
                  <strong>Terms:</strong> {invoiceData.invoiceHeader.terms}
                </p>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }

  const validateForm = () => {
if(invoiceHeader)
{
    if (!invoiceHeader.fromOrgAddress.trim()) {
      alert("From Organization Address is required.")
      return false
    }
    if (!invoiceHeader.fromOrgEmail.trim()) {
      alert("From Organization Email is required.")
      return false
    }
    if (!invoiceHeader.toOrgAddress.trim()) {
      alert("To Organization Address is required.")
      return false
    }
    if (!invoiceHeader.toOrgEmail.trim()) {
      alert("To Organization Email is required.")
      return false
    }
    if (!invoiceHeader.invoiceNumber.trim()) {
      alert("Invoice Number is required.")
      return false
    }
    if (!invoiceHeader.invoiceDate.trim()) {
      alert("Invoice Date is required.")
      return false
    }
    if (!invoiceHeader.dueDate.trim()) {
      alert("Due Date is required.")
      return false
    }
  }
    // Add more checks as needed for other fields like toOrgVAT, terms, etc.

    // Ensure there's at least one line item
    if (lineItems.length === 0) {
      alert("At least one line item is required.")
      return false
    }

    return true // If all checks pass, return true
  }

  const validateLineItem = item => {
    // Example: check if hours and night rate are numbers and greater than 0
    if (item.hours <= 0 || item.nightRate <= 0) {
      return false
    }
    return true
  }

  const handleSubmit = async () => {
    if (!validateForm()) {
      return // Stop if header validation fails
    }

    // Validate each line item
    for (let item of lineItems) {
      if (!validateLineItem(item)) {
        Alert.alert("Validation Error", "Invalid line item data.")
        return
      }
    }

    // Proceed with submission...
    const invoiceData = {
      // invoiceHeader.invoiceID,
      invoiceHeader,
      lineItems,
      total: calculateTotal(),
      vat: calculateVAT(calculateTotal()),
      grandTotal: calculateGrandTotal(
        calculateTotal(),
        calculateVAT(calculateTotal())
      ),
    }

    // Use FinanceDataService to create a new finance record
        try {
          const financeRefKey = await FinanceDataService.createFinance(invoiceData)

          // Upload file if selected
          if (selectedFile) {
            await FinanceDataService.uploadFile(financeRefKey, selectedFile)
            // Optionally, handle the display of the file URL
          }

          alert("Invoice submitted successfully!")
        } catch (error) {
          alert("Error submitting invoice: " + error.message)
        }
  }

  // ... JSX for rendering the form ...

  return (
    <div className="page-content">
      <h2>Create Invoice</h2>
      <label>
        Attach File:
        <input type="file" onChange={handleFileChange} />
      </label>
      {/* Invoice header form */}
      <span style={{ float: "right" }}>
        <label
          name="invoiceID"
          value={invoiceHeader.invoiceID}
          onChange={handleHeaderChange}
        />
        {invoiceHeader.invoiceID}
      </span>
      {/* <button onClick={handleShowInvoice}>Display Invoice</button> */}
      <InvoiceModal
        show={showModal}
        onClose={() => setShowModal(false)}
        invoiceData={invoiceData}
      />
      <form>
        <div className="form-group">
          <div className="xinput-group">
            <label>FROM:</label>
            <label>
              Organisation:
              <Input
                type="text"
                name="fromOrg"
                value={invoiceHeader.fromOrg}
                onChange={handleHeaderChange}
              />
            </label>
            <label>
              Organisation Address:
              <Input
                type="text"
                name="fromOrgAddress"
                value={invoiceHeader.fromOrgAddress}
                onChange={handleHeaderChange}
              />
            </label>
            <label>
              Organisation Email:
              <Input
                type="email"
                name="fromOrgEmail"
                value={invoiceHeader.fromOrgEmail}
                onChange={handleHeaderChange}
              />
            </label>
          </div>
          <div className="xinput-group">
            <label>TO:</label>
            <label>
              Organisation:
              <Input
                type="text"
                name="toOrg"
                value={invoiceHeader.toOrg}
                onChange={handleHeaderChange}
              />
            </label>
            <label>
              Organisation Address:
              <Input
                type="text"
                name="toOrgAddress"
                value={invoiceHeader.toOrgAddress}
                onChange={handleHeaderChange}
              />
            </label>
            <label>
              Organisation Email:
              <Input
                type="email"
                name="toOrgEmail"
                value={invoiceHeader.toOrgEmail}
                onChange={handleHeaderChange}
              />
            </label>
            <label>
              VAT:
              <Input
                type="text"
                name="toOrgVAT"
                value={invoiceHeader.toOrgVAT}
                onChange={handleHeaderChange}
              />
            </label>
          </div>
        </div>

        <div className="input-group">
          <label>
            Invoice Number:
            <Input
              type="text"
              name="invoiceNumber"
              value={invoiceHeader.invoiceNumber}
              onChange={handleHeaderChange}
            />
          </label>
          <label>
            Date of Invoice:
            <Input
              type="date"
              name="invoiceDate"
              value={invoiceHeader.invoiceDate}
              onChange={handleHeaderChange}
            />
          </label>
          <label>
            Due Date of Invoice:
            <Input
              type="date"
              name="dueDate"
              value={invoiceHeader.dueDate}
              onChange={handleHeaderChange}
            />
          </label>
          <label>
            <div>Terms of Receipt:</div>
            <textarea
              name="terms"
              style={{ width: "100%" }}
              value={invoiceHeader.terms}
              onChange={handleHeaderChange}
            />
          </label>
        </div>
      </form>
      {/* ... Form inputs for invoiceHeader fields ... */}
      {/* Line items */}
      <div>
        <h2 style={{ color: "blue" }}>Invoice Items</h2>
        {<LineHeadItem />}
        {lineItems.map((item, index) => (
          <>
            <LineItem
              key={index}
              item={item}
              index={index}
              onChange={handleLineItemChange}
              onRemove={handleRemoveLineItem}
            />
          </>
        ))}
        <button onClick={handleAddLineItem}>Add Line Item</button>
      </div>
      {/* Display totals */}
      {lineItems && lineItems.length > 0 && (
        <div className="totals">
          <span></span> {/* Empty span for spacing */}
          <span>
            <h3>Total: £{calculateTotal().toFixed(2)}</h3>
          </span>
          <span>
            <h3>VAT: £{calculateVAT(calculateTotal()).toFixed(2)}</h3>
          </span>
          <span></span> {/* Empty span for spacing */}
          <span></span> {/* Empty span for spacing */}
          <span></span> {/* Empty span for spacing */}
          <span>
            <h2>
              Grand Total: £
              {calculateGrandTotal(
                calculateTotal(),
                calculateVAT(calculateTotal())
              ).toFixed(2)}
            </h2>
          </span>
        </div>
      )}
      <button onClick={handleSubmit}>Submit Invoice</button>
      <div>
        <div ref={invoiceRef}>{/* Your invoice content here */}</div>
        {/* <button onClick={downloadPdfDocument}>Download Invoice as PDF</button> */}
      </div>{" "}
    </div>
  )
}

export default InvoicePage
