import { getDatabase, ref, get, set, remove, update } from "firebase/database" // Updated to include 'update'

const db = getDatabase()

const sanitizeKey = key => {
  return key.replace(/[.#$/[\]]/g, "_")
}

const WorkersAllocationService = {
  getAllocationsByCategory(jobId, category) {

    console.log("Getting Allocations" + JSON.stringify(jobId) + " by category " + category);

    const categoryRef = ref(db, `/WorkersEligibility/${jobId}/${category}`);
    
    console.log("Getting category ref: " + JSON.stringify(categoryRef));
    
    return get(categoryRef).then(snapshot => {
      const data = snapshot.val()
      console.log("Fetched data for jobId:", jobId, "category:", category, data)
      return data ? Object.keys(data).map(key => ({ key, ...data[key] })) : []
    })
  },

  addWorkerToCategory(jobId, category, worker) {
    const sanitizedKey = sanitizeKey(worker.key)
    console.log("Sanitized key: " + JSON.stringify(sanitizedKey));

    const categoryRef = ref(
      db,
      `/WorkersEligibility/${jobId}/${category}/${sanitizedKey}`
    )
        
    console.log(`Adding worker ${worker} to category ${categoryRef}`)

    return set(categoryRef, worker)
      .then(() => {
        console.log(`Worker ${worker.key} added to category ${category}`)
      })
      .catch(error => {
        console.error(`Error adding worker to category: ${category}`, error)
        throw error
      })
  },

  removeWorkerFromCategory(jobId, category, workerKey) {
    const sanitizedKey = sanitizeKey(workerKey)
    const categoryRef = ref(
      db,
      `/WorkersEligibility/${jobId}/${category}/${sanitizedKey}`
    )
    return remove(categoryRef)
      .then(() => {
        console.log(`Worker ${workerKey} removed from category ${category}`)
      })
      .catch(error => {
        console.error(`Error removing worker from category: ${category}`, error)
        throw error
      })
  },

  addEligibleWorkers(jobId, workers) {
    const updates = {}
    console.log(`Adding the job with id ${jobId}`);

    workers.forEach(worker => {
      const sanitizedKey = sanitizeKey(worker.key)
      updates[`/WorkersEligibility/${jobId}/eligible/${sanitizedKey}`] = worker
    })
    return update(ref(db), updates)
      .then(() => {
        console.log(`Workers added to eligible category for job ${jobId}`)
      })
      .catch(error => {
        console.error(`Error adding eligible workers for job ${jobId}`, error)
        throw error
      })
  },
}

export default WorkersAllocationService
