import React from "react";

import { Row, Col, CardBody, Card, Container } from "reactstrap";

import { Link } from "react-router-dom";

// import images
import profile from "assets/images/caretip.jpg";
import logo from "assets/images/caretip.jpg";

const PrivacyPolicy = props => {
  //meta title
  document.title = "Privacy Policy | Care-Tip - Privacy Policy";
  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary-subtle">
                  <Row>
                    <Col className="col-7">
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Care-Tip</h5>
                        <p> Privacy Policy</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/" className="logo-light-element">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logo}
                            alt=""
                            className="rounded-circle"
                            height="80"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    <div className="container mt-5">
                      <h1>Privacy Policy</h1>
                      <img src="" />
                      <p>Last updated: 18th January 2024</p>

                      <h2>Introduction</h2>
                      <p>
                        Welcome to Care-Tip. This Privacy Policy explains how we
                        collect, use, disclose, and safeguard your information
                        when you use our recruitment platform. Please read this
                        privacy policy carefully. If you do not agree with the
                        terms of this privacy policy, please do not access the
                        platform.
                      </p>

                      <h2>Collection of Your Information</h2>
                      <p>
                        We may collect information about you in various ways.
                        The information we may collect via the Platform
                        includes:
                      </p>
                      <ul>
                        <li>
                          <strong>Personal Data</strong>: Personally
                          identifiable information, such as your name, email
                          address, and phone number.
                        </li>
                        <li>
                          <strong>Derivative Data</strong>: Information our
                          servers automatically collect when you access the
                          Platform, such as your IP address.
                        </li>
                      </ul>

                      <h2>Use of Your Information</h2>
                      <p>We use collected information via the Platform to:</p>
                      <ul>
                        <li>Create and manage your account.</li>
                        <li>
                          Offer new products, services, and/or recommendations
                          to you.
                        </li>
                        <li>
                          Improve the functionality of our recruitment services.
                        </li>
                      </ul>

                      <h2>Disclosure of Your Information</h2>
                      <p>
                        We may share information we have collected about you in
                        certain situations. Your information may be disclosed as
                        follows:
                      </p>
                      <ul>
                        <li>
                          <strong>By Law or to Protect Rights</strong>: If we
                          believe the release of information about you is
                          necessary to respond to legal process, to investigate
                          or remedy potential violations of our policies, or to
                          protect the rights, property, and safety of others.
                        </li>
                        <li>
                          <strong>Third-Party Service Providers</strong>: We may
                          share your information with third parties that perform
                          services for us or on our behalf.
                        </li>
                      </ul>

                      <h2>Security of Your Information</h2>
                      <p>
                        We use administrative, technical, and physical security
                        measures to help protect your personal information.
                        While we have taken reasonable steps to secure the
                        personal information you provide to us, please be aware
                        that despite our efforts, no security measures are
                        perfect or impenetrable.
                      </p>

                      <h2>Contact Us</h2>
                      <p>
                        If you have questions or comments about this Privacy
                        Policy, please contact us at:
                        <a href="mailto:support@care-tip.com">
                          support@care-tip.com.
                        </a>
                      </p>
                    </div>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  © {new Date().getFullYear()} Care-Tip. Crafted with{" "}
                  <i className="mdi mdi-heart text-danger" /> by Precious Tech
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
};

export default PrivacyPolicy;

