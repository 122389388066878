import React, { useState, useEffect } from "react"
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap"
import FinanceDataService from "services/FinanceDataService"
import { v4 as uuidv4 } from "uuid";
import { Link, useNavigate } from "react-router-dom"

const FinanceAgent = () => {
  const [finance, setFinance] = useState([])
  const [currentFinance, setCurrentFinance] = useState([])
  ;({
    invoiceNo: "",
    invoiceType: "",
    providerCareHome: "",
    invoiceDate: "",
    dueDate: "",
    amount: "",
    totalPending: "",
    totalPaid: "",
    approvedBy: "",
    approvedOn: "",
    status: "",
    financeID: "",
  })
  const [isModalOpen, setIsModalOpen] = useState(false);


          const generatejobID = () => {
            const year = new Date().getFullYear()
            const uuid = uuidv4()
            const shortUuid = `${uuid.slice(0, 2)}-${uuid.slice(-2)}`
            return `INV-${year}-${shortUuid}`.toUpperCase()
          }

  useEffect(() => {
    const unsubscribe = FinanceDataService.getAll(
      data => {
        setFinance(data)
      },
      [currentFinance]
    )

    return () => unsubscribe && unsubscribe()
  }, [])

  const toggleModal = () => {
    console.log(isModalOpen)
    setIsModalOpen(!isModalOpen)
    if (isModalOpen) {
      resetForm()
    }
  }

  // const handleInputChange = event => {
  //   const { name, value } = event.target
  //   setCurrentFinance({ ...currentFinance, [name]: value })
  // }

    const handleInputChange = event => {
      const { name, value } = event.target

      if (name === "financeID") {
        const fixedPrefix = currentFinance.financeID.substring(0, 14)

        if (value.startsWith(fixedPrefix)) {
          setCurrentFinance({ ...currentFinance, [name]: value })
        } else {
          // For all other fields
          setCurrentFinance({ ...currentFinance, [name]: value })
        }
      }
    }

  

  const submitHandler = event => {
    event.preventDefault()
    const { financeID, ...financeData } = currentFinance
    if (financeID) {
      FinanceDataService.updateFinance(financeID, financeData).then(() => {
        resetForm()
        toggleModal()
      })
    } else {
      FinanceDataService.createFinance(financeData).then(() => {
        resetForm()
        toggleModal()
      })
    }
  }

  const resetForm = () => {
    setCurrentFinance({
      invoiceNo: "",
      invoiceType: "",
      providerCareHome: "",
      invoiceDate: "",
      dueDate: "",
      amount: "",
      totalPending: "",
      totalPaid: "",
      approvedBy: "",
      approvedOn: "",
      status: "",
      financeID: "",
    })
  }

  const editFinance = finance => {
    setCurrentFinance({ ...finance, financeID: finance.key || "" })
    toggleModal()
  }

  const deleteFinance = key => {
    FinanceDataService.deleteFinance(key)
  }

  return (
    <div className="page-content">
      <Button
        color="primary"
        style={{ margin: "10px" }}
        onClick={() => toggleModal()}
      >
        Add New Invoice
      </Button>
      <Modal isOpen={isModalOpen} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>
          {currentFinance.financeID ? "Update Finance" : "Create Finance"}
        </ModalHeader>
        <Form onSubmit={submitHandler}>
          <ModalBody>
            <FormGroup>
              <Label for="financeID">Finance ID</Label>
              <Input
                type="text"
                name="financeID"
                id="financeID"
                value={currentFinance.financeID || ""}
                onChange={handleInputChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="invoiceNo">Invoice No</Label>
              <Input
                type="text"
                name="invoiceNo"
                id="invoiceNo"
                value={currentFinance.invoiceNo}
                onChange={handleInputChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="invoiceType">Invoice type</Label>
              <Input
                type="text"
                name="invoiceType"
                id="invoiceType"
                value={currentFinance.invoiceType}
                onChange={handleInputChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="providerCareHome">Provider Care Home</Label>
              <Input
                type="text"
                name="providerCareHome"
                id="providerCareHome"
                value={currentFinance.providerCareHome}
                onChange={handleInputChange}
              />
            </FormGroup>

            <FormGroup>
              <Label for="invoiceDate">Invoice Date</Label>
              <Input
                type="date"
                name="invoiceDate"
                id="invoiceDate"
                value={currentFinance.invoiceDate}
                onChange={handleInputChange}
              />
            </FormGroup>

            <FormGroup>
              <Label for="dueDate">Due date</Label>
              <Input
                type="date"
                name="dueDate"
                id="dueDate"
                value={currentFinance.dueDate}
                onChange={handleInputChange}
              />
            </FormGroup>

            <FormGroup>
              <Label for="amount">Amount</Label>
              <Input
                type="number"
                name="amount"
                id="amount"
                value={currentFinance.amount}
                onChange={handleInputChange}
              />
            </FormGroup>

            <FormGroup>
              <Label for="totalPending">Total pending</Label>
              <Input
                type="number"
                name="totalPending"
                id="totalPending"
                value={currentFinance.totalPending}
                onChange={handleInputChange}
              />
            </FormGroup>

            <FormGroup>
              <Label for="totalPaid">Total paid</Label>
              <Input
                type="number"
                name="totalPaid"
                id="totalPaid"
                value={currentFinance.totalPaid}
                onChange={handleInputChange}
              />
            </FormGroup>

            <FormGroup>
              <Label for="approvedBy">Approved by</Label>
              <Input
                type="text"
                name="approvedBy"
                id="approvedBy"
                value={currentFinance.approvedBy}
                onChange={handleInputChange}
              />
            </FormGroup>

            <FormGroup>
              <Label for="approvedOn">Approved on</Label>
              <Input
                type="date"
                name="approvedOn"
                id="approvedOn"
                value={currentFinance.approvedOn}
                onChange={handleInputChange}
              />
            </FormGroup>

            {/* <FormGroup>
              <Label for="status">Status</Label>
              <Input
                type="text"
                name="status"
                id="status"
                value={currentOrg.status}
                onChange={handleInputChange}
              />
            </FormGroup> */}
          </ModalBody>
          <ModalFooter>
            <Button color="primary" type="submit">
              {currentFinance.financeID ? "Update" : "Create"}
            </Button>
            <Button color="secondary" onClick={toggleModal}>
              Cancel
            </Button>
          </ModalFooter>
        </Form>
      </Modal>

      <Table>
        <thead>
          <tr>
            <th>Invoice number</th>
            <th>Provider care home</th>
            {/* Add additional headers for other fields */}
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {finance.map(fin => (
            <tr key={fin.key}>
              <td>{fin.invoiceNo}</td>
              <td>{fin.providerCareHome}</td>
              {/* Add additional cells for other fields */}
              <td>
                <Button onClick={() => editFinance(fin)}>Edit</Button>
                &nbsp;&nbsp;&nbsp;
                <Button onClick={() => deleteFinance(fin.key)}>Delete</Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  )
}

export default FinanceAgent
