import React, { useState, useEffect } from "react"
import { Card, CardBody, CardTitle } from "reactstrap"
import { Link } from "react-router-dom"
import ActivityDataService from "./../../services/ActivityDataService"; 

const ActivityComp = () => {
  const [activityData, setActivityData] = useState([])

  useEffect(() => {
    const fetchActivityData = () => {
      ActivityDataService.getAll(fetchedData => {
        setActivityData(fetchedData)
      })
    }

    fetchActivityData()
  }, [])

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <CardTitle className="mb-5">Activity</CardTitle>
          <ul className="verti-timeline list-unstyled">
            {activityData.map((item, index) => (
              <li className="event-list" key={index}>
                <div className="event-timeline-dot">
                  <i className="bx bx-right-arrow-circle font-size-18" />
                </div>
                <div className="flex-shrink-0 d-flex">
                  <div className="me-3">
                    <h5 className="font-size-14">
                      {item.date}{" "}
                      <i className="bx bx-right-arrow-alt font-size-16 text-primary align-middle ms-2" />
                    </h5>
                  </div>
                  <div className="flex-grow-1">
                    <div>{item.activity}</div>
                    <div>
                      User: {item.user} ({item.userType})
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
          <div className="text-center mt-4">
            <Link
              to="/Activity"
              className="btn btn-primary waves-effect waves-light btn-sm"
            >
              View More <i className="mdi mdi-arrow-right ms-1" />
            </Link>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default ActivityComp
