import React, { useState } from "react"
import {
  FormGroup,
  Label,
  Input
} from "reactstrap"

import { Row, Col, CardBody, Card, Container } from "reactstrap";

import { Link } from "react-router-dom";

// import images
import profile from "assets/images/caretip.jpg";
import logo from "assets/images/caretip.jpg";

const UnsubscribePage = props => {
  //meta title
  document.title = "Opt out | Care-Tip - Dashboard";

    const [email, setEmail] = useState("");
  const [result, setResult] = useState("");

  const theemail = {
    from: "admin@care-tip.com",
    to: "delakti@icloud.com",
    subject: "Test email",
    text: "This is a test email sent from the browser",
  }
 
  const handleSubmit = async event => {
    event.preventDefault()
    // Implement the API call to your backend here.
    // For example: axios.post('/api/unsubscribe', { email })
    try 
    {
      setResult("Your request for unsubscription and deletion was submitted successfully.")
    console.log("Submitted request for: ", email)
    //  smtp
    //    .sendMail(theemail)
    //    .then(info => console.log(info))
    //    .catch(err => console.error(err))
    } 
    catch (error) 
    {
        console.log("Mail sending error: " + error);
    }
  
    // Handle response and give feedback to user (e.g., success or error message)

  }
  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary-subtle">
                  <Row>
                    <Col className="col-7">
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Care-Tip</h5>
                        <p> Opt Out</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/" className="logo-light-element">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logo}
                            alt=""
                            className="rounded-circle"
                            height="80"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    <div className="page-content">
                       <h1>Unsubscribe and Data Deletion Request</h1>
      <p>{result}</p>
      <form onSubmit={handleSubmit}>
        <FormGroup>
          <Label for="email">
            Email<span style={{ color: "red" }}>*</span>
          </Label>
          <Input
            type="text"
            name="email"
            id="email"
            style={{ width: "250px" }}
            value={email}
            onChange={e => setEmail(e.target.value)}
            required />
        </FormGroup>

        <p></p>
        <button type="submit">Submit Request</button>
      </form>
                    </div>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  © {new Date().getFullYear()} Care-Tip. Crafted with{" "}
                  <i className="mdi mdi-heart text-danger" /> by Precious Tech
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
};

export default UnsubscribePage;


