import React, { useState, useEffect } from "react"
import { debounce } from "lodash"
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap"
import {
  setKey,
  setDefaults,
  setLanguage,
  setRegion,
  fromAddress,
  fromLatLng,
  fromPlaceId,
  setLocationType,
  geocode,
  RequestType,
} from "react-geocode"
import JobDataService from "services/JobDataService"
import emailjs from "@emailjs/browser"
import { v4 as uuidv4 } from "uuid"
import { getFirestore, collection, getDocs } from "firebase/firestore"
import { useNavigate } from "react-router-dom";


const UsersTable = () => {
  const [users, setUsers] = useState([])
  const [modal, setModal] = useState(false)
  const [selectedUser, setSelectedUser] = useState(null)

  const toggleModal = () => setModal(!modal)

  const showUserDetail = user => {
    setSelectedUser(user)
    toggleModal()
  }

  const navigate = useNavigate()

  const createUser = () => {
    navigate(`/clientregister`)
  }

  useEffect(() => {
    // Define an async function inside the effect
    async function fetchData() {
      try {
          const db = getFirestore()
          const usersRef = collection(db, "users")
          const querySnapshot = await getDocs(usersRef)
          const fetchedUsers = querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
          }))

          setUsers(fetchedUsers)
        
      } catch (error) {
        console.log(error);
      }
    }
    fetchData()
  }, []) // Dependencies for useEffect

 

  return (
    <div className="page-content">
     <p>
      <Button onClick={() => createUser()}>Create User</Button>
     </p>
      <Table>
        <thead>
          <tr>
            <th>Full Name</th>
            <th>Email</th>
            <th>User Type</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {users?.map(user => (
            <tr key={user.id}>
              <td>{user.fullName}</td>
              <td>{user.email}</td>
              <td>{user.userType}</td>
              <td>
                <Button color="primary" onClick={() => showUserDetail(user)}>
                  View Details
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      {selectedUser && (
        <Modal isOpen={modal} toggle={toggleModal}>
          <ModalHeader toggle={toggleModal}>
            {selectedUser.fullName}
          </ModalHeader>
          <ModalBody>
            <p>
              <strong>Email:</strong> {selectedUser.email}
            </p>
            <p>
              <strong>Address:</strong> {selectedUser.address}
            </p>
            <p>
              <strong>User Type:</strong> {selectedUser.userType}
            </p>
            <p>
              <strong>Mail Ready:</strong> {selectedUser.mailReady}
            </p>
            <p>
              <strong>User Company:</strong> {selectedUser.userCompany}
            </p>
            <p>
              <strong>Worker ID:</strong> {selectedUser.workerID}
            </p>
            <p>
              <strong>Last Login Time:</strong>{" "}
              {new Date(
                selectedUser.lastLoginTime.seconds * 1000
              ).toLocaleString()}
            </p>
            {/* Add more user details here */}
          </ModalBody>
        </Modal>
      )}
    </div>
  )
}

export default UsersTable
