// Firebase imports
import { initializeApp } from 'firebase/app';
import { getDatabase, ref, push, onValue, update, remove } from 'firebase/database';
import {
  getFirestore,
  collection,
  addDoc,
  getDocs, // make sure to import getDocs
  updateDoc,
  doc,
  deleteDoc,
} from "firebase/firestore";
import {
  setKey,
  setDefaults,
  setLanguage,
  setRegion,
  fromAddress,
  fromLatLng,
  fromPlaceId,
  setLocationType,
  geocode,
  RequestType,
} from "react-geocode";
import emailjs from "@emailjs/browser";

// Firebase configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_APIKEY,
  authDomain: process.env.REACT_APP_AUTHDOMAIN,
  databaseURL: process.env.REACT_APP_DATABASEURL,
  projectId: process.env.REACT_APP_PROJECTID,
  storageBucket: process.env.REACT_APP_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_APPID,
  measurementId: process.env.REACT_APP_MEASUREMENTID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getDatabase(app);
setKey("AIzaSyC7RdiBANe6MImEXQLN7dSU5WrXKwz-wgs");

class UserDetailsService {
  constructor() {
    this.dbRef = ref(getDatabase());
  }

  calculateDistance(lat1, lon1, lat2, lon2) {
    // Haversine formula
    // Returns distance in miles
    const R = 3958.8 // Radius of the Earth in miles
    const radLat1 = (lat1 * Math.PI) / 180
    const radLat2 = (lat2 * Math.PI) / 180
    const deltaLat = radLat2 - radLat1
    const deltaLon = ((lon2 - lon1) * Math.PI) / 180

    const a =
      Math.sin(deltaLat / 2) * Math.sin(deltaLat / 2) +
      Math.cos(radLat1) *
        Math.cos(radLat2) *
        Math.sin(deltaLon / 2) *
        Math.sin(deltaLon / 2)
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
    return R * c // Distance in miles
  }

  

  async retrieveUsers(jobAddress) {
    const jobLocation = await fromAddress(jobAddress)
    console.log("Loc: " + JSON.stringify(jobLocation));
    const { lat: jobLat, lng: jobLng } =
      jobLocation.results[0].geometry.location

    const usersRef = ref(db, "users/")
    onValue(usersRef, async snapshot => {
      const users = snapshot.val()
      for (const userId in users) {
        const user = users[userId]
        const userLocation = await fromAddress(user.address)
        const { lat: userLat, lng: userLng } =
          userLocation.results[0].geometry.location
    
          console.log(
            "Loc: " + JSON.stringify(userLocation.results[0].geometry.location)
          )

        const distance = this.calculateDistance(
          jobLat,
          jobLng,
          userLat,
          userLng
        )
        if (distance <= 50) {
          try 
          {
            console.log("Distance: " + distance + " user " + user);
            // sendAcknowledgementEmail(
            //   user.email,
            //   user.firstName,
            //   user.lastName,
            //   "Job Title",
            //   "Job Description",
            //   jobAddress
            // )
          } catch (error) {
            console.log("Error sending email to " + user.email + ": " + error)
          }
        }
      }
    })
  }

  createUser(userData) {
    const newUserRef = ref(db, "users/")
    push(newUserRef, userData)
  }

  myuser(userData) {
    console.log("yesso:")
  }

  retrieveUsers(callback) {
    const usersRef = ref(db, "users/")
    console.log(JSON.stringify(usersRef))

    onValue(usersRef, snapshot => {
      const data = snapshot.val()
      console.log("thedata:" + data)
      callback(data)
    })
  }

  updateUser(userId, updatedData) {
    const userRef = ref(db, `users/${userId}`)
    update(userRef, updatedData)
  }

  deleteUser(userId) {
    const userRef = ref(db, `users/${userId}`)
    remove(userRef)
  }
}


export default UserDetailsService