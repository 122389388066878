import React, { useState, useEffect } from "react"
import JobDataService from "services/JobDataService"
import TimesheetDataService from "services/TimesheetDataService"
import { Popup } from "devextreme-react/popup"
import { Button } from "devextreme-react/button"
import {
  Form,
  SimpleItem,
  RequiredRule,
  ButtonItem,
} from "devextreme-react/form"
import FileUploader from "devextreme-react/file-uploader"
import "devextreme/dist/css/dx.light.css"
import {
  getStorage,
  ref as storageRef,
  uploadBytes,
  getDownloadURL,
} from "firebase/storage"

const CompletedJobsByTimesheetStatus = () => {
  const [email, setEmail] = useState("")
  const [jobs, setJobs] = useState({})
  const [selectedJob, setSelectedJob] = useState(null)
  const [showPopup, setShowPopup] = useState(false)
  const [showTimesheetPopup, setShowTimesheetPopup] = useState(false)
  const [timesheetFile, setTimesheetFile] = useState(null)
  const [timesheetData, setTimesheetData] = useState({
    date: null,
    startTime: null,
    endTime: null,
    breakTime: null,
  })
  const [timesheetDetails, setTimesheetDetails] = useState(null)

  useEffect(() => {
    if (localStorage.getItem("authUserDetails")) {
      let userDetails = JSON.parse(localStorage.getItem("authUserDetails"))
      let useremail = userDetails.email
      if (useremail) setEmail(useremail)
    } else {
      console.log("No user details found in localStorage.")
    }
  }, [])

  useEffect(() => {
    if (email) {
      JobDataService.getJobsByEmailAndStatus(
        email,
        "completed",
        fetchedJobs => {
          const categorizedJobs = fetchedJobs.reduce((acc, job) => {
            const status = job.timesheetStatus || "Unknown"
            if (!acc[status]) {
              acc[status] = []
            }
            acc[status].push(job)
            return acc
          }, {})
          setJobs(categorizedJobs)
        }
      )
    }
  }, [email])

  const handleShowPopup = job => {
    setSelectedJob(job)
    setShowPopup(true)
  }

  const handleShowTimesheetPopup = async job => {
    const timesheet = await TimesheetDataService.getTimesheetByJobId(job.key)
    setTimesheetDetails(timesheet)
    setShowTimesheetPopup(true)
  }

  const handleClosePopup = () => {
    setShowPopup(false)
    setSelectedJob(null)
    setTimesheetFile(null)
    setTimesheetData({
      date: null,
      startTime: null,
      endTime: null,
      breakTime: null,
    })
  }

  const handleCloseTimesheetPopup = () => {
    setShowTimesheetPopup(false)
    setTimesheetDetails(null)
  }

  const handleFileChange = e => {
    setTimesheetFile(e.value[0])
  }

  const handleTimesheetDataChange = e => {
    setTimesheetData({
      ...timesheetData,
      [e.dataField]: e.value,
    })
  }

  const uploadFileToStorage = async file => {
    const storage = getStorage()
    const fileRef = storageRef(storage, `timesheets/${file.name}`)
    await uploadBytes(fileRef, file)
    return await getDownloadURL(fileRef)
  }

  const handleSubmit = async status => {
    if (!selectedJob) return

    let fileUrl = selectedJob.timesheetFileUrl || ""
    if (timesheetFile) {
      try {
        fileUrl = await uploadFileToStorage(timesheetFile)
      } catch (error) {
        console.error("File upload failed:", error)
        return
      }
    }

    const timesheetEntry = {
      jobId: selectedJob.key,
      jobTitle: selectedJob.jobTitle,
      jobDescription: selectedJob.jobDescription,
      location: selectedJob.location,
      workerEmail: email,
      timesheetStatus: status,
      timesheetFileUrl: fileUrl,
      date: timesheetData.date,
      startTime: timesheetData.startTime,
      endTime: timesheetData.endTime,
      breakTime: timesheetData.breakTime,
    }

    await TimesheetDataService.createTimesheet(timesheetEntry)

    const updatedJob = { ...selectedJob, timesheetStatus: status }
    await JobDataService.updateJob(selectedJob.key, updatedJob)

    handleClosePopup()
    window.location.reload()
  }

  return (
    <div className="page-content">
      <h1>Completed Jobs Categorized by Timesheet Status</h1>
      {Object.keys(jobs).map(status => (
        <div key={status}>
          <h2>{status}</h2>
          <table className="table table-striped">
            <thead>
              <tr>
                <th>#</th>
                <th>Job Title</th>
                <th>Location</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {jobs[status].map((job, index) => (
                <tr key={job.key}>
                  <td>{index + 1}</td>
                  <td>{job.jobTitle}</td>
                  <td>{job.location}</td>
                  <td>
                    <Button
                      text={
                        job.timesheetStatus === "Submitted"
                          ? "View submitted timesheet"
                          : "View Details & Upload Timesheet"
                      }
                      onClick={() =>
                        job.timesheetStatus === "Submitted"
                          ? handleShowTimesheetPopup(job)
                          : handleShowPopup(job)
                      }
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ))}

      {selectedJob && (
        <Popup
          visible={showPopup}
          onHiding={handleClosePopup}
          dragEnabled={true}
          closeOnOutsideClick={true}
          showTitle={true}
          title="Job Details & Upload Timesheet"
          width={600}
          height={600}
        >
          <div>
            <p>
              <strong>Job Title:</strong> {selectedJob.jobTitle}
            </p>
            <p>
              <strong>Location:</strong> {selectedJob.location}
            </p>
            <p>
              <strong>Description:</strong> {selectedJob.jobDescription}
            </p>
            <Form
              formData={timesheetData}
              onFieldDataChanged={handleTimesheetDataChange}
            >
              <SimpleItem
                dataField="date"
                editorType="dxDateBox"
                editorOptions={{ type: "date", displayFormat: "yyyy-MM-dd" }}
              >
                <RequiredRule message="Please select a date" />
              </SimpleItem>
              <SimpleItem
                dataField="startTime"
                editorType="dxDateBox"
                editorOptions={{ type: "time", displayFormat: "HH:mm" }}
              >
                <RequiredRule message="Please select a start time" />
              </SimpleItem>
              <SimpleItem
                dataField="endTime"
                editorType="dxDateBox"
                editorOptions={{ type: "time", displayFormat: "HH:mm" }}
              >
                <RequiredRule message="Please select an end time" />
              </SimpleItem>
              <SimpleItem
                dataField="breakTime"
                editorType="dxNumberBox"
                editorOptions={{ min: 0, placeholder: "minutes" }}
              >
                <RequiredRule message="Please enter break time in minutes" />
              </SimpleItem>
              <SimpleItem dataField="timesheetFile">
                <FileUploader
                  selectButtonText="Select file"
                  labelText=""
                  accept="*/*"
                  uploadMode="useForm"
                  onValueChanged={handleFileChange}
                />
                <RequiredRule message="Please select a timesheet file" />
              </SimpleItem>
              <ButtonItem
                horizontalAlignment="left"
                buttonOptions={{
                  text: "Submit Timesheet",
                  onClick: () => handleSubmit("Submitted"),
                  type: "success",
                }}
              />
              <ButtonItem
                horizontalAlignment="left"
                buttonOptions={{
                  text: "Save and Submit Later",
                  onClick: () => handleSubmit("In-Progress"),
                  type: "default",
                }}
              />
              <ButtonItem
                horizontalAlignment="left"
                buttonOptions={{
                  text: "Close",
                  onClick: handleClosePopup,
                  type: "danger",
                }}
              />
            </Form>
          </div>
        </Popup>
      )}

      {timesheetDetails && (
        <Popup
          visible={showTimesheetPopup}
          onHiding={handleCloseTimesheetPopup}
          dragEnabled={true}
          closeOnOutsideClick={true}
          showTitle={true}
          title="Submitted Timesheet Details"
          width={600}
          height={600}
        >
          <div>
            <p>
              <strong>Job Title:</strong> {timesheetDetails.jobTitle}
            </p>
            <p>
              <strong>Location:</strong> {timesheetDetails.location}
            </p>
            <p>
              <strong>Description:</strong> {timesheetDetails.jobDescription}
            </p>
            <p>
              <strong>Date:</strong> {timesheetDetails.date.toDate().toString()}
            </p>
            <p>
              <strong>Start Time:</strong>{" "}
              {timesheetDetails.startTime.toDate().toString()}
            </p>
            <p>
              <strong>End Time:</strong>{" "}
              {timesheetDetails.endTime.toDate().toString()}
            </p>
            <p>
              <strong>Break Time:</strong> {timesheetDetails.breakTime} minutes
            </p>
            <p>
              <strong>Timesheet File:</strong>{" "}
              <a
                href={timesheetDetails.timesheetFileUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                View File
              </a>
            </p>
            <Button
              text="Close"
              onClick={handleCloseTimesheetPopup}
              type="danger"
            />
          </div>
        </Popup>
      )}
    </div>
  )
}

export default CompletedJobsByTimesheetStatus
