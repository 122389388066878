import React, { useState, useEffect, useRef } from "react"
import JobDataService from "services/JobDataService"
import {
  DayPilot,
  DayPilotCalendar,
  DayPilotNavigator,
} from "@daypilot/daypilot-lite-react"
import jsPDF from "jspdf"
import TimesheetModal from "./TimesheetModal"
import ApplyModal from "./ApplyModal"
import ViewModal from "./ViewModal"
import CancelModal from "./CancelModal"

import { DataGrid, Column, Pager, Paging } from 'devextreme-react/data-grid';
import 'devextreme/dist/css/dx.light.css';  // Importing DevExtreme theme


const Schedule = () => {
  const [jobs, setJobs] = useState([])
  const jobsRef = useRef(jobs) // Ref to hold the current jobs state
  const calendarRef = useRef()

  const [orientation, setOrientation] = useState("portrait")
  const [rowsPerPage, setRowsPerPage] = useState(10)

  const [isTimesheetModalOpen, setIsTimesheetModalOpen] = useState(false)
  const [isApplyModalOpen, setIsApplyModalOpen] = useState(false)
  const [isViewModalOpen, setIsViewModalOpen] = useState(false)
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false)

  const [currentJobData, setCurrentJobData] = useState(null)

  const openTimesheetModal = jobData => {
    setCurrentJobData(jobData)
    setIsTimesheetModalOpen(true)
  }

  const closeTimesheetModal = () => {
    setIsTimesheetModalOpen(false)
    setCurrentJobData(null)
  }

  const openApplyModal = jobData => {
    setCurrentJobData(jobData)
    setIsApplyModalOpen(true)
  }

  const closeApplyModal = () => {
    setIsApplyModalOpen(false)
    setCurrentJobData(null)
  }

  const openViewModal = jobData => {
    setCurrentJobData(jobData)
    setIsViewModalOpen(true)
  }

  const closeViewModal = () => {
    setIsViewModalOpen(false)
    setCurrentJobData(null)
  }

  const openCancelModal = jobData => {
    setCurrentJobData(jobData)
    setIsCancelModalOpen(true)
  }

  const closeCancelModal = () => {
    setIsCancelModalOpen(false)
    setCurrentJobData(null)
  }

  const editEvent = async e => {
    const dp = calendarRef.current.control
    const modal = await DayPilot.Modal.prompt("Update event text:", e.text())
    if (!modal.result) {
      return
    }
    e.data.text = modal.result
    dp.events.update(e)
  }

  const [config, setConfig] = useState({
    viewType: "Week",
    durationBarVisible: false,
    timeRangeSelectedHandling: "Enabled",
    eventMoveHandling: "Update",
    onEventMoved: args => {
      console.log("Event moved: " + args.e.text())
    },
    eventResizeHandling: "Update",
    onEventResized: args => {
      console.log("Event resized: " + args.e.text())
    },
    eventClickHandling: "Enabled",
    eventHoverHandling: "Bubble",
    // onTimeRangeSelected: async args => {
    //   const dp = calendarRef.current.control
    //   const modal = await DayPilot.Modal.prompt(
    //     "Create a new event:",
    //     "Event 1"
    //   )
    //   dp.clearSelection()
    //   if (!modal.result) {
    //     return
    //   }
    //   dp.events.add({
    //     start: args.start,
    //     end: args.end,
    //     id: DayPilot.guid(),
    //     text: modal.result,
    //   })
    // },
    contextMenu: new DayPilot.Menu({
      items: [
        {
          text: "Delete",
          onClick: async args => {
            const dp = calendarRef.current.control
            dp.events.remove(args.source)
          },
        },
        {
          text: "-",
        },
        {
          text: "Edit...",
          onClick: async args => {
            await editEvent(args.source)
          },
        },
      ],
    }),
    // Initial event handlers can be defined here if necessary
  })

  const [email, setEmail] = useState("")

  useEffect(() => {
    if (localStorage.getItem("authUserDetails")) {
      let userDetails = JSON.parse(localStorage.getItem("authUserDetails"))

      let useremail = userDetails.email

      if (useremail) setEmail(useremail)
    } else {
      console.log("No user details found in localStorage.")
    }
  }, [])

  // useEffect(() => {
  //   const unsubscribe = JobDataService.getAll(data => {
  //     const filteredJobs = data.filter(job => job.email === email)

  //     // console.log("FT: " + JSON.stringify(filteredJobs))

  //     setJobs(filteredJobs)
  //   })
  //   return () => unsubscribe && unsubscribe()
  // }, [email])

  useEffect(() => {
    const unsubscribe = JobDataService.getAll(data => {
      // No filtering based on email, directly setting all jobs
      setJobs(data)
    })
    return () => unsubscribe && unsubscribe()
  }, []) // Removed email dependency since we're no longer filtering based on it

  useEffect(() => {
    JobDataService.getAll(jobsData => {
      let today = new Date()
      let startDate = new DayPilot.Date(today)
      let endDate = startDate.addYears(1)

      let allDays = []
      for (let day = startDate; day < endDate; day = day.addDays(1)) {
        allDays.push(day)
      }

      const events = allDays
        .map(day => {
          const dayStr = day.toString("yyyy-MM-dd")
          const existingJob = Object.values(jobsData).find(
            job => job.startDate === dayStr && job.startTime && job.endTime
          )

          if (!existingJob) {
            return {
              id: `placeholder-${dayStr}`,
              text: "No job",
              start: `${dayStr}T00:00:00`,
              end: `${dayStr}T24:00:00`,
              backColor: "#eeeeee",
            }
          }
          return null
        })
        .filter(event => event !== null)

      Object.keys(jobsData).forEach(key => {
        const job = jobsData[key]
        let backColor
        switch (job.jobstatus) {
          case "open":
            backColor = "green"
            break
          case "open over-due":
            backColor = "purple"
            break
          case "allocated":
            backColor = "gold"
            break
          case "completed":
            backColor = "lemon"
            break
          case "cancelled":
            backColor = "fuchsia"
            break
          default:
            backColor = "#eeeeee" // Default color for undefined status
        }

        let additionalLinks = ""
        if (job.jobstatus === "completed") {
          // Add HTML for clickable links with distinct icons
          // <FontAwesomeIcon icon={faEye} /> View
          // <FontAwesomeIcon icon={faClock} /> Timesheet
          //   additionalLinks = `
          //   <a href="#" onclick="viewDetails('${job.key}')" style="margin-right: 10px;"><i class="fa fa-eye"></i> View</a>
          //   <a href="#" onclick="createTimesheet('${job.key}')"><i class="fa fa-clock-o"></i> Timesheet</a>
          // `
          additionalLinks = `\n\n `
        }

        if (job.startDate && job.startTime && job.endDate && job.endTime) {
          events.push({
            id: job.key,
            text: `
            ${job.careHome} \n
            ${job.startTime} - ${job.endTime}
            ${job.worker === undefined ? " " : job.worker} \n
            ${job.jobTitle ? " " : job.jobTitle}
            ${additionalLinks}`, // Include additional links in the event text
            start: `${job.startDate}T${job.startTime}:00`,
            end: `${job.endDate}T${job.endTime}:00`,
            backColor: backColor,
            status: job.jobstatus,
            data: job,
          })
        }
      })

      setJobs(events)
      calendarRef.current.control.update({ events })
    })
  }, [])

  function JobEvent({ htmlContent }) {
    return <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
  }

  const exportToPdf = async () => {
    const doc = new jsPDF(orientation, "in", "letter")
    doc.setFontSize(40)
    doc.text(0.5, 1, "Scheduler")

    const getCalendar = () => calendarRef.current?.control

    const rows = getCalendar().rows.all()
    var pages = Math.ceil(rows.length / rowsPerPage)

    for (let i = 0; i < pages; i++) {
      const startId = i * rowsPerPage
      let endId = i * rowsPerPage + rowsPerPage - 1
      endId = Math.min(endId, rows.length - 1)

      const image = getCalendar().exportAs("jpeg", {
        area: "range",
        scale: 2,
        resourceFrom: rows[startId].id,
        resourceTo: rows[endId].id,
        quality: 0.95,
      })

      const dimensions = image.dimensions() // pixels
      const maxDimensions =
        orientation === "landscape"
          ? { width: 10, height: 7.5 }
          : { width: 7.5, height: 10 } // inches
      const adjustedDimensions = shrink(dimensions, maxDimensions) // inches

      doc.addImage(
        image.toDataUri(),
        "JPEG",
        0.5,
        1.5,
        adjustedDimensions.width,
        adjustedDimensions.height
      )

      const last = i === pages - 1
      if (!last) {
        doc.addPage()
      }
    }

    const blob = doc.output("blob")
    DayPilot.Util.downloadBlob(blob, "scheduler.pdf")
  }

  useEffect(() => {
    jobsRef.current = jobs // Update the ref every time the jobs state changes
  }, [jobs])

  useEffect(() => {
    setConfig(currentConfig => ({
      ...currentConfig,
      onBeforeEventRender: args => {
        // Define custom areas within the event
        args.data.areas = [
          {
            top: 150, // Starting very top for the "View" section
            right: 30,
            width: 100, // Adjusted width to accommodate longer text
            height: 60, // Adjusted height for both text and button
            html: "<div><span style='display: block; font-weight: bold; margin-bottom: 5px;'></span><button style='padding: 5px 10px; border-radius: 5px; border: none; cursor: pointer;background-color: #4CAF50;'>View</button></div>",
            onClick: args => {
              console.log("Viewing details for", args.source.data)
              openViewModal(args.source.data)
            },
            toolTip: "View Details",
          },
          {
            top: 180, // Adjusted top to stack below the "View" section
            right: 30,
            width: 100,
            height: 60,
            html: "<div><span style='display: block; font-weight: bold; margin-bottom: 5px;'></span><button style='padding: 5px 10px; border-radius: 5px; border: none; cursor: pointer;background-color: #2196F3;'>Timesheet</button></div>",
            onClick: args => {
              console.log("Opening timesheet for", args.source.data)
              openTimesheetModal(args.source.data)
            },
            toolTip: "Open Timesheet",
          },
          {
            top: 210, // Further adjusted top to stack below "Timesheet"
            right: 30,
            width: 100,
            height: 60,
            html: "<div><span style='display: block; font-weight: bold; margin-bottom: 5px;'></span><button style='padding: 5px 10px; border-radius: 5px; border: none; cursor: pointer;background-color: #f44336;'>Apply</button></div>",
            onClick: args => {
              console.log("Apply for", args.source.data)
              openApplyModal(args.source.data)
            },
            toolTip: "Apply",
          },
          {
            top: 240, // Further adjusted top to stack below "Apply"
            right: 30,
            width: 100,
            height: 60,
            html: "<div><span style='display: block; font-weight: bold; margin-bottom: 5px;'></span><button style='padding: 5px 10px; border-radius: 5px; border: none; cursor: pointer;background-color: #FF9800;'>Cancel</button></div>",
            onClick: args => {
              console.log("Cancel job:", args.source.data)
              openCancelModal(args.source.data)
            },
            toolTip: "Cancel",
          },
        ]

        // Conditional logic to only add these areas if jobstatus is 'completed'
        if (args.data.status !== "completed") {
          args.data.areas = args.data.areas.filter(
            area =>
              area.toolTip !== "View Details" &&
              area.toolTip !== "Open Timesheet"
          )
        }

        // Conditional logic to only add these areas if jobstatus is 'completed'
        // if (args.data.status !== "open") {
        //   args.data.areas = args.data.areas.filter(
        //     area =>
        //       area.toolTip !== "View Details" &&
        //       area.toolTip !== "Apply"
        //   )
        // }
      },
      // Rest of your configuration...
    }))
  }, [])

  // Update the config with the latest event handlers using the jobsRef
  useEffect(() => {
    setConfig(currentConfig => ({
      ...currentConfig,
      eventClickHandling: "Enabled", // Make sure this is set
      onEventClick: async args => {
        // console.log("Event clicked:", args.e.id()); // Debugging: Check if this gets logged

        // console.log("Event jobs ref: " + JSON.stringify(jobsRef));

        const clickedEvent = jobsRef.current.find(job => {
          return job.id === args.e.id()
        })

        //  console.log("Clicked event:", clickedEvent)

        if (!clickedEvent) {
          // Handling case when no job matches the clicked event
          // console.log("No job available for this event") // Debugging
          await DayPilot.Modal.alert("There is no job for this slot.")
          return // Exit if no job found
        } else {
          let modalTitle = ""
          let formItems = []
          let actionButton = { text: "", action: async formData => {} }

          switch (clickedEvent.data.jobstatus) {
            case "open":
              modalTitle = "Apply for Job"
              formItems = [
                { name: "Name", id: "name" },
                { name: "Email", id: "email" },
                // Add more fields as necessary
              ]
              actionButton.text = "Apply"
              actionButton.action = async formData => {
                // Assuming updateJob accepts the job id and a partial object with fields to update
                await JobDataService.updateJob(clickedEvent.id, {
                  jobStatus: "allocated",
                })
                // Assuming createApplication accepts the application data object
                await ApplicationDataService.createApplication({
                  ...formData,
                  jobId: clickedEvent.id,
                })
                alert("Application submitted!")
              }
              break

            case "open over-due":
              modalTitle = "Send Reminder Email"
              formItems = [{ name: "Your Message", id: "message" }]
              actionButton.text = "Send Email"
              actionButton.action = async formData => {
                // Implement the logic to send an email
                // This could be an API call or any other logic
                alert("Email sent!")
              }
              break

            case "completed":
              //  console.log("clicked event: " + JSON.stringify( clickedEvent.data ))
              const shiftOverviewHtml = `
                        <div style="margin-bottom: 20px;">
                          <h3>Shift Overview</h3>
                          <div><strong>Job Key:</strong> ${
                            clickedEvent.data.key
                          }</div>
                           <div><strong>Provider:</strong> ${
                             clickedEvent.data.provider
                           }</div>
                          <div style="background-color: ${
                            clickedEvent.backColor
                          };">
                            <strong>Status:</strong> ${clickedEvent.data.jobstatus
                              .toString()
                              .toUpperCase()}
                          </div>
                        </div>
                        <div>
                          <div><strong>Provider:</strong> ${
                            clickedEvent.data.provider
                          }</div>
                          <div><strong>Care Home:</strong> ${
                            clickedEvent.data.careHome
                          }</div>
                          <div><strong>Start Date & Time:</strong> ${
                            clickedEvent.data.startDate
                          } ${clickedEvent.data.startTime}</div>
                          <div><strong>End Date & Time:</strong> ${
                            clickedEvent.data.endDate
                          } ${clickedEvent.data.endTime}</div>
                          <div><strong>Break:</strong> ${
                            clickedEvent.data.breaks
                          }</div>
                          <div><strong>Job Type:</strong> ${
                            clickedEvent.data.jobType
                          }</div>
                        </div>`

              // Use DayPilot.Modal.alert() to show the shift overview. This is not ideal since it's an alert, not a form.
              await DayPilot.Modal.alert(shiftOverviewHtml).then(
                async function () {
                  // This part is tricky because we're mixing modal usage. You'd typically have a custom form here.
                  // Consider showing a separate modal for the timesheet form submission or using a different modal library.
                  const timesheetFormHtml = `<form id="timesheetForm">Number of Hours Worked: <input type="text" name="hours" /><br />Comments: <input type="text" name="comments" /><br /><button type="submit">Submit</button></form>`

                  // You would need to implement how to show this form and handle its submission.
                }
              )

              break

            case "allocated":
              modalTitle = "Cancel Shift"
              formItems = [{ name: "Reason for Cancellation", id: "reason" }]
              actionButton.text = "Cancel Shift"
              actionButton.action = async formData => {
                // Logic to cancel the shift and send an email notification
                alert("Shift cancelled and notification sent.")
              }
              break
          }

          if (modalTitle) {
            await DayPilot.Modal.form(formItems, modalTitle).then(
              async formResult => {
                if (formResult.result) {
                  // Execute the action function with form data
                  await actionButton.action(formResult.values)
                  // Refresh or update the UI as needed here, e.g., reload events or update status visually
                }
              }
            )
          }
        }
      },
    }))
  }, [jobs]) // Ensure jobs is included in dependency array if it influences the event logic

  const data = [
    { id: 1, name: "John Doe", age: 30 },
    { id: 2, name: "Jane Smith", age: 25 },
  ]


  return (
    <div className="page-content">
      
      <DataGrid dataSource={data} keyExpr="id" showBorders={true}>
        <Column dataField="id" caption="ID" width={50} />
        <Column dataField="name" caption="Name" />
        <Column dataField="age" caption="Age" />
        <Pager visible={true} />
        <Paging pageSize={10} />
      </DataGrid>

      <TimesheetModal
        isOpen={isTimesheetModalOpen}
        onClose={closeTimesheetModal}
        jobData={currentJobData}
      />

      <ApplyModal
        isOpen={isApplyModalOpen}
        onClose={closeApplyModal}
        jobData={currentJobData}
      />

      <ViewModal
        isOpen={isViewModalOpen}
        onClose={closeViewModal}
        jobData={currentJobData}
      />

      <CancelModal
        isOpen={isCancelModalOpen}
        onClose={closeCancelModal}
        jobData={currentJobData}
      />

      <div style={{ display: "flex" }}>
        <div style={{ marginRight: "10px" }}>
          <DayPilotNavigator
            selectMode="Week"
            showMonths={3}
            skipMonths={3}
            selectionDay={"2024-02-13"}
            onTimeRangeSelected={args => {
              calendarRef.current.control.update({ startDate: args.day })
            }}
          />
        </div>
        <div style={{ flexGrow: 1 }}>
          <div className={"toolbar"}>
            <div className={"toolbar-item"}>
              <b>Export</b>
            </div>
            Orientation:
            <select onChange={ev => setOrientation(ev.target.value)}>
              <option value={"portrait"}>Portrait</option>
              <option value={"landscape"}>Landscape</option>
            </select>
            Rows per page:
            <select onChange={ev => setRowsPerPage(parseInt(ev.target.value))}>
              <option>10</option>
              <option>20</option>
              <option>30</option>
            </select>
            <button onClick={() => exportToPdf()}>Download PDF</button>
          </div>
          <DayPilotCalendar {...config} ref={calendarRef} />
        </div>
      </div>
    </div>
  )
}

export default Schedule
