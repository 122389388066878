import React, { useState } from "react"
import Modal from "react-modal"
import JobDataService from "services/JobDataService"
import TimesheetDataService from "services/TimesheetDataService"

// Custom styling for the modal
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "80%", // Adjusted width
    maxWidth: "500px", // Set a max-width for larger screens
    padding: "20px",
    backgroundColor: "#f7f7f7", // Light grey background for the modal content
    borderRadius: "10px", // Rounded corners
  },
  overlay: {
    backgroundColor: "rgba(0,0,0,0.5)", // Semi-transparent background
  },
}

Modal.setAppElement("#root")

function ViewModal({ isOpen, onClose, jobData }) {
  const [hours, setHours] = useState("")
  const [comments, setComments] = useState("")
  const [file, setFile] = useState(null)

  const handleSubmit = async e => {
    e.preventDefault()
    // Assuming JobDataService has a createTimesheet method
    const timesheetData = {
      jobId: jobData?.data?.key,
      hours,
      comments,
      file: file,
    }
    await TimesheetDataService.createTimesheet(timesheetData)
    alert("Timesheet submitted successfully!")
    onClose() // Close the modal after submission
  }

  //   const handleSubmit = async e => {
  //     e.preventDefault()

  //     const formData = new FormData() // Use FormData for multipart/form-data
  //     formData.append("jobId", jobData?.data?.key)
  //     formData.append("hours", hours)
  //     formData.append("comments", comments)
  //     //if (file) formData.append("timesheetFile", file) // Append file if available

  //     try {
  //       await TimesheetDataService.createTimesheet(formData) // Ensure createTimesheet can handle FormData
  //       alert("Timesheet submitted successfully!")
  //       onClose() // Close the modal after submission
  //     } catch (error) {
  //       console.error("Error submitting timesheet:", error)
  //       alert("An error occurred while submitting the timesheet.")
  //     }
  //   }

  // Convert ISO date string to UK date format
  const convertToFriendlyDateUK = isoDateString => {
    const date = new Date(isoDateString)
    return date.toLocaleString("en-GB", {
      day: "numeric",
      month: "long",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    })
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      style={customStyles}
      contentLabel="Timesheet Modal"
    >
      <h2 style={{ color: "#333", textAlign: "center" }}>View Timesheet</h2>
      <div style={{ marginBottom: "20px" }}>
        <h3>Job Details</h3>
        {jobData && (
          <div
            style={{
              backgroundColor: "#fff",
              padding: "10px",
              borderRadius: "5px",
              boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
            }}
          >
            <p>
              Provider:{" "}
              <strong>{jobData?.data?.provider || "Not Available"}</strong>
            </p>
            <p>
              Care Home:{" "}
              <strong>{jobData?.data?.careHome || "Not Available"}</strong>
            </p>
            <p>
              Start Date & Time:{" "}
              <strong>
                {jobData?.start
                  ? convertToFriendlyDateUK(jobData.start)
                  : "Not Available"}
              </strong>{" "}
            </p>
            <p>
              End Date & Time:{" "}
              <strong>
                {jobData?.end
                  ? convertToFriendlyDateUK(jobData.end)
                  : "Not Available"}
              </strong>
            </p>
          </div>
        )}
      </div>
      <form onSubmit={handleSubmit}>
        <div style={{ textAlign: "right" }}>
          <button
            type="button"
            onClick={onClose}
            style={{
              marginRight: "10px",
              padding: "10px 20px",
              backgroundColor: "gray",
              color: "white",
              border: "none",
              borderRadius: "5px",
            }}
          >
            Close
          </button>
        </div>
      </form>
    </Modal>
  )
}

export default ViewModal
