import React, { useState, useEffect } from "react"
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap"
import AgreementDataService from "services/AgreementDataService"
import { v4 as uuidv4 } from "uuid"
import OrganisationDataService from "services/OrganisationDataService"
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage"
import {
  getFirestore,
  doc,
  setDoc,
  collection,
  addDoc,
  query,
  where,
  getDocs,
} from "firebase/firestore"
import logUserActivity from "helpers/activityLogger"

const AgreementManager = () => {
  const [agreement, setAgreement] = useState([])
  const [companyName, setCompanyName] = useState("")
  const [organisations, setOrganisations] = useState([])
  const [uploadedFiles, setUploadedFiles] = useState([])

  const storage = getStorage()
  const firestore = getFirestore()

  const [currentOrg, setCurrentOrg] = useState({
    businessName: "",
    businessID: "",
  })

  useEffect(() => {
    if (!localStorage.getItem("authUserDetails")) {
      // console.log("No user details found...")
    } else {
      // console.log("LS " + localStorage.getItem("authUserDetails"))
      if (localStorage.getItem("authUserDetails")) {
        let userDetails = JSON.parse(localStorage.getItem("authUserDetails"))

        // const company = userDetails.userCompany
        // console.log("Company is: " + company)
        // setCompanyName(company)

        if (userDetails.userType === "Super Admin") {
          console.log("setting as Super Admin.")
        } else {
          location.href = "/dashboard"
        }
      } else {
        console.log("No user details found in localStorage.")
      }
    }
  }, [])

  const [currentAgreement, setCurrentAgreement] = useState({
    agreementName: "",
    specialRate: "",
    billingCycle: "",
    createdBy: "",
    createdOn: "",
    workerRates: "",
    workerType: "",
    skill: "",
    hourlyRate: "",
    finalHourlyCost: "",
    status: "",
    agreementID: "",
    companyName: "",
    createdAt: Date.now(),
    signedAgreements: [],
  })

  const [selectedOrg, setSelectedOrg] = useState("")

  const handleSelectChange = e => {
    setSelectedOrg(e.target.value)
  }

  const handleFileChange = event => {
    const files = Array.from(event.target.files)
    setUploadedFiles(files) // Store the selected files in the state
  }

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [confirmDelete, setConfirmDelete] = useState({
    show: false,
    index: null,
  }) // For delete confirmation modal

  useEffect(() => {
    const unsubscribe = AgreementDataService.getAll(
      data => {
        setAgreement(data)
      },
      [currentAgreement]
    )
    return () => unsubscribe && unsubscribe()
  }, [])

  const toggleModal = () => {
    console.log(isModalOpen)
    setIsModalOpen(!isModalOpen)
    if (isModalOpen) {
      resetForm()
    }
  }

  // const handleInputChange = (event) => {
  //   const { name, value } = event.target;
  //   setCurrentAgreement({ ...currentAgreement, [name]: value });
  // };

  const handleInputChange = event => {
    const { name, value } = event.target

    if (name === "agreementID") {
      const fixedPrefix = currentAgreement.agreementID.substring(0, 14)

      if (value.startsWith(fixedPrefix)) {
        setCurrentAgreement({ ...currentAgreement, [name]: value })
      }
    } else {
      setCurrentAgreement({ ...currentAgreement, [name]: value })
    }
  }

  const generateAgreementID = () => {
    const year = new Date().getFullYear()
    const uuid = uuidv4()
    const shortUuid = `${uuid.slice(0, 2)}-${uuid.slice(-2)}`
    return `AGR-${year}-${shortUuid}`.toUpperCase()
  }

  useEffect(() => {
    setCurrentAgreement({
      ...currentAgreement,
      agreementID: generateAgreementID(),
      companyName: companyName,
    })
  }, [])

  // const submitHandler = event => {
  //   event.preventDefault()
  //   const { agreementID, ...agreementData } = currentAgreement
  //   if (agreementID) {
  //     AgreementDataService.updateAgreement(agreementID, agreementData).then(
  //       () => {
  //         resetForm()
  //         toggleModal()
  //       }
  //     )
  //   } else {
  //     AgreementDataService.createAgreement(agreementData).then(() => {
  //       resetForm()
  //       toggleModal()
  //     })
  //   }
  // }

  const uploadDocument = async (document, agreementID) => {
    if (document) {
      const uniqueName = `${uuidv4()}_${document.name}` // Create a unique file name
      const documentRef = ref(
        storage,
        `agreements/${agreementID}/${uniqueName}`
      )
      await uploadBytes(documentRef, document)
      return getDownloadURL(documentRef)
    }
    return null
  }

  const submitHandler = async event => {
    event.preventDefault()
    const { agreementID, companyName, ...agreementData } = currentAgreement
    const dataToSave = { ...agreementData, companyName }

    try {
      // Upload each signed agreement and get their URLs
      const signedAgreementUrls = await Promise.all(
        uploadedFiles.map(file => uploadDocument(file, agreementID))
      )

      // Store the signed agreement URLs in the agreement data
      dataToSave.signedAgreements = signedAgreementUrls

      // Create or update the agreement in the database
      if (agreementID) {
        await AgreementDataService.updateAgreement(agreementID, dataToSave)
      } else {
        await AgreementDataService.createAgreement(dataToSave)
      }
      // // Log user activity (optional)
      // logUserActivity({
      //   date: new Date().toISOString().split("T")[0],
      //   time: new Date().toLocaleTimeString(),
      //   activuploadDocumentity: `Agreement ${currentAgreement.agreementName} updated successfully.`,
      //   activityType: "Agreement",
      // })

      resetForm()
      toggleModal()
    } catch (error) {
      console.error("Error saving agreement: ", error.message)
    }
  }

  const deleteSignedAgreement = index => {
    const updatedAgreements = [...currentAgreement.signedAgreements]
    updatedAgreements.splice(index, 1) // Remove the agreement by index
    setCurrentAgreement({
      ...currentAgreement,
      signedAgreements: updatedAgreements,
    })
    setConfirmDelete({ show: false, index: null })
  }

  const uploadFiles = async agreementID => {
    // Placeholder function to handle file uploads
    const formData = new FormData()
    uploadedFiles.forEach((file, index) => {
      formData.append(`file_${index}`, file)
    })

    // Upload files to the server or a cloud storage service
    try {
      await AgreementDataService.uploadFiles(agreementID, formData) // Implement this method in your service
    } catch (error) {
      console.error("Error uploading files: ", error)
    }
  }

  const resetForm = () => {
    setCurrentAgreement({
      agreementName: "",
      specialRate: "",
      billingCycle: "",
      createdBy: "",
      createdOn: "",
      workerRates: "",
      workerType: "",
      skill: "",
      hourlyRate: "",
      finalHourlyCost: "",
      status: "",
      agreementID: generateAgreementID(), // Generate new ID on reset
      companyName: currentAgreement.companyName, // Retain the selected company name
      createdAt: Date.now(),
    })
    setUploadedFiles([]) // Reset uploaded files
  }

  const editAgreement = agreement => {
    setSelectedOrg(
      organisations.find(org => org.businessName === agreement.companyName)
        ?.key || ""
    )
    setCurrentAgreement({ ...agreement, agreementID: agreement.key || "" })
    setUploadedFiles(agreement.signedAgreements || [])
    toggleModal()
  }

  const deleteAgreement = key => {
    AgreementDataService.deleteAgreement(key)
  }

  // useEffect(() => {
  //   setCurrentAgreement(current => ({
  //     ...current,
  //     companyName: companyName,
  //   }))
  // }, [companyName])

  useEffect(() => {
    const unsubscribe = OrganisationDataService.getAll(
      data => {
        setOrganisations(data)
      },
      [currentOrg]
    )

    return () => unsubscribe && unsubscribe()
  }, [])

  useEffect(() => {
    // Find the selected organisation from the organisations array
    console.log("ORGS: " + JSON.stringify(organisations))

    const selectedOrganisation = organisations.find(
      org => org.key === selectedOrg
    )

    console.log("SOG: " + JSON.stringify(selectedOrganisation))
    // If an organisation is selected, update the currentAgreement with the companyName
    if (selectedOrganisation) {
      setCurrentAgreement(current => ({
        ...current,
        companyName: selectedOrganisation.businessName,
      }))
    }
  }, [selectedOrg, organisations]) // Depend on selectedOrg and organisations

  return (
    <div className="page-content">
      <Button
        color="primary"
        style={{ margin: "10px" }}
        onClick={() => toggleModal()}
      >
        Add New Agreement
      </Button>
      <span
        style={{ float: "right" }}
      >{`Displaying ${agreement.length} agreement(s)`}</span>
      <Modal
        isOpen={isModalOpen}
        toggle={toggleModal}
        backdrop={true}
        size="xl"
      >
        <ModalHeader toggle={toggleModal}>
          {currentAgreement.agreementID
            ? "Update Agreement"
            : "Create Agreement"}
        </ModalHeader>
        <Form onSubmit={submitHandler}>
          <ModalBody>
            <div>Fields marked with an asterisk (*) are required.</div>
            <FormGroup>
              <Label for="agreementID">Agreement ID</Label>
              <Input
                type="text"
                name="agreementID"
                id="agreementID"
                value={currentAgreement.agreementID || ""}
                onChange={handleInputChange}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for="agreementName">Agreement Name</Label>
              <Input
                type="text"
                name="agreementName"
                id="agreementName"
                value={currentAgreement.agreementName}
                onChange={handleInputChange}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for="specialRate">Special Rate</Label>
              <Input
                type="text"
                name="specialRate"
                id="specialRate"
                value={currentAgreement.specialRate}
                onChange={handleInputChange}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for="billingCycle">Billing Cycle</Label>
              <Input
                type="select"
                name="billingCycle"
                id="billingCycle"
                value={currentAgreement.billingCycle}
                onChange={handleInputChange}
                required
              >
                <option value="">Select a cycle</option>
                <option value="Daily">Daily</option>
                <option value="Weekly">Weekly</option>
                <option value="Monthly">Monthly</option>
                <option value="Quarterly">Quarterly</option>
                <option value="Yearly">Yearly</option>
              </Input>
            </FormGroup>

            <FormGroup>
              <Label for="createdBy">Created by</Label>
              <Input
                type="text"
                name="createdBy"
                id="createdBy"
                value={currentAgreement.createdBy}
                onChange={handleInputChange}
                required
              />
            </FormGroup>

            <FormGroup>
              <Label for="createdOn">Created on</Label>
              <Input
                type="date"
                name="createdOn"
                id="createdOn"
                value={currentAgreement.createdOn}
                onChange={handleInputChange}
                required
              />
            </FormGroup>

            <FormGroup>
              <Label for="workerRates">Worker rates</Label>
              <Input
                type="text"
                name="workerRates"
                id="workerRates"
                value={currentAgreement.workerRates}
                onChange={handleInputChange}
                required
              />
            </FormGroup>

            <FormGroup>
              <Label for="workerType">Worker type</Label>
              <Input
                type="text"
                name="workerType"
                id="workerType"
                value={currentAgreement.workerType}
                onChange={handleInputChange}
                required
              />
            </FormGroup>

            <FormGroup>
              <Label for="skill">Skill</Label>
              <Input
                type="text"
                name="skill"
                id="skill"
                value={currentAgreement.skill}
                onChange={handleInputChange}
                required
              />
            </FormGroup>

            <FormGroup>
              <Label for="hourlyRate">Hourly rate</Label>
              <Input
                type="text"
                name="hourlyRate"
                id="hourlyRate"
                value={currentAgreement.hourlyRate}
                onChange={handleInputChange}
                required
              />
            </FormGroup>

            <FormGroup>
              <Label for="finalHourlyCost">Final Hour cost</Label>
              <Input
                type="text"
                name="finalHourlyCost"
                id="finalHourlyCost"
                value={currentAgreement.finalHourlyCost}
                onChange={handleInputChange}
                required
              />
            </FormGroup>

            <FormGroup>
              <Label for="files">Upload Signed Agreements</Label>
              <Input
                type="file"
                name="files"
                id="files"
                multiple
                onChange={handleFileChange}
              />

              {currentAgreement.signedAgreements &&
                currentAgreement.signedAgreements.length > 0 && (
                  <div>
                    <Label>View Uploaded Signed Agreements:</Label>
                    <ul>
                      {currentAgreement.signedAgreements.map((url, index) => (
                        <li key={index}>
                          <a
                            href={url}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ color: "blue" }}
                          >
                            Signed Agreement {index + 1}
                          </a>
                          <Button
                            color="danger"
                            size="sm"
                            onClick={() =>
                              setConfirmDelete({ show: true, index })
                            }
                          >
                            Delete
                          </Button>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
            </FormGroup>
            <FormGroup>
              <Label for="companyName">Company</Label>
              <Input
                type="text"
                name="companyName"
                id="companyName"
                value={currentAgreement.companyName}
                onChange={handleInputChange}
                disabled={true}
                style={{ display: "none" }}
              />

              <select
                className="form-control"
                value={selectedOrg}
                onChange={handleSelectChange}
              >
                <option value="">Select Organisation</option>
                {organisations.map(org => (
                  <option key={org.key} value={org.key}>
                    {org.businessName}
                  </option>
                ))}
              </select>
              {/* <Input
                name="user_company"
                className="form-control"
                placeholder="Enter company"
                type="text"
               
                value={validation.values.user_company || ""}
            
                style={{ display: "none" }}
              /> */}
            </FormGroup>

            {/* <FormGroup>
              <Label for="status">Status</Label>
              <Input
                type="text"
                name="status"
                id="status"
                value={currentOrg.status}
                onChange={handleInputChange}
              />
            </FormGroup> */}
          </ModalBody>
          <ModalFooter>
            <Button color="primary" type="submit">
              {currentAgreement.agreementID ? "Update" : "Create"}
            </Button>
            <Button color="secondary" onClick={toggleModal}>
              Cancel
            </Button>
          </ModalFooter>
        </Form>
      </Modal>

      {/* Delete Confirmation Modal */}
      <Modal
        isOpen={confirmDelete.show}
        toggle={() => setConfirmDelete({ show: false, index: null })}
      >
        <ModalHeader
          toggle={() => setConfirmDelete({ show: false, index: null })}
        >
          Confirm Deletion
        </ModalHeader>
        <ModalBody>
          Are you sure you want to delete Signed Agreement{" "}
          {confirmDelete.index + 1}?
        </ModalBody>
        <ModalFooter>
          <Button
            color="danger"
            onClick={() => deleteSignedAgreement(confirmDelete.index)}
          >
            Delete
          </Button>
          <Button
            color="secondary"
            onClick={() => setConfirmDelete({ show: false, index: null })}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>

      <Table>
        <thead>
          <tr>
            <th>Provider </th>
            <th>Agreement Name</th>
            <th>Agreement ID</th>

            {/* Add additional headers for other fields */}
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {agreement.map(agree => (
            <tr key={agree.key}>
              <td>{agree.companyName}</td>
              <td>{agree.agreementName}</td>
              <td>{agree.key}</td>
              <td>
                <Button onClick={() => editAgreement(agree)}>Edit</Button>
                &nbsp;&nbsp;&nbsp;
                <Button onClick={() => deleteAgreement(agree.key)}>
                  Delete
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  )
}

export default AgreementManager
