import React, { useEffect } from "react"
import PropTypes from "prop-types"
import withRouter from "components/Common/withRouter"
import { logoutUser } from "../../store/actions"

// Redux
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"

const Logout = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  useEffect(() => {
    // Clear authUser and authUserDetails from localStorage
    localStorage.removeItem("authUser")
    localStorage.removeItem("authUserDetails")

    // Dispatch logout action
    dispatch(logoutUser(navigate))
  }, [dispatch, navigate])

  return <></>
}

Logout.propTypes = {
  history: PropTypes.object,
}

export default withRouter(Logout)
