import React, { useState, useEffect } from "react"
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap"
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage"
import {
  getFirestore,
  doc,
  setDoc,
  collection,
  addDoc,
  query,
  where,
  getDocs,
} from "firebase/firestore"

import WorkersDataService from "services/WorkersDataService"
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
import { editProfile } from "store/actions"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import style from "./workermanager.css"
import { v4 as uuidv4 } from "uuid"
import { getFirebaseBackend } from "../../helpers/firebase_helper"
import emailjs from "@emailjs/browser"
import { messages } from "common/data"

const Worker = () => {
  const [worker, setWorker] = useState([])
  const [dropdownOpenStates, setDropdownOpenStates] = useState({})
  const [emailError, setEmailError] = useState("")

  const storage = getStorage()
  const firestore = getFirestore()

  const [newCertificate, setNewCertificate] = useState({
    CertificateType: "",
    CertificateValidUntil: "",
    CertificateDoc: null,
  })
  const [certificates, setCertificates] = useState([])

  const [currentWorker, setCurrentWorker] = useState({
    firstName: "",
    lastName: "",
    alsoKnownAs: "",
    workerEmailAddress: "",
    emailError: "",
    dateOfBirth: null,
    gender: "",
    phoneNumber: "",
    country: "",
    postcode: "",
    residentialAddress: "",
    howlongintheaddress: "",
    fromDuration: "",
    toDuration: "",
    proofOfAddress: "",
    vatNumber: "",
    status: "",
    workerID: "",
    provider: "",

    //Documents
    cv: "",
    covid: "",
    nationalInsurance: "",
    referenceProof: "",
    passport: "",
    passportexpiryDate: null,
    proofOfAddress: "",
    dbs: "",
    dbsexpiryDate: null,
    visa_workpermit: "",
    visa_workpermitDate: null,

    //Certificates
    totalUKCareExperienceinYears: 0,
    totalUKCareExperienceinMonths: 0,
    Certificates: [],

    //Emergency
    emergencyContactName: "",
    emergencyRelationship: "",
    emergencyPriContact: "",
    emergencySecContact: "",

    //Vaccination
    vaccinationType: "",
    vaccinationDate: null,
    vaccinationProductName: "",
    vaccinationClinicSite: "",
    vaccinationIsCovid: false,
    vaccinationDoc: null,
    vaccinationAllergy: "",
    vaccinationTreatment: "",
    vaccinationOtherInfo: "",

    //Reference
    ReferenceType: "",
    ReferenceChecked: false,
  })

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isDocModalOpen, setIsDocModalOpen] = useState(false)
  const [isCertModalOpen, setIsCertModalOpen] = useState(false)
  const [isEmergencyModalOpen, setIsEmergencyModalOpen] = useState(false)
  const [isVacModalOpen, setIsVacModalOpen] = useState(false)
  const [createNew, setCreateNew] = useState(false)

  const [superadmin, setSuperAdmin] = useState(false)
  const [client, setClient] = useState(false)
  const [clientmanager, setClientManager] = useState(false)
  const [agencysuperadmin, setAgencySuperAdmin] = useState(false)
  const [companyName, setCompanyName] = useState("")
  const [email, setEmail] = useState("")

  //   useEffect(() => {
  //     const unsubscribe = WorkersDataService.getAll(
  //       data => {
  //         setWorker(data)
  //       },
  //       [currentWorker]
  //     )

  //     return () => unsubscribe && unsubscribe()
  //   }, [])

  useEffect(() => {
    const fetchCertificates = async () => {
      const db = getFirestore()
      const q = query(
        collection(db, "workers", currentWorker.workerID, "certificates")
      )

      try {
        const querySnapshot = await getDocs(q)
        const fetchedCertificates = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }))
        setCertificates(fetchedCertificates)
      } catch (error) {
        console.error("Error fetching certificates: ", error)
      }
    }

    if (currentWorker && currentWorker.workerID) {
      fetchCertificates()
    }
  }, [currentWorker])

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen)

    if (isModalOpen) {
      resetForm()
    }
  }

  const toggleDocModal = () => {
    setIsDocModalOpen(!isDocModalOpen)

    if (isDocModalOpen) {
      resetDocForm()
    }
  }

  const toggleCertificateModal = () => {
    setIsCertModalOpen(!isCertModalOpen)

    if (isCertModalOpen) {
      resetCertForm()
    }
  }

  const toggleEmergencyModal = () => {
    setIsEmergencyModalOpen(!isEmergencyModalOpen)

    if (isEmergencyModalOpen) {
      resetCertForm()
    }
  }

  const toggleVacModal = () => {
    setIsVacModalOpen(!isVacModalOpen)

    if (isVacModalOpen) {
      resetVacForm()
    }
  }

  function generateTempPassword(length = 10) {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()"
    let tempPassword = ""
    for (let i = 0; i < length; i++) {
      tempPassword += characters.charAt(
        Math.floor(Math.random() * characters.length)
      )
    }
    return tempPassword
  }

  const sendAcknowledgementEmail = (
    userEmail,
    userName,
    firstName,
    lastName,
    workerId,
    userType,
    tempPassword
  ) => {
    const templateParams = {
      to_email: userEmail,
      to_name: userName,
      firstName: firstName,
      lastName: lastName,
      workerID: workerId,
      userType: userType,
      password: tempPassword,
    }

    emailjs
      .send(
        "service_hms0mqj",
        "template_new_user",
        templateParams,
        "MGa-IIDI_leIHBrXj"
      )
      .then(
        response => {
          console.log("SUCCESS!", response.status, response.text)
        },
        error => {
          console.log("FAILED...", error)
        }
      )
  }


 useEffect(() => {
   if (localStorage.getItem("authUserDetails")) 
   {
     console.log("USER", localStorage.getItem("authUserDetails"));
     //USER {"lastLoginTime":{"seconds":1704266829,"nanoseconds":5000000},"userType":"Worker",
     //"userCompany":"FAUX","createdDtm":{"seconds":1704266829,"nanoseconds":5000000},
     //"workerID":"WOR-2024-13746648-1DA1-4059-8B32-C09BB2CFD221","lastName":"Worker",
     //"fullName":"New Worker","picture":"https://eu.ui-avatars.com/api/?name=New+Worker&size=250",
     //"email":"delakti@icloud.com","firstName":"New"}

     let userDetails = JSON.parse(localStorage.getItem("authUserDetails"))

     // Check if userDetails has the necessary properties
     if (userDetails.userCompany && userDetails.email) {
       const company = userDetails.userCompany
       setCompanyName(company)

       const theemail = userDetails.email
       setEmail(theemail) // Set email state

       let isSubscribed = true

       WorkersDataService.getAll(allWorkers => {
         if (isSubscribed) {
           const matchedWorker = allWorkers.find(
             worker => worker.workerEmailAddress === theemail
           )
           setWorker(matchedWorker ? [matchedWorker] : []) // Set a single worker or an empty array if no match
         }
       })

       return () => {
         isSubscribed = false
       }
     } else {
       console.log("Required user details are missing")
     }
   } else {
     console.log("No user details found in localStorage.")
   }
 }, [])


  const handleInputChange = event => {
    const { name, value } = event.target

    if (name === "workerID") {
      const fixedPrefix = currentWorker.workerID.substring(0, 14)

      if (value.startsWith(fixedPrefix)) {
        setCurrentWorker({ ...currentWorker, [name]: value })
      }
    } else {
      // For all other fields
      setCurrentWorker({ ...currentWorker, [name]: value })
    }
  }

  const handleEmailInputChange = event => {
    const { name, value } = event.target

    if (name === "workerEmailAddress") {
      if (!validateEmail(value)) {
        // Set error state
        setEmailError("Invalid email address")
        return
      } else {
        setEmailError("")
      }
    }
    setCurrentWorker({ ...currentWorker, [name]: value })
  }

  function validateEmail(email) {
    const re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
    return re.test(String(email).toLowerCase())
  }

  const handleFileChange = event => {
    const { name, files } = event.target
    if (files.length > 0) {
      setCurrentWorker(prevState => ({
        ...prevState,
        [name]: files[0], // Update the state with the file
      }))
    }
  }

  function getValidDate(date) {
    if (!date) return null // Return null if date is not set

    const parsedDate = new Date(date)
    return isNaN(parsedDate.getTime()) ? null : parsedDate
  }

  const handleBirthExpiryDateChange = date => {
    if (date instanceof Date && !isNaN(date)) {
      setCurrentWorker({ ...currentWorker, dateOfBirth: date })
    }
  }

  const handleDBSExpiryDateChange = date => {
    if (date instanceof Date && !isNaN(date)) {
      setCurrentWorker({ ...currentWorker, dbsexpiryDate: date })
    }
  }

  const handleVisaWorkpermitDateChange = date => {
    if (date instanceof Date && !isNaN(date)) {
      setCurrentWorker({ ...currentWorker, visa_workpermitDate: date })
    }
  }

  const handlePassportExpiryDateChange = date => {
    if (date instanceof Date && !isNaN(date)) {
      setCurrentWorker({ ...currentWorker, passportexpiryDate: date })
    }
  }

  const generateWorkerID = () => {
        const year = new Date().getFullYear()
        const uuid = uuidv4()
        const shortUuid = `${uuid.slice(0, 2)}-${uuid.slice(-2)}` // Take 4 characters from the start and 4 from the end
        return `WOR-${year}-${shortUuid}`.toUpperCase()
  }
      
  useEffect(() => {
    setCurrentWorker({
      ...currentWorker,
      workerID: generateWorkerID(),
    })
  }, [])

  const submitHandler = event => {
    event.preventDefault()
    const { workerID, ...workerData } = currentWorker

    if (createNew === true) {
      const firebaseBackend = getFirebaseBackend()
      const userType = "Worker"
      const tempPasswd = generateTempPassword()
      // Create the user with email and password
      firebaseBackend
        .registerUser(workerData.workerEmailAddress, tempPasswd)
        .then(registeredUser => {
          // After successful registration, add additional details to Firestore
          const userDetails = {
            email: workerData.workerEmailAddress,
            firstName: workerData.firstName,
            lastName: workerData.lastName,
            userType: userType,
            userCompany: companyName,
            workerID: workerID,
            // other necessary fields
          }

          firebaseBackend.addNewUserToFirestore(registeredUser.uid, userDetails)

          sendAcknowledgementEmail(
            workerData.workerEmailAddress,
            workerData.firstName + " " + workerData.lastName,
            workerData.firstName,
            workerData.lastName,
            workerID,
            userType,
            tempPasswd
          )
        })
        .catch(error => {
          console.error("Registration error", error) // Handle registration error
        })
    } else {
      // alert("updating worker...")
    }

    if (workerID) {
      WorkersDataService.updateWorker(workerID, workerData).then(() => {
        resetForm()
        toggleModal()
      })
    } else {
      WorkersDataService.createWorker(workerData).then(() => {
        resetForm()
        toggleModal()
      })
    }
  }

  const sendAccountConfirmationEmail = (email, text) => {
    const templateParams = {
      to_email: email,
      message: text,
      // You can add more template parameters if needed
    }

    const serviceID = "service_gmail"
    const userID = "MGa-IIDI_leIHBrXj"
    const templateID = "template_new_user"

    emailjs.send(serviceID, templateID, templateParams, userID).then(
      response => {
        console.log("Email successfully sent!", response.status, response.text)
      },
      error => {
        console.log("Failed to send email.", error)
      }
    )
  }

  const OldsubmitDocHandler = event => {
    event.preventDefault()
    const { workerID, ...workerData } = currentWorker
    if (workerID) {
      WorkersDataService.updateWorker(workerID, workerData).then(() => {
        resetDocForm()
        toggleDocModal()
      })
    } 
    else 
    {
      WorkersDataService.createWorker(workerData).then(() => {
        resetDocForm()
        toggleDocModal()
      })
    }
  }

  const OldsubmitCertHandler = async e => {
    e.preventDefault()

    // Upload to Firebase Storage
    const storageRef = storage.ref()
    //const storageRef = ref(storage, `documents/${workerID}/${path}`);
    const fileRef = storageRef.child(
      `certificates/${newCertificate.CertificateDoc.name}`
    )
    await fileRef.put(newCertificate.CertificateDoc)
    const fileUrl = await fileRef.getDownloadURL()

    // Add the new certificate to the certificates array
    const updatedCertificates = [
      ...certificates,
      {
        CertificateType: newCertificate.CertificateType,
        CertificateValidUntil: newCertificate.CertificateValidUntil,
        CertificateDoc: fileUrl,
      },
    ]

    setCertificates(updatedCertificates)

    // Reset newCertificate state or handle additional logic
    setNewCertificate({
      CertificateType: "",
      CertificateValidUntil: "",
      CertificateDoc: null,
    })

    // Optionally, update the worker's data on your database
    // ...
  }

  const submitCertHandler = async e => {
    e.preventDefault()
    const { workerID, ...workerData } = currentWorker

    // Initialize Firestore and Storage
    const db = getFirestore()
    const storage = getStorage()

    // File upload to Firebase Storage
    const storageRef = ref(
      storage,
      `certificates/${workerID}/${newCertificate.CertificateDoc.name}`
    )
    await uploadBytes(storageRef, newCertificate.CertificateDoc)
      .then(async snapshot => {
        const downloadURL = await getDownloadURL(snapshot.ref)

        // Create a new certificate object
        const certificateData = {
          CertificateType: newCertificate.CertificateType,
          CertificateValidUntil: newCertificate.CertificateValidUntil,
          CertificateDoc: downloadURL,
        }

        try {
          // Add to Firestore in the worker's certificates subcollection
          const workerCertsRef = collection(
            db,
            "workers",
            workerID,
            "certificates"
          )

          try {
            const docRef = await addDoc(workerCertsRef, certificateData)

            // Update local state
            setCertificates(prev => [
              ...prev,
              { id: docRef.id, ...certificateData },
            ])
          } catch (error) {
            console.error("Battling with updating of firestore: ", error)
          }

          setNewCertificate({
            CertificateType: "",
            CertificateValidUntil: "",
            CertificateDoc: null,
          })
        } catch (error) {
          console.error("Error updating firestore: ", error)
        }
      })
      .catch(error => {
        console.error("Error uploading file: ", error)
      })
  }

  const submitEmergencyHandler = async e => {
    e.preventDefault()
    const { workerID, ...workerData } = currentWorker
    if (workerID) {
      WorkersDataService.updateWorker(workerID, workerData).then(() => {
        resetForm()
        toggleEmergencyModal()
      })
    } else {
      WorkersDataService.createWorker(workerData).then(() => {
        resetForm()
        toggleEmergencyModal()
      })
    }
  }

  const esubmitCertHandler = async e => {
    e.preventDefault()
    const { workerID, ...workerData } = currentWorker

    // Initialize Firestore and Storage
    const db = getFirestore()
    const storage = getStorage()

    // File upload to Firebase Storage
    const storageRef = ref(
      storage,
      `certificates/${newCertificate.CertificateDoc.name}`
    )
    await uploadBytes(storageRef, newCertificate.CertificateDoc)
      .then(async snapshot => {
        const downloadURL = await getDownloadURL(snapshot.ref)

        // Create a new certificate object
        const certificateData = {
          CertificateType: newCertificate.CertificateType,
          CertificateValidUntil: newCertificate.CertificateValidUntil,
          CertificateDoc: downloadURL,
        }

        // Update Firestore (Assuming a structure where each worker has a document)
        try {
          const workerRef = doc(db, "workers", workerID) // Replace 'workerID' with actual worker ID
          await setDoc(
            workerRef,
            { certificates: [...certificates, certificateData] },
            { merge: true }
          )
        } catch (error) {
          console.error("Error updating firestore: ", error)
        }

        try {
          // Update local state
          setCertificates(prev => [...prev, certificateData])
          setNewCertificate({
            CertificateType: "",
            CertificateValidUntil: "",
            CertificateDoc: null,
          })
        } catch (error) {
          console.error("Error setting local state: ", error)
        }
      })
      .catch(error => {
        console.error("Error uploading file: ", error)
      })
  }

  const submitDocHandler = async event => {
    event.preventDefault()
    const {
      workerID,
      cv,
      covid,
      nationalInsurance,
      referenceProof,
      dbs,
      visa_workpermit,
      ...otherData
    } = currentWorker

    try {
      if (workerID) {
        // Upload each document and get their download URLs
        const uploadDocument = async (document, path) => {
          if (document) {
            const documentRef = ref(storage, `documents/${workerID}/${path}`)
            await uploadBytes(documentRef, document)
            return getDownloadURL(documentRef)
          }
          return null
        }

        const cvUrl = await uploadDocument(cv, "cv")
        const covidUrl = await uploadDocument(covid, "covid")
        const nationalInsuranceUrl = await uploadDocument(
          nationalInsurance,
          "nationalInsurance"
        )
        const referenceProofUrl = await uploadDocument(
          referenceProof,
          "referenceProof"
        )
        const dbsUrl = await uploadDocument(dbs, "dbs")
        const visaWorkpermitUrl = await uploadDocument(
          visa_workpermit,
          "visa_workpermit"
        )

        // Create an object to update in Firestore
        const updatedData = {
          ...otherData,
          cv: cvUrl,
          covid: covidUrl,
          nationalInsurance: nationalInsuranceUrl,
          referenceProof: referenceProofUrl,
          dbs: dbsUrl,
          visa_workpermit: visaWorkpermitUrl,
        }

        WorkersDataService.updateWorker(workerID, updatedData).then(() => {
          resetDocForm()
          toggleDocModal()
        })
      } else {
        console.log("Worker ID is required")
      }
    } catch (error) {
      console.log("Update error: " + error.message)
    }
  }

  const resetForm = () => {
    setCurrentWorker({
      firstName: "",
      lastName: "",
      alsoKnownAs: "",
      workerEmailAddress: "",
      dateOfBirth: null,
      gender: "",
      phoneNumber: "",
      country: "",
      postcode: "",
      residentialAddress: "",
      howlongintheaddress: "",
      fromDuration: "",
      toDuration: "",
      proofOfAddress: "",
      vatNumber: "",
      status: "",
      workerID: "",
    })
  }

  const resetDocForm = () => {
    setCurrentWorker({
      cv: "",
      covid: "",
      nationalInsurance: "",
      referenceProof: "",
      passport: "",
      passportexpiryDate: null,
      proofOfAddress: "",
      dbs: "",
      dbsexpiryDate: null,
      visa_workpermit: "",
      visa_workpermitDate: null,
    })
  }

  const resetCertForm = () => {
    setCurrentWorker({
      CertificateType: "",
      CertificateValidUntil: null,
      certificates: [],
    })
  }

  const resetVacForm = () => {
    setCurrentWorker({})
  }

  const resetEmergencyForm = () => {
    setCurrentWorker({
      emergencyContactName: "",
      emergencyRelationship: "",
      emergencyPriContact: "",
      emergencySecContact: "",
    })
  }

  const editProfile = work => {
    setCurrentWorker({ ...work, workerID: work.key || "" })

    toggleModal()
  }

  const editDocuments = work => {
    setCurrentWorker({ ...work, workerID: work.key || "" })

    toggleDocModal()
  }

  const editCertificates = work => {
    setCurrentWorker({ ...work, workerID: work.key || "" })

    toggleCertificateModal()
  }

  const editEmergency = work => {
    setCurrentWorker({ ...work, workerID: work.key || "" })

    toggleEmergencyModal()
  }

  const editVaccinations = work => {
    setCurrentWorker({ ...work, workerID: work.key || "" })

    toggleVacModal()
  }

  const editReferences = work => {
    setCurrentWorker({ ...work, workerID: work.key || "" })

    toggleModal()
  }

  const editAudits = work => {
    setCurrentWorker({ ...work, workerID: work.key || "" })

    toggleModal()
  }

  const editWorker = worker => {
    setCurrentWorker({ ...worker, workerID: worker.key || "" })
    toggleModal()
  }

  const deleteWorker = key => {
    WorkersDataService.deleteWorker(key)
  }

  const toggleDropdown = key => {
    setDropdownOpenStates(prevState => ({
      ...prevState,
      [key]: !prevState[key],
    }))
  }

  // function getValidDate(date) {
  //   const parsedDate = new Date(date);
  //   return isNaN(parsedDate.getTime()) ? null : parsedDate;
  // }

  return (
    <div className="page-content">
      <h5>
        Welcome to the onboarding system. Please select the category in the
        action button that you wish to launch.
      </h5>
      {/* <div className="doc-container">
        <Button
          color="primary"
          onClick={() => {
            setCreateNew(true)
            toggleModal()
          }}
        >
          Add New Worker
        </Button>
      </div> */}

      <Modal
        isOpen={isModalOpen}
        toggle={toggleModal}
        backdrop={true}
        size="lg"
      >
        <ModalHeader toggle={toggleModal}>
          {currentWorker.workerID ? "Update Worker" : "Create Worker"}
        </ModalHeader>
        <Form onSubmit={submitHandler}>
          <ModalBody>
            <div>Fields marked with an asterisk (*) are required.</div>
            <FormGroup>
              <Label for="workerID">
                Worker ID<span style={{ color: "red" }}>****</span>
              </Label>
              <Input
                type="text"
                name="workerID"
                id="workerID"
                value={currentWorker.workerID || ""}
                onChange={handleInputChange}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for="businessName">
                First Name<span style={{ color: "red" }}>*</span>
              </Label>
              <Input
                type="text"
                name="firstName"
                id="firstName"
                value={currentWorker.firstName}
                onChange={handleInputChange}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for="lastName">
                Last Name<span style={{ color: "red" }}>*</span>
              </Label>
              <Input
                type="text"
                name="lastName"
                id="lastName"
                value={currentWorker.lastName}
                onChange={handleInputChange}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for="alsoKnownAs">Also known as</Label>
              <Input
                type="text"
                name="alsoKnownAs"
                id="alsoKnownAs"
                value={currentWorker.alsoKnownAs}
                onChange={handleInputChange}
              />
            </FormGroup>

            <FormGroup>
              <Label for="workerEmailAddress">
                Worker Email Address<span style={{ color: "red" }}>*</span>
              </Label>
              <div>
                <Input
                  type="email"
                  name="workerEmailAddress"
                  id="workerEmailAddress"
                  value={currentWorker.workerEmailAddress}
                  onChange={handleInputChange}
                  className={emailError ? "error-class" : ""}
                  required
                />
                {emailError && (
                  <div className="error-message">{emailError}</div>
                )}
              </div>
            </FormGroup>
            <FormGroup>
              <Label for="dateOfBirth">
                Date Of Birth<span style={{ color: "red" }}>*</span>
              </Label>
              <DatePicker
                selected={getValidDate(currentWorker.dateOfBirth)}
                onChange={handleBirthExpiryDateChange}
                className="form-control"
                dateFormat="dd/MM/yyyy"
                required
              />
            </FormGroup>

            <FormGroup>
              <Label for="gender">
                Gender<span style={{ color: "red" }}>*</span>
              </Label>
              <select
                name="gender"
                id="gender"
                value={currentWorker.gender}
                onChange={handleInputChange}
                className="form-control"
                required
              >
                <option value="">Select Gender</option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
              </select>
            </FormGroup>

            <FormGroup>
              <Label for="phoneNumber">
                Phone Number<span style={{ color: "red" }}>*</span>
              </Label>
              <Input
                type="text"
                name="phoneNumber"
                id="phoneNumber"
                value={currentWorker.phoneNumber}
                onChange={handleInputChange}
                required
              />
            </FormGroup>

            <FormGroup>
              <Label for="country">
                Country<span style={{ color: "red" }}>*</span>
              </Label>
              <Input
                type="text"
                name="country"
                id="country"
                value={currentWorker.country}
                onChange={handleInputChange}
                required
              />
            </FormGroup>

            <FormGroup>
              <Label for="postcode">
                Post code<span style={{ color: "red" }}>*</span>
              </Label>
              <Input
                type="text"
                name="postcode"
                id="postcode"
                value={currentWorker.postcode}
                onChange={handleInputChange}
                required
              />
            </FormGroup>

            <FormGroup>
              <Label for="residentialAddress">
                Residential Address<span style={{ color: "red" }}>*</span>
              </Label>
              <Input
                type="text"
                name="residentialAddress"
                id="residentialAddress"
                value={currentWorker.residentialAddress}
                onChange={handleInputChange}
                required
              />
            </FormGroup>

            <FormGroup>
              <Label for="howlongintheaddress">
                How long have you lived in the Address?
                <span style={{ color: "red" }}>*</span>
              </Label>
              <Input
                type="text"
                name="howlongintheaddress"
                id="howlongintheaddress"
                value={currentWorker.howlongintheaddress}
                onChange={handleInputChange}
                required
              />
            </FormGroup>

            <FormGroup>
              <Label for="fromDuration">
                Duration from:<span style={{ color: "red" }}>*</span>
              </Label>
              <Input
                type="text"
                name="fromDuration"
                id="fromDuration"
                value={currentWorker.fromDuration}
                onChange={handleInputChange}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for="toDuration">
                Duration to:<span style={{ color: "red" }}>*</span>
              </Label>
              <Input
                type="text"
                name="toDuration"
                id="toDuration"
                value={currentWorker.toDuration}
                onChange={handleInputChange}
                required
              />
            </FormGroup>

            <FormGroup>
              <Label for="proofOfAddress">Proof Of Address</Label>
              <Input
                type="text"
                name="proofOfAddress"
                id="proofOfAddress"
                value={currentWorker.proofOfAddress}
                onChange={handleInputChange}
              />
            </FormGroup>

            {/* <FormGroup>
              <Label for="status">Status</Label>
              <Input
                type="text"
                name="status"
                id="status"
                value={currentOrg.status}
                onChange={handleInputChange}
              />
            </FormGroup> */}
          </ModalBody>
          <ModalFooter>
            <Button color="primary" type="submit">
              {currentWorker.workerID ? "Update" : "Create"}
            </Button>
            <Button color="secondary" onClick={toggleModal}>
              Cancel
            </Button>
          </ModalFooter>
        </Form>
      </Modal>

      <Modal
        isOpen={isDocModalOpen}
        toggle={toggleDocModal}
        backdrop={true}
        size="lg"
      >
        <ModalHeader toggle={toggleDocModal}>
          {currentWorker.workerID ? "Update Document" : "Create Document"}
        </ModalHeader>
        <Form onSubmit={submitDocHandler}>
          <ModalBody>
            <FormGroup>
              <div className="doc-container">
                <Label for="cv">CV</Label>
                {currentWorker.cv && (
                  <a
                    href={currentWorker.cv || "#"}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    View Uploaded CV
                  </a>
                )}
              </div>
              <Input
                type="file"
                name="cv"
                id="cv"
                onChange={handleFileChange}
              />
            </FormGroup>
            <FormGroup>
              <div className="doc-container">
                <Label for="covid">COVID</Label>
                { currentWorker.covid && (
                  <a
                    href={currentWorker.covid || "#"}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    View Uploaded COVID
                  </a>
                )}
              </div>
              <Input
                type="file"
                name="covid"
                id="covid"
                onChange={handleFileChange}
              />
            </FormGroup>
            <FormGroup>
              <div className="doc-container">
                <Label for="nationalInsurance">National Insurance</Label>
                {currentWorker.nationalInsurance && (
                  <a
                    href={currentWorker.nationalInsurance || "#"}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    View Uploaded NI
                  </a>
                )}
              </div>
              <Input
                type="file"
                name="nationalInsurance"
                id="nationalInsurance"
                onChange={handleFileChange}
              />
            </FormGroup>
            <FormGroup>
              <div className="doc-container">
                <Label for="referenceProof">Reference Proof</Label>
                {currentWorker.referenceProof && (
                  <a
                    href={currentWorker.referenceProof || "#"}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    View Uploaded Reference
                  </a>
                )}
              </div>
              <Input
                type="file"
                name="referenceProof"
                id="referenceProof"
                onChange={handleFileChange}
              />
            </FormGroup>
            <FormGroup>
              <div className="doc-container">
                <Label for="passport">Passport</Label>
                {currentWorker.passport && (
                  <a
                    href={currentWorker.passport || "#"}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    View Uploaded Passport
                  </a>
                )}
              </div>
              <Input
                type="file"
                name="passport"
                id="passport"
                onChange={handleFileChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="passportexpiryDate">Passport Expiry Date</Label>
              <DatePicker
                selected={getValidDate(currentWorker.passportexpiryDate)}
                onChange={handlePassportExpiryDateChange}
                className="form-control"
                dateFormat="dd/MM/yyyy"
              />
            </FormGroup>
            <FormGroup>
              <div className="doc-container">
                <Label for="dbs">DBS</Label>
                {currentWorker.dbs && (
                  <a
                    href={currentWorker.dbs || "#"}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    View Uploaded DBS
                  </a>
                )}
              </div>
              <Input
                type="file"
                name="dbs"
                id="dbs"
                onChange={handleFileChange}
              />
            </FormGroup>

            <FormGroup>
              <Label for="dbsexpiryDate">DBS Expiry Date</Label>

              <DatePicker
                selected={getValidDate(currentWorker.dbsexpiryDate)} // Fallback to current date
                onChange={handleDBSExpiryDateChange}
                className="form-control"
                dateFormat="dd/MM/yyyy"
              />
            </FormGroup>

            <FormGroup>
              <div className="doc-container">
                <Label for="visa_workpermit">Visa work permit</Label>
                {currentWorker.visa_workpermit && (
                  <a
                    href={currentWorker.visa_workpermit || "#"}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    View Uploaded work permit
                  </a>
                )}
              </div>
              <Input
                type="file"
                name="visa_workpermit"
                id="visa_workpermit"
                onChange={handleFileChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="visa_workpermitDate">Visa Work Permit Date</Label>

              <DatePicker
                selected={getValidDate(currentWorker.visa_workpermitDate)} // Fallback to current date
                onChange={handleVisaWorkpermitDateChange}
                className="form-control"
                dateFormat="dd/MM/yyyy"
              />
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" type="submit">
              {currentWorker.workerID ? "Update" : "Create"}
            </Button>
            <Button color="secondary" onClick={toggleDocModal}>
              Cancel
            </Button>
          </ModalFooter>
        </Form>
      </Modal>

      <Modal
        isOpen={isCertModalOpen}
        toggle={toggleCertificateModal}
        backdrop={true}
        size="lg"
      >
        <ModalHeader toggle={toggleCertificateModal}>
          {currentWorker.workerID ? "Update Certificate" : "Create Certificate"}
        </ModalHeader>
        <form
          onSubmit={submitCertHandler}
          style={{
            padding: "20px",
            maxWidth: "500px",
            margin: "0 auto",
            backgroundColor: "#f5f5f5",
            borderRadius: "8px",
            boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
          }}
        >
          <div style={{ marginBottom: "10px" }}>
            <input
              type="text"
              value={newCertificate.CertificateType}
              onChange={e =>
                setNewCertificate({
                  ...newCertificate,
                  CertificateType: e.target.value,
                })
              }
              placeholder="Certificate Type"
              style={{
                padding: "10px",
                width: "100%",
                borderRadius: "4px",
                border: "1px solid #ddd",
              }}
            />
          </div>
          <div style={{ marginBottom: "10px" }}>
            <input
              type="date"
              value={newCertificate.CertificateValidUntil}
              onChange={e =>
                setNewCertificate({
                  ...newCertificate,
                  CertificateValidUntil: e.target.value,
                })
              }
              style={{
                padding: "10px",
                width: "100%",
                borderRadius: "4px",
                border: "1px solid #ddd",
              }}
            />
          </div>
          <div style={{ marginBottom: "20px" }}>
            <input
              type="file"
              onChange={e =>
                setNewCertificate({
                  ...newCertificate,
                  CertificateDoc: e.target.files[0],
                })
              }
              style={{ width: "100%" }}
            />
          </div>
          <button
            type="submit"
            style={{
              padding: "10px 20px",
              backgroundColor: "#007bff",
              color: "white",
              border: "none",
              borderRadius: "4px",
              cursor: "pointer",
            }}
          >
            Add Certificate
          </button>
        </form>
        <ul
          style={{
            listStyleType: "none",
            padding: "0",
            maxWidth: "500px",
            margin: "20px auto",
          }}
        >
          {certificates.map((certificate, index) => (
            <li
              key={index}
              style={{
                background: "#fff",
                margin: "10px 0",
                padding: "15px",
                borderRadius: "4px",
                boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
              }}
            >
              Type: {certificate.CertificateType}, Valid Until:{" "}
              {certificate.CertificateValidUntil}
              <a
                href={certificate.CertificateDoc}
                rel="noreferrer"
                target="_blank"
                style={{
                  marginLeft: "10px",
                  textDecoration: "none",
                  color: "#007bff",
                }}
              >
                View Document
              </a>
            </li>
          ))}
        </ul>
      </Modal>

      <Modal
        isOpen={isEmergencyModalOpen}
        toggle={toggleEmergencyModal}
        backdrop={true}
        size="lg"
      >
        <ModalHeader toggle={toggleEmergencyModal}>
          {currentWorker.workerID ? "Update Emergency" : "Create Emergency"}
        </ModalHeader>
        <form onSubmit={submitEmergencyHandler}>
          <ModalBody>
            <FormGroup>
              <Label for="emergencyContactName">Emergency Contact Name</Label>
              <Input
                type="text"
                name="emergencyContactName"
                id="emergencyContactName"
                value={currentWorker.emergencyContactName || ""}
                onChange={handleInputChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="emergencyRelationship">Emergency Relationship</Label>
              <Input
                type="text"
                name="emergencyRelationship"
                id="emergencyContactName"
                value={currentWorker.emergencyRelationship || ""}
                onChange={handleInputChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="emergencyPriContact">Emergency Primary Contact</Label>
              <Input
                type="text"
                name="emergencyPriContact"
                id="emergencyPriContact"
                value={currentWorker.emergencyPriContact || ""}
                onChange={handleInputChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="emergencySecContact">
                Emergency Secondary Contact
              </Label>
              <Input
                type="text"
                name="emergencySecContact"
                id="emergencySecContact"
                value={currentWorker.emergencySecContact || ""}
                onChange={handleInputChange}
              />
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" type="submit">
              {currentWorker.workerID ? "Update" : "Create"}
            </Button>
            <Button color="secondary" onClick={toggleEmergencyModal}>
              Cancel
            </Button>
          </ModalFooter>
        </form>
      </Modal>

      <Modal
        isOpen={isVacModalOpen}
        toggle={toggleVacModal}
        backdrop={true}
        size="lg"
      >
        <ModalHeader toggle={toggleVacModal}>
          {currentWorker.workerID ? "Update Vaccination" : "Create Vaccination"}
        </ModalHeader>
        <form onSubmit={submitEmergencyHandler}>
          <ModalBody>
            <FormGroup>
              <Label for="vaccinationType">Vaccination Type</Label>
              <Input
                type="text"
                name="vaccinationType"
                id="vaccinationType"
                value={currentWorker.vaccinationType}
                onChange={handleInputChange}
              />
            </FormGroup>

            <FormGroup>
              <Label for="vaccinationDate">Vaccination Date</Label>
              <Input
                type="date"
                name="vaccinationDate"
                id="vaccinationDate"
                value={currentWorker.vaccinationDate}
                onChange={handleInputChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="vaccinationProductName">Product Name</Label>
              <Input
                type="text"
                name="vaccinationProductName"
                id="vaccinationProductName"
                value={currentWorker.vaccinationProductName}
                onChange={handleInputChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="vaccinationClinicSite">Clinic Site</Label>
              <Input
                type="text"
                name="vaccinationClinicSite"
                id="vaccinationClinicSite"
                value={currentWorker.vaccinationClinicSite}
                onChange={handleInputChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="vaccinationIsCovid">Is Covid</Label>
              <Input
                type="text"
                name="vaccinationIsCovid"
                id="vaccinationIsCovid"
                value={currentWorker.vaccinationIsCovid}
                onChange={handleInputChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="vaccinationDoc">Vaccination Doc</Label>
              <Input
                type="text"
                name="vaccinationDoc"
                id="vaccinationDoc"
                value={currentWorker.vaccinationDoc}
                onChange={handleInputChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="vaccinationAllergy">Allergy</Label>
              <Input
                type="text"
                name="vaccinationAllergy"
                id="vaccinationAllergy"
                value={currentWorker.vaccinationAllergy}
                onChange={handleInputChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="vaccinationTreatment">Treatment</Label>
              <Input
                type="text"
                name="vaccinationTreatment"
                id="vaccinationTreatment"
                value={currentWorker.vaccinationTreatment}
                onChange={handleInputChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="vaccinationOtherInfo">Other Information</Label>
              <Input
                type="text"
                name="vaccinationOtherInfo"
                id="vaccinationOtherInfo"
                value={currentWorker.vaccinationOtherInfo}
                onChange={handleInputChange}
              />
            </FormGroup>
          </ModalBody>
        </form>
      </Modal>
      <Table>
        {/* <thead>
          <tr>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Status</th>
            <th>Actions</th>
          </tr>
        </thead> */}
        <tbody>
          {worker.map(work => (
            <tr key={work.key}>
              <td>{work.firstName}</td>
              <td>{work.lastName}</td>
              <td>
                <span
                  className={`badge badge-${
                    (work.status && work.status.toLowerCase()) || "new"
                  }`}
                >
                  {work.status || "New"}
                </span>
              </td>
              {/* Add additional cells for other fields */}
              <td>
                {/* <Button onClick={() =>{editWorker(work)}}>Edit</Button>&nbsp;&nbsp;&nbsp;
                <Button onClick={() => deleteWorker(work.key)}>Delete</Button> */}
                <Dropdown
                  className="custom-dropdown-menu"
                  isOpen={dropdownOpenStates[work.key]}
                  toggle={() => toggleDropdown(work.key)}
                >
                  <DropdownToggle caret>Actions</DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem onClick={() => editProfile(work)}>
                      Profile
                    </DropdownItem>
                    <DropdownItem onClick={() => editDocuments(work)}>
                      Documents
                    </DropdownItem>
                    <DropdownItem onClick={() => editCertificates(work)}>
                      Certificates
                    </DropdownItem>
                    <DropdownItem onClick={() => editEmergency(work)}>
                      Emergency
                    </DropdownItem>
                    <DropdownItem onClick={() => editVaccinations(work)}>
                      Vaccinations
                    </DropdownItem>
                    <DropdownItem onClick={() => editReferences(work)}>
                      References
                    </DropdownItem>
                    <DropdownItem onClick={() => editAudits(work)}>
                      Audits
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  )
}

export default Worker
