import React, { useState, useEffect } from "react"
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap"
import JobDataService from "services/JobDataService"
import { v4 as uuidv4 } from "uuid"
import emailjs from "@emailjs/browser"
import Booking from "./Booking"

const JobManager = () => {
  const [job, setJob] = useState([])
  const [isNew, setIsNew] = useState("")

  const [currentJob, setCurrentJob] = useState({
    jobTitle: "",
    jobDescription: "",
    jobType: "",
    provider: "",
    careHome: "",
    workerType: "",
    noofVacancies: "",
    startDate: "",
    startTime: "",
    endDate: "",
    endTime: "",
    enableRecurrence: "",
    breaks: "",
    jobstatus: "",
    jobID: "",
    category: "",
    featured: "",
    createdAt: Date.now(),
  })
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [searchQuery, setSearchQuery] = useState("") // Search query state
  const [sortField, setSortField] = useState("jobType") // Sorting field
  const [sortOrder, setSortOrder] = useState("asc") // Sorting order

    const handleSearch = event => {
      setSearchQuery(event.target.value)
    }

    const handleSort = field => {
      const order = sortField === field && sortOrder === "asc" ? "desc" : "asc"
      setSortField(field)
      setSortOrder(order)
    }

    const filteredAndSortedJobs = () => {
      let filteredJobs = job

      // Filter based on search query
      if (searchQuery) {
        filteredJobs = filteredJobs.filter(
          jb =>
            jb.jobType.toLowerCase().includes(searchQuery.toLowerCase()) ||
            jb.provider.toLowerCase().includes(searchQuery.toLowerCase()) ||
            jb.jobID.toLowerCase().includes(searchQuery.toLowerCase())
        )
      }

      // Sort by selected field
      const sortedJobs = [...filteredJobs].sort((a, b) => {
        let fieldA = a[sortField]?.toLowerCase() || ""
        let fieldB = b[sortField]?.toLowerCase() || ""
        return sortOrder === "asc"
          ? fieldA > fieldB
            ? 1
            : -1
          : fieldA < fieldB
          ? 1
          : -1
      })

      return sortedJobs
    }


  useEffect(() => {
    const unsubscribe = JobDataService.getAll(
      data => {
        setJob(data)
      },
      [currentJob]
    )

    return () => unsubscribe && unsubscribe()
  }, [])

  const toggleModal = () => {
    console.log(isModalOpen)
    setIsModalOpen(!isModalOpen)
    if (isModalOpen) {
      resetForm()
    }
  }

  const handleInputChange = event => {
    const { name, value } = event.target
    setCurrentJob({ ...currentJob, [name]: value })
  }

  const generateJobID = () => {
    const year = new Date().getFullYear()
    const uuid = uuidv4()
    const shortUuid = `${uuid.slice(0, 2)}-${uuid.slice(-2)}`
    return `JOB-${year}-${shortUuid}`.toUpperCase()
  }

  const sendAcknowledgementEmail = (
    userEmail,
    userName,
    firstName,
    lastName,
    jobTitle,
    jobDescription,
    jobAddress
  ) => {
    const templateParams = {
      to_email: userEmail,
      to_name: userName,
      firstName: firstName,
      lastName: lastName,
      jobTitle: jobTitle,
      jobDescription: jobDescription,
      jobAddress: jobAddress,
    }

    emailjs
      .send(
        "service_hms0mqj",
        "template_newjob",
        templateParams,
        "MGa-IIDI_leIHBrXj"
      )
      .then(
        response => {
          console.log("SUCCESS!", response.status, response.text)
        },
        error => {
          console.log("FAILED...", error)
        }
      )
  }

  // useEffect(() => {
  //   setCurrentJob({ ...currentJob, jobID: generateJobID() })
  // }, [])

  useEffect(() => {
    // Check if jobID is not set (undefined, null, or empty string)
    if (!currentJob.jobID) {
      setCurrentJob(prevJob => ({
        ...prevJob,
        jobID: generateJobID(),
      }))
    }
  }, [currentJob])

  const submitHandler = event => {
    event.preventDefault()
    const { jobID, ...jobData } = currentJob
    if (jobID) {
      JobDataService.updateJob(jobID, jobData).then(() => {
        resetForm()
        toggleModal()
      })
    } else {
      // Create new job
      JobDataService.createJob(jobData).then(newJobID => {
        // Assuming createJob returns the new jobID
        setCurrentJob({ ...jobData, jobID: newJobID })
        resetForm()
        toggleModal()
      })
    }

    console.log(`Job change status: ` + isNew)

    if (isNew === "newni") {
      console.log(
        "New job created successfully, sending messages to staff's on record"
      )
      try {
        sendAcknowledgementEmail(
          "delakti@icloud.com",
          "user name",
          "first name",
          "last name",
          "job title",
          "job description",
          "job address"
        )
      } catch (error) {
        console.log("Error:" + error)
      }
    } else {
      // alert("New job updated successfully");
    }
  }

  const resetForm = () => {
    setCurrentJob({
      jobTitle: "",
      jobDescription: "",
      jobExperience: "",
      jobType: "",
      provider: "",
      careHome: "",
      workerType: "",
      noofVacancies: "",
      startDate: "",
      endDate: "",
      startTime: "",
      endTime: "",
      enableRecurrence: "",
      breaks: "",
      jobstatus: "",
      jobID: "",
      category: "",
    })
  }

  const editJob = job => {
    setCurrentJob({ ...job, jobID: job.key || "" })
    toggleModal()
  }

  const deleteJob = key => {
    JobDataService.deleteJob(key)
  }

  const Bookings = key => {
    window.location.href = "/booking"
  }

  return (
    <div className="page-content">
      <Input
        type="text"
        placeholder="Search by Job Type, Provider, or Job ID"
        value={searchQuery}
        onChange={handleSearch}
        style={{ marginBottom: "10px", width: "300px" }}
      />
      {/* <Button
        color="primary"
        style={{ margin: "10px" }}
        onClick={() => {
          setIsNew("newni")
          toggleModal()
        }}
      >
        Add New Job...
      </Button> */}
      <Button
        color="primary"
        onClick={() => {
          Bookings()
        }}
        style={{ margin: "10px" }}
      >
        Book a new job
      </Button>
      <Modal
        isOpen={isModalOpen}
        toggle={toggleModal}
        backdrop={true}
        size="xl"
      >
        <ModalHeader toggle={toggleModal}>
          {currentJob.jobID ? "Update Job" : "Create Job"}
        </ModalHeader>
        <Form onSubmit={submitHandler}>
          <ModalBody>
            <div>Fields marked with an asterisk (*) are required.</div>
            <FormGroup>
              <Label for="jobID">
                Job ID<span style={{ color: "red" }}>*</span>
              </Label>
              <Input
                type="text"
                name="jobID"
                id="jobID"
                value={currentJob.jobID || ""}
                onChange={handleInputChange}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for="jobTitle">
                Job Title<span style={{ color: "red" }}>*</span>
              </Label>
              <Input
                type="text"
                name="jobTitle"
                id="jobTitle"
                value={currentJob.jobTitle}
                onChange={handleInputChange}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for="jobDescription">
                Job Description<span style={{ color: "red" }}>*</span>
              </Label>
              <Input
                type="textarea"
                name="jobDescription"
                id="jobDescription"
                value={currentJob.jobDescription}
                onChange={handleInputChange}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for="jobExperience">
                Job Experience<span style={{ color: "red" }}>*</span>
              </Label>
              <Input
                type="text"
                name="jobExperience"
                id="jobExperience"
                value={currentJob.jobExperience}
                onChange={handleInputChange}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for="jobType">
                Job Type<span style={{ color: "red" }}>*</span>
              </Label>

              <Input
                type="select"
                name="jobType"
                id="jobType"
                value={currentJob.jobType}
                onChange={handleInputChange}
              >
                <option value="">Select job type</option>
                <option value="Support worker">Support worker</option>
                <option value="Residential Support worker(sleep in)">
                  Residential Support worker(sleep in)
                </option>

                <option value="Health Care Assistant">
                  Health Care Assistant
                </option>
                <option value="Registered Mental Health Nurse">
                  Registered Mental Health Nurse
                </option>
                <option value="Registered General Nurse">
                  Registered General Nurse
                </option>
                <option value="Doctor">Doctor</option>
              </Input>
            </FormGroup>
            <FormGroup>
              <Label for="provider">
                Provider<span style={{ color: "red" }}>*</span>
              </Label>
              <Input
                type="text"
                name="provider"
                id="provider"
                value={currentJob.provider}
                onChange={handleInputChange}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for="careHome">
                Care Home<span style={{ color: "red" }}>*</span>
              </Label>
              <Input
                type="text"
                name="careHome"
                id="careHome"
                value={currentJob.careHome}
                onChange={handleInputChange}
                required
              />
            </FormGroup>
            {/* <FormGroup>
              <Label for="workerType">
                Worker Type<span style={{ color: "red" }}>*</span>
              </Label>
              <Input
                type="select"
                name="workerType"
                id="workerType"
                value={currentJob.workerType}
                onChange={handleInputChange}
                required
              >
                <option value="">Select a worker type</option>
                <option value="Temporary">Temporary</option>
                <option value="Permanent">Permanent</option>
              </Input>
            </FormGroup> */}
            {/* <FormGroup>
              <Label for="noofVacancies">
                Number of vacancies<span style={{ color: "red" }}>*</span>
              </Label>
              <Input
                type="number"
                name="noofVacancies"
                id="noofVacancies"
                value={currentJob.noofVacancies}
                onChange={handleInputChange}
                required
              />
            </FormGroup> */}
            <FormGroup>
              <Label for="startDate">
                Start Date<span style={{ color: "red" }}>*</span>
              </Label>
              <Input
                type="date"
                name="startDate"
                id="startDate"
                value={currentJob.startDate}
                onChange={handleInputChange}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for="startTime">
                Start Time<span style={{ color: "red" }}>*</span>
              </Label>
              <Input
                type="time"
                name="startTime"
                id="startTime"
                value={currentJob.startTime}
                onChange={handleInputChange}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for="endDate">
                End Date<span style={{ color: "red" }}>*</span>
              </Label>
              <Input
                type="date"
                name="endDate"
                id="endDate"
                value={currentJob.endDate}
                onChange={handleInputChange}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for="endTime">
                End time<span style={{ color: "red" }}>*</span>
              </Label>
              <Input
                type="time"
                name="endTime"
                id="endTime"
                value={currentJob.endTime}
                onChange={handleInputChange}
                required
              />
            </FormGroup>
            {/* <FormGroup>
              <Label for="enableRecurrence">Enable Recurrence</Label>

              <Input
                type="select"
                name="enableRecurrence"
                id="enableRecurrence"
                value={currentJob.enableRecurrence}
                onChange={handleInputChange}
              >
                <option value="">Enable Recurrence</option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </Input>
            </FormGroup> */}
            <FormGroup>
              <Label for="breaks">
                Breaks<span style={{ color: "red" }}>*</span>
              </Label>
              <Input
                type="number"
                name="breaks"
                id="breaks"
                value={currentJob.breaks}
                onChange={handleInputChange}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for="category">
                Category<span style={{ color: "red" }}>*</span>
              </Label>
              <Input
                type="select"
                name="category"
                id="category"
                value={currentJob.category}
                onChange={handleInputChange}
                required
              >
                <option value="">Select a Category</option>
                <option value="Clinical">Clinical</option>
                <option value="Non-Clinical">Non-Clinical</option>
              </Input>
            </FormGroup>
            <FormGroup>
              <Label for="jobstatus">Status</Label>
              <Input
                type="select"
                name="jobstatus"
                id="jobstatus"
                value={currentJob.jobstatus}
                onChange={handleInputChange}
              >
                <option value="">Select a status</option>
                <option value="Open">Open</option>
                <option value="Close">Close</option>
              </Input>
            </FormGroup>
            <FormGroup>
              <Label for="featured">Featured</Label>
              <Input
                type="select"
                name="featured"
                id="jobstatus"
                value={currentJob.featured}
                onChange={handleInputChange}
              >
                <option value="">Featured?</option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </Input>
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" type="submit">
              {currentJob.jobID ? "Update" : "Create"}
            </Button>
            <Button color="secondary" onClick={toggleModal}>
              Cancel
            </Button>
          </ModalFooter>
        </Form>
      </Modal>

      <Table>
        <thead>
          <tr>
            <th>Job Type</th>
            <th>Provider</th>
            <th>Job ID</th>

            {/* Add additional headers for other fields */}
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {filteredAndSortedJobs().map(jb => (
            <tr key={jb.key}>
              <td>{jb.jobType}</td>
              <td>{jb.provider}</td>
              <td>{jb.key}</td>
              <td>
                <Button onClick={() => editJob(jb)}>Edit</Button>
                &nbsp;&nbsp;&nbsp;
                <Button onClick={() => deleteJob(jb.key)}>Delete</Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  )
}

export default JobManager
