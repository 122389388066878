import React, { useState, useEffect } from "react"
import JobDataService from "services/JobDataService"
import TimesheetDataService from "services/TimesheetDataService"
import { Link } from "react-router-dom"
import { Badge } from "reactstrap"

const JobListing = () => {
  const [jobs, setJobs] = useState([])
  const [timesheets, setTimesheets] = useState([])

  useEffect(() => {
    JobDataService.getAll(setJobs)
    TimesheetDataService.getAllTimesheets(setTimesheets)
  }, [])

  const getTimesheetStatus = jobId => {
    const timesheet = timesheets.find(t => t.jobId === jobId)
    if (timesheet) {
      return timesheet.status
    }
    return "No Timesheet"
  }

  const getStatusColor = status => {
    switch (status) {
      case "Disputed":
        return "danger"
      case "Pending":
        return "warning"
      case "Approved":
        return "success"
      default:
        return "secondary"
    }
  }

  return (
    <div className="page-content">
      <h1>Job List</h1>
      <Link to="/timesheetform" className="btn btn-primary mb-3">
        Add a Timesheet
      </Link>
      <table className="table">
        <thead>
          <tr>
            <th>Job Title</th>
            <th>Provider</th>
            <th>Start Date</th>
            <th>End Date</th>
            <th>Timesheet Status</th>
          </tr>
        </thead>
        <tbody>
          {jobs.map(job => (
            <tr key={job.key}>
              <td>{job.jobTitle}</td>
              <td>{job.provider}</td>
              <td>{job.startDate}</td>
              <td>{job.endDate}</td>
              <td>
                <Badge color={getStatusColor(getTimesheetStatus(job.key))}>
                  {getTimesheetStatus(job.key)}
                </Badge>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default JobListing
